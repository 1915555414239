import React, {useEffect, useState} from 'react';
import Header from "../../common/header/header";
import {SESSION_ADMIN} from "../../../constants/sessionName";
import SideBar from "../sideBar/sideBar";
import Dashboard from "../dashBoard/dashboard";
import Zoning from "../zoning/zoning";
import {getPath} from "../../../routes/path";
import {useHistory} from "react-router-dom";
import AdminList from "../adminlist/adminList";
import ManagerList from "../managerlist/managerList";
import DirecteurList from "../directeurlist/directeurList";
import FormateurList from "../formateurlist/formateurList";
import RdvList from "../rdvlist/rdvList";
import OpticienList from "../opticienlist/opticienList";

import './space.scss';

function SpaceAdmin() {
    const history = useHistory();
    const [loggedAdmin , setLoggedAdmin] = useState({});
    const [item, setItem] = useState('dashboard');
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        if(localStorage.hasOwnProperty(SESSION_ADMIN)) {
            setLoggedAdmin(JSON.parse(localStorage.getItem(SESSION_ADMIN)));
        } else {
            history.push(getPath('login'));
        }
    },[]);

    const onItemChanged = item => {
        setItem(item);
    }

    const activeItem = active => {
        if (active === 'dashboard') {
            return <Dashboard />
        }
        else if (active === 'zoning') {
            return <Zoning />
        }
        else if (active === 'administrateurs') {
            return <AdminList />
        }
        else if (active === 'manager') {
            return <ManagerList />
        }
        else if (active === 'dr') {
            return <DirecteurList />
        }
        else if (active === 'rdv1') {
            return <RdvList />
        }
        else if (active === 'formateurs') {
            return <FormateurList />
        }
        else if (active === 'opticien') {
            return <OpticienList />
        }
    };

    const visibleModal = () => {
        setShowModal(true);
    };

    return (
        <div className="w-100 h-100">
            {
                loggedAdmin.user !== undefined ?
                    <Header user={loggedAdmin.user}/>
                : null
            }
            <div className="row mt-1 m-0 w-100">
                <div className="col-2">
                    <SideBar
                        bollModal={showModal}
                        itemChanged={onItemChanged}
                        visibleModal={visibleModal}
                    />
                </div>
                <div className="col-10">
                    {activeItem(item)}
                </div>

            </div>
        </div>
    );
}

export default SpaceAdmin;