import React, { useState, useEffect } from "react";
//import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {Modal, OverlayTrigger, Form, Popover} from "react-bootstrap";
import { DateRangePickerCalendar,  START_DATE, END_DATE } from "react-nice-dates";
import {fr} from "date-fns/locale";
import {format, formatISO, getDay, getWeek, parseISO} from "date-fns";
import {updateFormationByRdv} from "../../../../actions/rdv/updateFormationActions";

import './modalRdv.scss';

function ModalRdv({ handleClose, show, child }) {
    //const updateformationRequest = useSelector(state => state.updateFormationReducer, shallowEqual);
    const dispatch = useDispatch();

    const [color, setColor] = useState('');
    const [clicked, setClicked] = useState('');
    const [date] = useState(new Date());
    const [focus, setFocus] = useState(START_DATE);
    const [startDate, setStartDate] = useState( null);
    const [endDate, setEndDate] = useState(null);
    const [option1, setOption1] = useState({});
    const [startDate2, setStartDate2] = useState( null);
    const [endDate2, setEndDate2] = useState(null);
    const [option2, setOption2] = useState({});
    const [startDate3, setStartDate3] = useState( null);
    const [endDate3, setEndDate3] = useState(null);
    const [option3, setOption3] = useState({});
    const [observation, setObservation] = useState('');

    useEffect(() => {

        if (child.status === -1) {
            setColor('#F98579');
            setClicked('click');
        } else if (child.status === 0) {
            setColor('#9BC3E5');
            setClicked('click');
        } else {
            setColor('#A8D08C');
            setClicked('');
        }
        setOption1({});
        setOption2({});
        setOption3({});
        let childDetail = JSON.parse(child.detail);
        if (childDetail.length === 1) {
            setStartDate(parseISO(childDetail[0].startDate));
            setEndDate(parseISO(childDetail[0].endDate));
            setOption1(childDetail[0]);
        } else if (childDetail.length === 2) {
            setStartDate(parseISO(childDetail[0].startDate));
            setEndDate(parseISO(childDetail[0].endDate));
            setOption1(childDetail[0]);

            setStartDate2(parseISO(childDetail[1].startDate));
            setEndDate2(parseISO(childDetail[1].endDate));
            setOption2(childDetail[1]);

        } else if (childDetail.length === 3) {
            setStartDate(parseISO(childDetail[0].startDate));
            setEndDate(parseISO(childDetail[0].endDate));
            setOption1(childDetail[0]);

            setStartDate2(parseISO(childDetail[1].startDate));
            setEndDate2(parseISO(childDetail[1].endDate));
            setOption2(childDetail[1]);

            setStartDate3(parseISO(childDetail[2].startDate));
            setEndDate3(parseISO(childDetail[2].endDate));
            setOption3(childDetail[2]);
        }

        setObservation(child.observation);

    }, [child.detail, child.status, child.observation]);

    const handleFocusChange = newFocus => {

        if (newFocus === "endDate") {
            if (startDate === undefined) {
                setFocus(START_DATE);
            }
            else {
                setFocus(newFocus);
            }
        } else if (newFocus === "startDate") {
            if (endDate === undefined) {
                setFocus(END_DATE);
            }
            else {
                setFocus(newFocus);
            }
        }

    }

    const onStartDateChange = (value) => {
        if (getDay(value) === 1 ){
            setStartDate(value);
            const op1= option1;
            op1.startDate= formatISO(value);
            setOption1(op1);
        }
    }

    const onEndDateChange = (value) => {
        if (getDay(value) === 5 ){
            setEndDate(value);
            const op1= option1;
            op1.endDate= formatISO(value);
            setOption1(op1);
        }
    }

    const onStartDateChange2 = (value) => {
        if (getDay(value) === 1 ){
            setStartDate2(value);
            const op2= option2;
            op2.startDate= formatISO(value);
            setOption2(op2);
        }
    }

    const onEndDateChange2 = (value) => {
        if (getDay(value) === 5 ){
            setEndDate2(value);
            const op2= option2;
            op2.endDate= formatISO(value);
            setOption2(op2);
        }
    }

    const onStartDateChange3 = (value) => {
        if (getDay(value) === 1 ){
            setStartDate3(value);
            let op3= option3;
            op3.startDate= formatISO(value);
            setOption3(op3);
        }
    }

    const onEndDateChange3 = (value) => {
        if (getDay(value) === 5 ){
            setEndDate3(value);
            let op3= option3;
            op3.endDate= formatISO(value);
            setOption3(op3);
        }
    }

    const modifiers = {
        disabled: date => getDay(date) === 6 || getDay(date) === 0,
    };

    const getWeekNumber = (option) => {
        if (option.startDate !== undefined && option.endDate !== undefined) {
            let start = getWeek(parseISO(option.startDate));
            return `Semaine ${start}`;
        } else {
            return `Semaine __`;
        }
    }

    const getDateSteing = (option) => {
        if (option.startDate !== undefined && option.endDate !== undefined) {
            let start = format(parseISO(option.startDate), 'dd MMM', { locale: fr });
            let end = format(parseISO(option.endDate), 'dd MMM', { locale: fr });
            return `${start.slice(0, -1)} au ${end.slice(0, -1)}`;
        }
    }

    const handleObservationChange = (event) => {
        setObservation(event.target.value);
    }

    const validerFormation = () => {
        const array = [];

        if (startDate !== null && endDate !== null) {
            const op1 = {
                startDate: format(startDate, 'Y-MM-dd'),
                endDate: format(endDate, 'Y-MM-dd'),
            };
            array.push(op1);
        }
        if (startDate2 !== null && endDate2 !== null) {
            const op2 = {
                startDate: format(startDate2, 'Y-MM-dd'),
                endDate: format(endDate2, 'Y-MM-dd'),
            };
            array.push(op2);
        }
        if (startDate3 !== null && endDate3 !== null) {
            const op3 = {
                startDate: format(startDate3, 'Y-MM-dd'),
                endDate: format(endDate3, 'Y-MM-dd'),
            };
            array.push(op3);
        }

        const params = {
            formation_id: child.id,
            detail: JSON.stringify(array),
        };

        if (observation !== null && observation !== ''){
            params.observation = observation
        }

        dispatch(updateFormationByRdv(params));
        handleClose('update');
    }

    const popover1 = (
        <Popover style={{ width: "350px"}} id="popover-date1">
            <Popover.Content>
                <DateRangePickerCalendar
                    minimumLength={1}
                    maximumLength={5}
                    month={date}
                    weekdayFormat="EEEEE"
                    startDate={startDate}
                    endDate={endDate}
                    focus={focus}
                    modifiers={modifiers}
                    onStartDateChange={(e) => {onStartDateChange(e)}}
                    onEndDateChange={(e) => {onEndDateChange(e)}}
                    onFocusChange={handleFocusChange}
                    locale={fr}
                />
            </Popover.Content>
        </Popover>
    );

    const popover2 = (
        <Popover style={{ width: "350px"}} id="popover-date2">
            <Popover.Content>
                <DateRangePickerCalendar
                    minimumLength={1}
                    maximumLength={5}
                    month={date}
                    weekdayFormat="EEEEE"
                    startDate={startDate2}
                    endDate={endDate2}
                    focus={focus}
                    modifiers={modifiers}
                    onStartDateChange={(e) => {onStartDateChange2(e)}}
                    onEndDateChange={(e) => {onEndDateChange2(e)}}
                    onFocusChange={handleFocusChange}
                    locale={fr}
                />
            </Popover.Content>
        </Popover>
    );

    const popover3 = (
        <Popover style={{ width: "350px"}} id="popover-date3">
            <Popover.Content>
                <DateRangePickerCalendar
                    minimumLength={1}
                    maximumLength={5}
                    month={date}
                    weekdayFormat="EEEEE"
                    startDate={startDate3}
                    endDate={endDate3}
                    focus={focus}
                    modifiers={modifiers}
                    onStartDateChange={(e) => {onStartDateChange3(e)}}
                    onEndDateChange={(e) => {onEndDateChange3(e)}}
                    onFocusChange={handleFocusChange}
                    locale={fr}
                />
            </Popover.Content>
        </Popover>
    );

    return (
        <Modal
            show={show}
            onHide={() => handleClose('none')}
            size="lg"
            centered
        >
            <Modal.Body style={{
                borderTop: `20px solid ${color}`
            }}>
                <div className="d-md-none d-sm-flex flex-column align-items-center row mb-3 ">
                    <i className="fas fa-times" onClick={() => handleClose('none')} />
                </div>
                <div className="row">
                    <div className="col-md-6 col-12 text-left d-flex flex-row align-items-center">
                        <span className="span-title">Opticien</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.optician_name}</span>
                    </div>
                    <div className="col-md-6 col-12 text-right d-flex flex-row align-items-center
            justify-content-md-end">
                        <span className="span-title">NºJNJ</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.jnj_number}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 text-left d-flex flex-row align-items-center">
                        <span className="span-title">Contact point de vente désigné dans le contrat</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.trade_name}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-5 col-12 d-flex flex-row align-items-center">
                        <span className="span-title">Téléphone fixe</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.phone_fixe}</span>
                    </div>
                    <div className="col-md-7 col-12 d-flex flex-row align-items-center">
                        <span className="span-title">Téléphone mobile</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.phone_mobile}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 text-left d-flex flex-row align-items-start">
                        <span className="span-title">Adresse</span>
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row align-items-center">
                                <div className="v-line ml-5 mr-2"/>
                                <span className="span-text text-uppercase">{child.address}</span>
                            </div>
                            <div className="mt-2 d-flex flex-row align-items-center">
                                <div className="v-line ml-5 mr-2"/>
                                <span className="span-text text-uppercase">{child.postal_code}</span>
                                <div className="v-line ml-5 mr-2"/>
                                <span className="span-text text-uppercase">{child.city}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 text-left d-flex flex-row align-items-center">
                        <span className="span-title text-uppercase">périodes de formation proposées</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-4 col-12 d-flex flex-row align-items-baseline justify-content-center">
                        <span className="span-title mr-2">Option 1</span>
                        <div className="d-flex flex-column align-items-center">
                            <span className="span-text-semaine mr-2">{getWeekNumber(option1)}</span>
                            <span className="span-title-date">{getDateSteing(option1)}</span>
                        </div>
                        <OverlayTrigger trigger={clicked} placement="top" overlay={popover1} rootClose>
                            <i className="fas fa-calendar-alt text-danger" />
                        </OverlayTrigger>
                    </div>
                    { option2.startDate ?
                        <div className="col-md-4 col-12 d-flex flex-row align-items-baseline justify-content-center">
                            <span className="span-title mr-2">Option 2</span>
                            <div className="d-flex flex-column align-items-center">
                                <span className="span-text-semaine mr-2">{getWeekNumber(option2)}</span>
                                <span className="span-title-date">{getDateSteing(option2)}</span>
                            </div>
                            <OverlayTrigger trigger={clicked} placement="top" overlay={popover2} rootClose>
                                <i className="fas fa-calendar-alt text-danger" />
                            </OverlayTrigger>
                        </div>
                        : null
                    }
                    { option3.startDate ?
                        <div className="col-md-4 col-12 d-flex flex-row align-items-baseline justify-content-center">
                            <span className="span-title mr-2">Option 3</span>
                            <div className="d-flex flex-column align-items-center">
                                <span className="span-text-semaine mr-2">{getWeekNumber(option3)}</span>
                                <span className="span-title-date">{getDateSteing(option3)}</span>
                            </div>
                            <OverlayTrigger trigger={clicked} placement="top" overlay={popover3} rootClose>
                                <i className="fas fa-calendar-alt text-danger" />
                            </OverlayTrigger>
                        </div>
                        : null
                    }
                </div>

                <div className="row mt-3">
                    <div className="col-12 d-flex flex-column">
                        <span className="span-title">Observations</span>
                    </div>
                    <div className="col-12 d-flex flex-column">
                        {
                            clicked === 'click' ?
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" value={observation} onChange={(e) => handleObservationChange(e)} rows={3}/>
                                </Form.Group>
                            :
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" value={observation} onChange={(e) => handleObservationChange(e)} rows={3} readOnly/>
                                </Form.Group>
                        }

                    </div>
                </div>

                {
                    child.status_finished === 1 ?
                        <div className="row mt-3">
                            <div className="col-12 d-flex flex-column">
                                <span className="span-title">Compte rendu formation</span>
                            </div>
                            <div className="col-12 d-flex flex-column">
                                <Form.Control as="textarea" value={child.compte_rendu} rows={3} readOnly/>

                            </div>
                        </div>
                    : null

                }

                <div className="row mb-3 justify-content-center">
                    {
                        clicked === 'click' ?
                            <button className="button-envoyer"
                                    type="button"
                                    onClick={validerFormation}
                            >
                                ENVOYER
                            </button>
                        : null
                    }
                </div>

            </Modal.Body>
        </Modal>
    );
}

export default ModalRdv;