import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {SESSION_MANAGER} from "../../../constants/sessionName";
import {getPath} from "../../../routes/path";
import Header from "../../common/header/header";
import SideBar from "../sidebar/sideBar";
import DashboardManager from "../dashboard/dashboardManager";
import ZoningManager from "../zoning/zoningManager";
import OpticienManager from "../opticien/opticienManager";

function SpaceManager(props) {
    const history = useHistory();
    const [loggedManager , setLoggedManager] = useState({});
    const [item , setItem] = useState('dashboard');
    // eslint-disable-next-line no-unused-vars
    const [displayContact , setDisplayContact] = useState(false);
    useEffect(() => {
        if(localStorage.hasOwnProperty(SESSION_MANAGER)) {
            setLoggedManager(JSON.parse(localStorage.getItem(SESSION_MANAGER)));
        } else {
            history.push(getPath('login'));
        }
    },[history]);
    const onItemChanged = item => {
        setItem(item);
    }

    const activeItem = active => {
        if (active === 'dashboard') {
            return <DashboardManager />
        }
        else if (active === 'zoning') {
            return <ZoningManager />
        }
        else if (active === 'opticien') {
            return <OpticienManager />
        }
    };

    const handleDisplayContact =(value)=> {
        setDisplayContact(value);
    }

    return (
        <div className="w-100 h-100">
            {
                loggedManager.user !== undefined ?
                    <Header user={loggedManager.user} showContact={handleDisplayContact} />
                    : null
            }
            <div className="row mt-1 m-0 w-100">
                <div className="col-2">
                    <SideBar
                        itemChanged={onItemChanged}
                    />
                </div>
                <div className="col-10">
                    {activeItem(item)}
                </div>

            </div>
        </div>
    );
}

export default SpaceManager;