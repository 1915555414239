import {getRoutes} from "./routes";

export const getPath = (name: string, params: Object = null) => {
    const routeFound = getRoutes().find(route => route.name === name);
    let path = routeFound ? routeFound.path : null;
    if (path && params) {
        Object.entries(params).forEach(([key, value]: [string, any]) => {
            path = path ? path.replace(`:${key}`, value) : '';
        });
    }
    return path;
}