import axios from "axios";
import{ FIND_OPTICIAN_BY_JNJ } from "../../constants/types";
import {SESSION_RDV} from "../../constants/sessionName";
import {SERVER_URL} from "../../constants/constants";

export const findOpticianByJnj = (params) => {
    const rdv_session = JSON.parse(localStorage.getItem(SESSION_RDV));
    const headers= {
        'Authorization': `Bearer ${rdv_session.token}`
    }
    return (dispatch) => {
        dispatch(findOpticianByJnjRequest())
        axios
            .get(`${SERVER_URL}/api/rdv/get-optician-by-jnj` , { params, headers })
            .then(response => {
                const resp = response;
                dispatch(findOpticianByJnjSuccess(resp))
            })
            .catch(error => {
                dispatch(findOpticianByJnjFailure(error.message))
            })
    }
}

const findOpticianByJnjRequest = () => {
    return {
        type: FIND_OPTICIAN_BY_JNJ.FIND_OPTICIAN_BY_JNJ_REQUEST
    }
}

const findOpticianByJnjSuccess = response => {
    return {
        type: FIND_OPTICIAN_BY_JNJ.FIND_OPTICIAN_BY_JNJ_SUCCESS,
        payload: response
    }
}

const findOpticianByJnjFailure = error => {
    return {
        type: FIND_OPTICIAN_BY_JNJ.FIND_OPTICIAN_BY_JNJ_FAILED,
        payload: error
    }
}