import React, {useEffect, useState} from 'react';
import {SESSION_RDV} from "../../../constants/sessionName";
import {getPath} from "../../../routes/path";
import {useHistory} from "react-router-dom";

import './contactRdv.scss';

function ContactRdv({display}) {
    const history = useHistory();
    const [loggedRdv , setLoggedRdv] = useState({});
    useEffect(() => {
        if(localStorage.hasOwnProperty(SESSION_RDV)) {
            setLoggedRdv(JSON.parse(localStorage.getItem(SESSION_RDV)));
        } else {
            history.push(getPath('login'));
        }
    },[history]);
    return (
        <div className="w-100 d-flex flex-column align-items-center
                        justify-content-center contact-space">
            {
                loggedRdv.user !== undefined ?
                    <React.Fragment>
                        <h1 className="text-white">hello !</h1>
                        <div className="border border-white d-flex flex-column align-items-center">
                                <span
                                    className="span-close-contact text-right px-2"
                                    onClick={() => display(false)}
                                >
                                    X
                                </span>
                            <span className="span-text-contact font-weight-bold py-2">VOTRE FORMATEUR RÉFÉRENT</span>
                            <div className="d-flex flex-column px-5">
                                <div className="row py-2">
                                    <div className="col-12 text-left d-flex flex-row align-items-center">
                                        <span className="span-title-contact mr-3">je suis à votre disposition</span>
                                        <div className="v-line-contact ml-5 mr-2"/>
                                        <span className="span-text-contact text-uppercase">{}
                                            {`${loggedRdv.user.others.formateur.gender} ${loggedRdv.user.others.formateur.last_name} ${loggedRdv.user.others.formateur.first_name}`}
                                        </span>
                                    </div>
                                </div>
                                <div className="row py-2">
                                    <div className="col-12 text-left d-flex flex-row align-items-center">
                                        <span className="span-title-contact mr-3">pour m’appeler</span>
                                        <div className="v-line-contact ml-5 mr-2"/>
                                        <span className="span-text-contact text-uppercase">{loggedRdv.user.others.formateur.phone}</span>
                                    </div>
                                </div>
                                <div className="row py-2">
                                    <div className="col-12 text-left d-flex flex-row align-items-center">
                                        <span className="span-title-contact mr-3">ou m’envoyer un mail</span>
                                        <div className="v-line-contact ml-5 mr-2"/>
                                        <span className="span-text-contact">{loggedRdv.user.others.formateur.email}</span>
                                    </div>
                                </div>
                            </div>
                            <span className="span-text-contact font-weight-bold py-2 px-5">L’ÉQUIPE PROJET</span>
                            <div className="d-flex flex-column px-5">
                                <p className="span-text-contact text-center py-2">
                                    dans quels cas dois-je contacter L’ÉQUIPE PROJET : <br/>
                                    quand vous l’estimez nécessaire, par exemple quand votre formateur<br/>
                                    ne répond pas, un souci de connexion ou pour une question technique !
                                </p>
                                <div className="row py-2">
                                    <div className="col-12 text-left d-flex flex-row align-items-center">
                                        <span className="span-title-contact mr-3">du lundi au vendredi de 10h à 19h au</span>
                                        <div className="v-line-contact ml-5 mr-2"/>
                                        <span className="span-text-contact text-uppercase">07 60 17 26 80</span>
                                    </div>
                                </div>
                                <div className="row py-2">
                                    <div className="col-12 text-left d-flex flex-row align-items-center">
                                        <span className="span-title-contact mr-2">ou par e-mail</span>
                                        <div className="v-line-contact ml-5 mr-2"/>
                                        <span className="span-text-contact text-uppercase">support@mail.mycontact-opticien.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    : null
            }

        </div>
    );
}

export default ContactRdv;