import axios from "axios";
import{ STORE_FORMATION_RDV } from "../../constants/types";
import {SESSION_RDV} from "../../constants/sessionName";
import {SERVER_URL} from "../../constants/constants";

export const storeFormationByRdv = (params) => {
    const rdv = JSON.parse(localStorage.getItem(SESSION_RDV));
    const headers= {
        'Authorization': `Bearer ${rdv.token}`
    }
    return (dispatch) => {
        dispatch(storeformationRequest())
        axios
            .post(`${SERVER_URL}/api/rdv/store-formation` , params, { headers } )
            .then(response => {
                const resp = response;
                dispatch(storeformationSuccess(resp))
            })
            .catch(error => {
                dispatch(storeformationFailure(error.response))
            })
    }
}

const storeformationRequest = () => {
    return {
        type: STORE_FORMATION_RDV.STORE_FORMATION_RDV_REQUEST
    }
}

const storeformationSuccess = response => {
    return {
        type: STORE_FORMATION_RDV.STORE_FORMATION_RDV_SUCCESS,
        payload: response
    }
}

const storeformationFailure = error => {
    return {
        type: STORE_FORMATION_RDV.STORE_FORMATION_RDV_FAILED,
        payload: error
    }
}