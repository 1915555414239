import React, {useEffect, useState} from 'react';
import {Modal, Form, Button, Spinner } from "react-bootstrap";
import Select from 'react-select';
import {toast} from "react-toastify";
import {SESSION_ADMIN} from "../../../../constants/sessionName";
import axios from "axios";
import {SERVER_URL} from "../../../../constants/constants";

import './opticienModal.scss';

function OpticienModal({ show, handleClose }) {
    const [options, setOptions] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [opticien, setOpticien] = useState('');
    const [jnj, setJnj] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [postalcode, setPostalcode] = useState('');
    const [pdv, setPdv] = useState('');
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');
    const [email, setEmail] = useState('');
    const [rdv, setRdv] = useState({});
    const [errors, setErrors] = useState({});
    const [isImpLoading, setImpLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {

        const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
        const headers= {
            'Authorization': `Bearer ${admin_session.token}`
        }

        axios.get(`${SERVER_URL}/api/rdv/get-all-rdv` , { headers })
            .then(response => {
                const data = response.data.data;
                if (data.length > 0){
                    let op = [];
                    data.forEach(item => {
                        op.push( {
                            value: item.userable_id,
                            label: `${item.gender} ${item.first_name} ${item.last_name}`
                        });

                    });
                    setOptions(op);
                } else {
                    toast.configure();
                    onCloseClicked('none');
                    toast('Aucun responsable de vente exist sur la base de donnee', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            pauseOnFocusLoss: false,
                        }
                    );
                }
            })
            .catch(error => {
                console.log(error);
            })

    },[]);

    const resetData = () => {
        setOpticien('');
        setJnj('');
        setAddress('');
        setCity('');
        setPostalcode('');
        setPdv('');
        setPhone2('');
        setPhone1('');
        setEmail('');
        setRdv({});
        setOptions([]);
        setErrors({});
        setSelectedFile(null);
    }

    const onFileChange = event => {
        if (event.target.files[0] !== undefined) {
            setSelectedFile(event.target.files[0]);
        } else {
            setSelectedFile(null);
        }

    };

    const downloadModel= (event) => {
        if (localStorage.hasOwnProperty(SESSION_ADMIN)) {
            let session = JSON.parse(localStorage.getItem(SESSION_ADMIN));

            const headers = {
                'Authorization': `Bearer ${session.token}`
            }

            axios({
                url: `${SERVER_URL}/api/admin/download-matrice`,
                method: 'get',
                responseType:"blob",
                headers: headers
            }).then(function (response) {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');     // Create a label
                const href = window.URL.createObjectURL(blob);       // Create downloaded link
                aEle.href = href;
                aEle.download = `masqueCSV.xlsx`;  // File name after download
                document.body.appendChild(aEle);
                aEle.click();     // Click to download
                document.body.removeChild(aEle); // Download complete remove element
                window.URL.revokeObjectURL(href) // Release blob object
            }).catch(err => {
                console.log(err);
            })

        }
    }

    const onFileUpload = () => {
        setImpLoading(true);
        setErrors({});
        if (selectedFile === null) {
            setErrors({ "file": ['aucun fichier choisi']});
            setImpLoading(false);
        }
        else {
            const formData = new FormData();
            formData.append("name", selectedFile.name);
            formData.append("file", selectedFile);

            const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
            const headers= {
                'Authorization': `Bearer ${admin_session.token}`,
                'content-type': 'multipart/form-data'
            }

            axios.post(`${SERVER_URL}/api/admin/upload-opticien` , formData, { headers } )
                .then(response => {
                    toast.configure();
                    onCloseClicked();
                    toast('La liste des opticiens uploader avec succès', {
                            position: "bottom-right", autoClose: 3000, hideProgressBar: false,
                            closeOnClick: true, pauseOnHover: true, draggable: true,
                            progress: undefined, pauseOnFocusLoss: false,
                        }
                    );
                })
                .catch(error => {
                    if (error.response.status === 500){
                        setErrors({
                            file: ['le format du fichier uploader est incorrect comparer le avec le masque CSV']
                        });
                    } else {
                        setErrors(error.response.data);
                    }
                })
                .finally(() => {
                    setImpLoading(false);
                })
        }
    };

    const handleChange = selectedOption => {
        setRdv(selectedOption);
    };

    const handleInputChange = event => {
        const { name, value } = event.target;
        switch (name) {
            case 'opticien':
                setOpticien(value);
                break;
            case 'jnj':
                if (Number(value)) {
                    setJnj(value);
                }
                break;
            case 'address':
                setAddress(value);
                break;
            case 'city':
                setCity(value);
                break;
            case 'postalcode':
                setPostalcode(value);
                break;
            case 'pdv':
                setPdv(value);
                break;
            case 'phone1':
                setPhone1(value);
                break;
            case 'phone2':
                setPhone2(value);
                break;
            case 'email':
                setEmail(value);
                break;
            default:
                return null;
        }
    }

    const onBackSpacePress = (code) => {
        if (code === 'Backspace' && jnj.length === 1) {
            setJnj('');
        }
    }

    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();

        const params = {
            opticien, jnj, address, city,
            postalcode, pdv, email, rdv_id: rdv.value
        };

        if(phone1 !== '') {
            params.phone1 = phone1
        }

        if(phone2 !== '') {
            params.phone2 = phone2
        }

        const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
        const headers= {
            'Authorization': `Bearer ${admin_session.token}`
        }

        axios.post(`${SERVER_URL}/api/admin/store-opticien`, params, { headers } )
            .then(resp => {
                toast.configure();
                onCloseClicked('add');
                toast('Opticien ajouté avec succès', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        pauseOnFocusLoss: false,
                    }
                );
            }).catch(err => {
            setErrors(err.response.data);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const onCloseClicked = (action) => {
        resetData();
        handleClose(action);

    }

    return (
        <Modal
            backdrop="static"
            show={show}
            onHide={onCloseClicked}
            size="lg"
            centered
        >
            <Modal.Body>
                <div className="row">
                    <div className="col text-right">
                        <span className="close-button" onClick={() => onCloseClicked('none')}>X</span>
                    </div>
                </div>
                <div className="d-flex flex-column pl-5 pr-5">
                    <h5>Importer une base de données</h5>
                    <div className="horizontal-line-op w-25 mt-3 mb-3"/>
                </div>
                <div className="d-flex flex-column align-items-center">
                    <div className="d-flex flex-row align-items-baseline pl-5 pt-1 pb-1 w-100 file-input-op">
                        <span className="mr-2">Parcourir</span>
                        <input type="file" id="file" className="file-op" onChange={onFileChange} />
                        <label htmlFor="file">
                            { selectedFile ? selectedFile.name : 'CHOISIR FICHIER' }
                        </label>
                        <Button bsPrefix="btn-import mr-2 ml-1 w-25" onClick={onFileUpload}>
                            {isImpLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                : 'importer'
                            }
                        </Button>
                        <Button bsPrefix="btn-show" variant="primary" onClick={downloadModel}>Visualisez le masque .csv</Button>
                    </div>
                    {
                        errors && errors.file ?
                            <div className="text-danger">
                                {errors.file[0]}
                            </div>
                            : null
                    }
                </div>
                <div className="d-flex flex-column mt-4 pl-5 pr-5">
                    <h5>Ajouter un nouvel Opticien</h5>
                    <div className="horizontal-line-op w-25 mt-3 mb-3"/>
                </div>

                <Form onSubmit={handleSubmit}>
                    <div className="d-flex flex-column pl-5  w-100">

                        <div className="row">
                            <div className="col-5 text-left d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <span className="span-title">Opticien</span>
                                        <div className="v-line ml-3 mr-2"/>
                                        <Form.Control size="sm" type="text" name="opticien" required placeholder="Nom Opticien"
                                                      className={`${errors && errors.opticien ? 'is-invalid border border-danger' : '' }`}
                                                      value={opticien} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors && errors.opticien ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.opticien[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-1" />
                            <div className="
                            col-4 float-right d-flex
                            flex-row align-items-center
                            justify-content-end
                            ">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <span className="span-title">NºJNJ</span>
                                        <div className="v-line ml-3 mr-2"/>
                                        <Form.Control size="sm" type="text" name="jnj" required placeholder="JNJ Opticien"
                                                      onKeyDown={(e) =>  onBackSpacePress(e.code)}
                                                      className={`${errors &&  errors.jnj ? 'is-invalid border border-danger' : '' }`}
                                                      value={jnj} maxLength="7" onChange={handleInputChange}  />
                                    </div>
                                    {
                                        errors && errors.jnj ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.jnj[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-5 text-left d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <span className="span-title">Adresse</span>
                                        <div className="v-line ml-3 mr-2"/>
                                        <Form.Control size="sm" type="text" name="address" required placeholder="Adresse Opticien"
                                                      className={`${errors &&  errors.address ? 'is-invalid border border-danger' : '' }`}
                                                      value={address} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors && errors.address ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.address[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-3 text-left d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <div className="v-line ml-3 mr-2"/>
                                        <Form.Control size="sm" type="text" name="postalcode" required placeholder="CP Opticien"
                                                      className={`${errors && errors.postalcode ? 'is-invalid border border-danger' : '' }`}
                                                      value={postalcode} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors && errors.postalcode ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.postalcode[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-3 text-left d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <div className="v-line ml-3 mr-2"/>
                                        <Form.Control size="sm" type="text" name="city" required placeholder="Ville Opticien"
                                                      className={`${errors && errors.city ? 'is-invalid border border-danger' : '' }`}
                                                      value={city} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors && errors.city ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.city[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">

                            <div className="col-4 text-left d-flex flex-row align-items-center">
                                <span className="span-title">Contact point de vente</span>
                            </div>
                            <div className="col-4 text-left d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <div className="v-line mr-2"/>
                                        <Form.Control size="sm" type="text" name="pdv" required placeholder="Point de vente Opticien"
                                                      className={`${errors && errors.pdv ? 'is-invalid border border-danger' : '' }`}
                                                      value={pdv} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors && errors.pdv ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.pdv[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-3 text-left d-flex flex-row align-items-center">
                                <span className="span-title">Téléphone fixe</span>
                            </div>
                            <div className="col-3 text-left d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <div className="v-line mr-2"/>
                                        <Form.Control size="sm" type="text" name="phone1" placeholder="Téléphone Fixe"
                                                      className={`${errors && errors.phone1 ? 'is-invalid border border-danger' : '' }`}
                                                      onKeyPress={(event) => {
                                                          if (!/[0-9]/.test(event.key)) {
                                                              event.preventDefault();
                                                          }
                                                      }}
                                                      value={phone1} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors && errors.phone1 ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.phone1[0]}
                                            </div>
                                            : null
                                    }
                                </div>

                            </div>
                            <div className="col- d-flex flex-row align-items-center">
                                <span className="span-title">Téléphone mobile</span>
                            </div>
                            <div className="col-3 d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <div className="v-line mr-2"/>
                                        <Form.Control size="sm" type="text" name="phone2" placeholder="Téléphone Mobile"
                                                      className={`${errors && errors.phone2 ? 'is-invalid border border-danger' : '' }`}
                                                      onKeyPress={(event) => {
                                                          if (!/[0-9]/.test(event.key)) {
                                                              event.preventDefault();
                                                          }
                                                      }}
                                                      value={phone2} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors && errors.phone2 ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.phone2[0]}
                                            </div>
                                            : null
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-2 text-left d-flex flex-row align-items-center">
                                <span className="span-title">E-mail</span>
                            </div>
                            <div className="col-5 text-left d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <div className="v-line mr-2"/>
                                        <Form.Control size="sm" type="email" name="email" required placeholder="E-mail Opticien"
                                                      className={`${errors && errors.email ? 'is-invalid border border-danger' : '' }`}
                                                      value={email} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors && errors.email ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.email[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-2 text-left d-flex flex-row align-items-center">
                                <span className="span-title">RDV JNJ</span>
                            </div>
                            <div className="col-7 text-left d-flex flex-row align-items-center">
                                <div className="v-line mr-2"/>
                                <div className="w-100">
                                    <Select
                                        options={options}
                                        value={rdv}
                                        onChange={handleChange}
                                    />
                                    {
                                        errors && errors.rdv_id ?
                                            <div className="text-danger error-message">
                                                Choisi un responsable de vente.
                                            </div>
                                            : null
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12 text-center">
                                <Button bsPrefix="w-25 button-o-form-valider" type="submit">
                                    {isLoading ?
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        : 'Valider'
                                    }
                                </Button>
                            </div>
                        </div>

                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default OpticienModal;