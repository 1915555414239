import axios from "axios";
import{ LIST_ITEM_BY_ADMIN } from "../../constants/types";
import {SESSION_ADMIN} from "../../constants/sessionName";
import {SERVER_URL} from "../../constants/constants";

export const rdvListAction = (pageNumber = 1, sort = null, dir = null, keyWord = null) => {
    const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
    const headers= {
        'Authorization': `Bearer ${admin_session.token}`
    }
    const params = {
        page: pageNumber,
        sort, dir, keyWord
    }
    return (dispatch) => {
        dispatch(rdvListActionRequest());
        axios
            .get(`${SERVER_URL}/api/admin/get-rdvs-list` , { params, headers } )
            .then(response => {
                const resp = response;
                dispatch(rdvListActionSuccess(resp))
            })
            .catch(error => {
                dispatch(rdvListActionFailure(error.message))
            })
    }
}

const rdvListActionRequest = () => {
    return {
        type: LIST_ITEM_BY_ADMIN.LIST_ITEM_BY_ADMIN_REQUEST
    }
}

const rdvListActionSuccess = response => {
    return {
        type: LIST_ITEM_BY_ADMIN.LIST_ITEM_BY_ADMIN_SUCCESS,
        payload: response
    }
}

const rdvListActionFailure = error => {
    return {
        type: LIST_ITEM_BY_ADMIN.LIST_ITEM_BY_ADMIN_FAILED,
        payload: error
    }
}