import React from 'react';

import './secteur.scss';

function SecteurTable({formations}) {
    return (
        <div className="border mb-1">
            <div className="d-flex p-2 flex-column">
                <p className="font-weight-bold">SECTEURS</p>
                {   formations.length > 0 ?
                    formations.map((item, index) =>
                        <div key={index} className="row p-2">
                            <div className="col-4 text-uppercase">
                                {item.name}
                            </div>
                            <div className="col-2 font-weight-bold text-center h5">
                                {item.ok + item.pending + item.fixed}
                            </div>
                            <div className="col-2 font-weight-bold text-center ok-legend h5">
                                {item.ok}
                            </div>
                            <div className="col-2 font-weight-bold text-center pending-legend h5">
                                {item.pending}
                            </div>
                            <div className="col-2 font-weight-bold text-center fixed-legend h5">
                                {item.fixed}
                            </div>
                        </div>
                    )
                    : null
                }

            </div>
        </div>
    );
}

export default SecteurTable;