import React, {useEffect, useState} from 'react';
import Header from "../../common/header/header";
import {useHistory} from "react-router-dom";
import SideBarFormateur from "../sidebar/sideBarFormateur";
import {SESSION_FORMATEUR} from "../../../constants/sessionName";
import {getPath} from "../../../routes/path";
import OpticienFormateur from "../opticien/opticienFormateur";
import RdvFormateur from "../rdv/rdvFormateur";
import FormationFormateur from "../formation/formationFormateur";
import FormateurChart from "../chart/formateurChart";
import './spaceFormateur.scss';


function SpaceFormateur(props) {
    const history = useHistory();
    const [loggedFormateur , setLoggedFormateur] = useState({});
    const [item , setItem] = useState('action');

    useEffect(() => {
        if(localStorage.hasOwnProperty(SESSION_FORMATEUR)) {
            setLoggedFormateur(JSON.parse(localStorage.getItem(SESSION_FORMATEUR)));
        } else {
            history.push(getPath('login'));
        }

    },[history]);

    const onItemChanged = item => {
        setItem(item);
    }

    const activeItem = active => {
        if (active === 'action') {
            return <FormationFormateur />
        }
        else if (active === 'opticien') {
            return <OpticienFormateur />
        }
        else if (active === 'rdv') {
            return <RdvFormateur />
        }
    };

    return (
        <div className="w-100 h-100">
            {
                loggedFormateur.user !== undefined ?
                    <Header user={loggedFormateur.user}/>
                    : null
            }
            <div className="row mt-1 m-0 w-100">
                <div className="col-md-2 col-12">
                    <SideBarFormateur
                        itemChanged={onItemChanged}
                    />
                    <div className="mt-1 h-50">
                        <FormateurChart />
                    </div>
                </div>
                <div className="col-md-10 col-12">
                    {activeItem(item)}
                </div>

            </div>
        </div>
    );
}

export default SpaceFormateur;