import React, {useEffect, useState} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import man from '../../../assets/images/man.png';
import women from '../../../assets/images/women.png';
import {SESSION_RDV} from "../../../constants/sessionName";
import {getPath} from "../../../routes/path";

import './formationConfirmer.scss';

function FormationConfirmer() {
    const location = useLocation();
    const history = useHistory();
    const [loggedRdv, setLoggedRdv] = useState({});

    useEffect(() => {
        if(localStorage.hasOwnProperty(SESSION_RDV)) {
            setLoggedRdv(JSON.parse(localStorage.getItem(SESSION_RDV)));
        } else {
            history.push(getPath('login'));
        }
    },[history]);

    const onFermerClicked = () =>{
        history.push(getPath('login'));
    }

    const onEspaceClicked = () =>{
        history.push(getPath('spaceRdv'));
    }

    const onLogoClicked = () => {
        history.push(getPath('login'));
        window.location.reload();
    }

    return (
        <div className="row formationconfirmer">
            <header className="header">
                <div className="col-md-12 col-sm-12 d-flex justify-content-between align-items-center p-1">
                    <div className="col-md-9 col-sm-12" onClick={onLogoClicked}>
                        <img className="header__logo" src={logo} alt="logo" />
                        <h1 className="h4 font-weight-light">KALENDAR</h1>
                    </div>
                    <div className="col-md-3 col-sm-12 d-flex flex-row align-items-end">
                        <a className="d-flex flex-row align-items-center" href="mailto:schen@veniseactivation.com">
                            <img className="ml-1 header__menwomen-logo" src={women} alt="logo" />
                            <span className="text-dark font-weight-bold ml-2">CONTACT</span>
                        </a>

                    </div>
                </div>
            </header>
            <div className="
              px-4
              d-flex
              flex-column
              align-items-center
              justify-content-center
              content">
                {
                    loggedRdv.user ?
                        <p className="text-center p-desc">
                            Merci {loggedRdv.user.first_name} !
                        </p>
                    : null
                }
                <p className="text-center p-desc mb-3 h5">
                    L&apos;équipe de formation vient d&apos;être informée que ce nouvel opticien<br/>
                    vient de rejoindre le programme et doit être recontacter<br/>
                    pour fixer les rendez-vous de formation

                </p>
                <span className="span-optique text-uppercase">
                    {location.state.optician.corporate_name}
                </span>
                <button className="button-form-valider"
                        type="button" onClick={onEspaceClicked}>
                    ACCÉDER À VOTRE ESPACE
                </button>
                <button className="mb-5 button-form-valider"
                        type="button" onClick={onFermerClicked}>
                    FERMER
                </button>
            </div>
        </div>
    );
}

export default FormationConfirmer;
