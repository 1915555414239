import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import Select from "react-select";
import {toast} from "react-toastify";
import axios from "axios";
import {SERVER_URL} from "../../../../constants/constants";
import {SESSION_ADMIN} from "../../../../constants/sessionName";

import './updateOptician.scss';

function UpdateOptician({ handleClose, show, child }) {
    const [options, setOptions] = useState([]);
    const [opticien, setOpticien] = useState(child.corporate_name);
    const [jnj, setJnj] = useState(child.jnj_number);
    const [address, setAddress] = useState(child.address);
    const [city, setCity] = useState(child.city);
    const [postalcode, setPostalcode] = useState(child.postal_code);
    const [pdv, setPdv] = useState(child.trade_name);
    const [phone1, setPhone1] = useState(child.phone_fixe);
    const [phone2, setPhone2] = useState(child.phone_mobile);
    const [email, setEmail] = useState(child.email);
    const [rdv, setRdv] = useState(child.select_rdv);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {

        const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
        const headers= {
            'Authorization': `Bearer ${admin_session.token}`
        }

        axios.get(`${SERVER_URL}/api/rdv/get-all-rdv` , { headers })
            .then(response => {
                const data = response.data.data;
                if (data){
                    let op = [];
                    data.forEach(item => {
                        op.push( {
                            value: item.userable_id,
                            label: `${item.gender} ${item.first_name} ${item.last_name}`
                        });

                    });
                    setOptions(op);
                } else {
                    toast.configure();
                    onCloseClicked('none');
                    toast('Aucun responsable de vente exist sur la base de donnee', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            pauseOnFocusLoss: false,
                        }
                    );
                }
            })
            .catch(error => {
                console.log(error);
            })

    },[]);

    const resetData = () => {
        setOpticien('');
        setJnj('');
        setAddress('');
        setCity('');
        setPostalcode('');
        setPdv('');
        setPhone2('');
        setPhone1('');
        setEmail('');
        setRdv({});
        setOptions([]);
        setErrors({});
    }

    const handleChange = selectedOption => {
        setRdv(selectedOption);
    };

    const handleInputChange = event => {
        const { name, value } = event.target;
        switch (name) {
            case 'opticien':
                setOpticien(value);
                break;
            case 'jnj':
                if (Number(value)) {
                    setJnj(value);
                }
                break;
            case 'address':
                setAddress(value);
                break;
            case 'city':
                setCity(value);
                break;
            case 'postalcode':
                setPostalcode(value);
                break;
            case 'pdv':
                setPdv(value);
                break;
            case 'phone1':
                setPhone1(value);
                break;
            case 'phone2':
                setPhone2(value);
                break;
            case 'email':
                setEmail(value);
                break;
            default:
                return null;
        }
    }

    const onBackSpacePress = (code) => {
        if (code === 'Backspace' && jnj.length === 1) {
            setJnj('');
        }
    }

    const onCloseClicked = (action) => {
        resetData();
        handleClose(action);
    }

    const handleSubmit = (event) => {
        let params = {};
        if (opticien !== child.opticien) params = {...params, opticien};
        if (jnj !== child.jnj_number) params = {...params, jnj};
        if (address !== child.address) params = {...params, address};
        if (city !== child.city) params = {...params, city};
        if (postalcode !== child.postal_code) params = {...params, postalcode};
        if (pdv !== child.trade_name) params = {...params, pdv};
        if (phone1 !== child.phone_fixe) params = {...params, phone1};
        if (phone2 !== child.phone_mobile) params = {...params, phone2};
        if (email !== child.email) params = {...params, email};
        if (rdv !== child.select_rdv) params = {...params, rdv_id: rdv.value};
        if
        (
            params.opticien || params.jnj || params.address || params.city || params.postalcode || params.pdv ||
            params.phone1 || params.phone2 || params.email || params.rdv_id
        ){
            setLoading(true);
            event.preventDefault();
            const data = { ...params, user_id: child.id, optician_id: child.id };

            const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
            const headers= {
                'Authorization': `Bearer ${admin_session.token}`
            }

            axios
                .patch(`${SERVER_URL}/api/admin/update-optician` , data, { headers } )
                .then(response => {
                    toast.configure();
                    onCloseClicked('update');
                    toast('Opticien modifié avec succès', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            pauseOnFocusLoss: false,
                        }
                    );
                })
                .catch(error => {
                    setErrors(error.response.data);
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            onCloseClicked('none');
        }

    };

    return (
        <Modal
            backdrop="static"
            show={show}
            onHide={onCloseClicked}
            size="lg"
            centered
        >
            <Modal.Body>
                <div className="row">
                    <div className="col text-right">
                        <span className="close-button" onClick={() => onCloseClicked('none')}>X</span>
                    </div>
                </div>
                <div className="d-flex flex-column pl-5 pr-5">
                    <h5>Modifier un Opticien</h5>
                    <div className="horizontal-line-op w-25 mt-3 mb-3"/>
                </div>

                <Form onSubmit={handleSubmit}>
                    <div className="d-flex flex-column pl-5  w-100">

                        <div className="row">
                            <div className="col-5 text-left d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <span className="span-title">Opticien</span>
                                        <div className="v-line ml-3 mr-2"/>
                                        <Form.Control size="sm" type="text" name="opticien" required placeholder="Nom Opticien"
                                                      className={`${errors.opticien ? 'is-invalid border border-danger' : '' }`}
                                                      value={opticien} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors.opticien ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.opticien[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-1" />
                            <div className="
                            col-4 float-right d-flex
                            flex-row align-items-center
                            justify-content-end
                            ">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <span className="span-title">NºJNJ</span>
                                        <div className="v-line ml-3 mr-2"/>
                                        <Form.Control size="sm" type="text" name="jnj" required placeholder="JNJ Opticien"
                                                      onKeyDown={(e) =>  onBackSpacePress(e.code)}
                                                      className={`${errors.jnj ? 'is-invalid border border-danger' : '' }`}
                                                      value={jnj} maxLength="7" onChange={handleInputChange}  />
                                    </div>
                                    {
                                        errors.jnj ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.jnj[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-5 text-left d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <span className="span-title">Adresse</span>
                                        <div className="v-line ml-3 mr-2"/>
                                        <Form.Control size="sm" type="text" name="address" required placeholder="Adresse Opticien"
                                                      className={`${errors.address ? 'is-invalid border border-danger' : '' }`}
                                                      value={address} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors.address ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.address[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-3 text-left d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <div className="v-line ml-3 mr-2"/>
                                        <Form.Control size="sm" type="text" name="postalcode" required placeholder="CP Opticien"
                                                      className={`${errors.postalcode ? 'is-invalid border border-danger' : '' }`}
                                                      value={postalcode} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors.postalcode ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.postalcode[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-3 text-left d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <div className="v-line ml-3 mr-2"/>
                                        <Form.Control size="sm" type="text" name="city" required placeholder="Ville Opticien"
                                                      className={`${errors.city ? 'is-invalid border border-danger' : '' }`}
                                                      value={city} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors.city ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.city[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-4 text-left d-flex flex-row align-items-center">
                                <span className="span-title">Contact point de vente</span>
                            </div>
                            <div className="col-4 text-left d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <div className="v-line mr-2"/>
                                        <Form.Control size="sm" type="text" name="pdv" required placeholder="Point de vente Opticien"
                                                      className={`${errors.pdv ? 'is-invalid border border-danger' : '' }`}
                                                      value={pdv} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors.pdv ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.pdv[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-3 text-left d-flex flex-row align-items-center">
                                <span className="span-title">Téléphone fixe</span>
                            </div>
                            <div className="col-3 text-left d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <div className="v-line mr-2"/>
                                        <Form.Control size="sm" type="text" name="phone1" placeholder="Téléphone Fixe"
                                                      className={`${errors.phone1 ? 'is-invalid border border-danger' : '' }`}
                                                      onKeyPress={(event) => {
                                                          if (!/[0-9]/.test(event.key)) {
                                                              event.preventDefault();
                                                          }
                                                      }}
                                                      value={phone1} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors.phone1 ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.phone1[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col- d-flex flex-row align-items-center">
                                <span className="span-title">Téléphone mobile</span>
                            </div>
                            <div className="col-3 d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <div className="v-line mr-2"/>
                                        <Form.Control size="sm" type="text" name="phone2" placeholder="Téléphone Mobile"
                                                      className={`${errors.phone2 ? 'is-invalid border border-danger' : '' }`}
                                                      onKeyPress={(event) => {
                                                          if (!/[0-9]/.test(event.key)) {
                                                              event.preventDefault();
                                                          }
                                                      }}
                                                      value={phone2} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors.phone2 ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.phone2[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-2 text-left d-flex flex-row align-items-center">
                                <span className="span-title">E-mail</span>
                            </div>
                            <div className="col-5 text-left d-flex flex-row align-items-center">
                                <div className="row">
                                    <div className="col-12 d-flex flex-row align-items-center">
                                        <div className="v-line mr-2"/>
                                        <Form.Control size="sm" type="email" name="email" required placeholder="E-mail Opticien"
                                                      className={`${errors.email ? 'is-invalid border border-danger' : '' }`}
                                                      value={email} onChange={handleInputChange} />
                                    </div>
                                    {
                                        errors.email ?
                                            <div className="col-12 text-danger error-message">
                                                {errors.email[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-2 text-left d-flex flex-row align-items-center">
                                <span className="span-title">RDV JNJ</span>
                            </div>
                            <div className="col-7 text-left d-flex flex-row align-items-center">
                                <div className="v-line mr-2"/>
                                <div className="w-100">
                                    <Select
                                        options={options}
                                        value={rdv}
                                        onChange={handleChange}
                                    />
                                    {
                                        errors.rdv_id ?
                                            <div className="text-danger">
                                                Choisi un responsable de vente.
                                            </div>
                                            : null
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12 text-center">
                                <Button bsPrefix="w-25 button-o-form-valider" type="submit">
                                    {isLoading ?
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        : 'Valider'
                                    }
                                </Button>
                            </div>
                        </div>

                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default UpdateOptician;