import React, { useState, useEffect } from "react";
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import { Modal } from "react-bootstrap";
import {fr} from "date-fns/locale";
import {format, getWeek, parseISO} from "date-fns";
import RendezVousCard from "./rendezVousCard";
import {updateFormationByFormateur} from "../../../../actions/formateur/updateFormationByFormateurActions";
import {toast} from "react-toastify";

import 'react-nice-dates/build/style.css'
import './modalFormateur.scss';
import {UPDATE_FORMATIONS_BY_FORMATEUR} from "../../../../constants/types";

function ModalFormateur({ handleClose, show, child }) {
    const updateformationRequest = useSelector(state => state.updateFormationByFormateurReducer, shallowEqual);
    const dispatch = useDispatch();
    const [color, setColor] = useState('');
    const [option1, setOption1] = useState({});
    const [option2, setOption2] = useState({});
    const [option3, setOption3] = useState({});
    const [optionV1, setOptionV1] = useState({});
    const [optionV2, setOptionV2] = useState({});
    const [optionV3, setOptionV3] = useState({});

    useEffect(() => {
        toast.configure();
        if (child.status === -1) {
            setColor('#F98579');
        } else if (child.status === 0) {
            setColor('#9BC3E5');
        } else {
            setColor('#A8D08C');
        }
        setOption1({});
        setOption2({});
        setOption3({});

        let childDetail = JSON.parse(child.detail);
        if (childDetail.length === 1) {
            setOption1(childDetail[0]);
        } else if (childDetail.length === 2) {
            setOption1(childDetail[0]);

            setOption2(childDetail[1]);

        } else if (childDetail.length === 3) {
            setOption1(childDetail[0]);

            setOption2(childDetail[1]);

            setOption3(childDetail[2]);
        };

        let childDetailF = JSON.parse(child.detail_formateur);
        if (childDetailF !== null) {
            if (childDetailF.length === 1) {
                //setOptionV1(childDetailF[0]);
                setOptionV1({
                    rdv_type: childDetailF[0].rdv_type,
                    rdv_1: [parseISO(childDetailF[0].rdv_1[0], 'Y-MM-dd'), childDetailF[0].rdv_1[1], childDetailF[0].rdv_1[2]],
                    rdv_3: [parseISO(childDetailF[0].rdv_3[0], 'Y-MM-dd'), childDetailF[0].rdv_3[1], childDetailF[0].rdv_3[2]],
                    rdv_10: [parseISO(childDetailF[0].rdv_10[0], 'Y-MM-dd'), childDetailF[0].rdv_10[1], childDetailF[0].rdv_10[2]],
                });
            } else if (childDetailF.length === 2) {
                //setOptionV1(childDetailF[0]);
                setOptionV1({
                    rdv_type: childDetailF[0].rdv_type,
                    rdv_1: [parseISO(childDetailF[0].rdv_1[0], 'Y-MM-dd'), childDetailF[0].rdv_1[1], childDetailF[0].rdv_1[2]],
                    rdv_3: [parseISO(childDetailF[0].rdv_3[0], 'Y-MM-dd'), childDetailF[0].rdv_3[1], childDetailF[0].rdv_3[2]],
                    rdv_10: [parseISO(childDetailF[0].rdv_10[0], 'Y-MM-dd'), childDetailF[0].rdv_10[1], childDetailF[0].rdv_10[2]],
                });
                //setOptionV2(childDetailF[1]);
                setOptionV2({
                    rdv_type: childDetailF[1].rdv_type,
                    rdv_1: [parseISO(childDetailF[1].rdv_1[0], 'Y-MM-dd'), childDetailF[1].rdv_1[1], childDetailF[1].rdv_1[2]],
                    rdv_3: [parseISO(childDetailF[1].rdv_3[0], 'Y-MM-dd'), childDetailF[1].rdv_3[1], childDetailF[1].rdv_3[2]],
                    rdv_10: [parseISO(childDetailF[1].rdv_10[0], 'Y-MM-dd'), childDetailF[1].rdv_10[1], childDetailF[1].rdv_10[2]],
                });

            } else if (childDetailF.length === 3) {
                //setOptionV1(childDetailF[0]);
                setOptionV1({
                    rdv_type: childDetailF[0].rdv_type,
                    rdv_1: [parseISO(childDetailF[0].rdv_1[0], 'Y-MM-dd'), childDetailF[0].rdv_1[1], childDetailF[0].rdv_1[2]],
                    rdv_3: [parseISO(childDetailF[0].rdv_3[0], 'Y-MM-dd'), childDetailF[0].rdv_3[1], childDetailF[0].rdv_3[2]],
                    rdv_10: [parseISO(childDetailF[0].rdv_10[0], 'Y-MM-dd'), childDetailF[0].rdv_10[1], childDetailF[0].rdv_10[2]],
                });
                //setOptionV2(childDetailF[1]);
                setOptionV2({
                    rdv_type: childDetailF[1].rdv_type,
                    rdv_1: [parseISO(childDetailF[1].rdv_1[0], 'Y-MM-dd'), childDetailF[1].rdv_1[1], childDetailF[1].rdv_1[2]],
                    rdv_3: [parseISO(childDetailF[1].rdv_3[0], 'Y-MM-dd'), childDetailF[1].rdv_3[1], childDetailF[1].rdv_3[2]],
                    rdv_10: [parseISO(childDetailF[1].rdv_10[0], 'Y-MM-dd'), childDetailF[1].rdv_10[1], childDetailF[1].rdv_10[2]],
                });

                //setOptionV3(childDetailF[2]);
                setOptionV3({
                    rdv_type: childDetailF[2].rdv_type,
                    rdv_1: [parseISO(childDetailF[2].rdv_1[0], 'Y-MM-dd'), childDetailF[2].rdv_1[1], childDetailF[2].rdv_1[2]],
                    rdv_3: [parseISO(childDetailF[2].rdv_3[0], 'Y-MM-dd'), childDetailF[2].rdv_3[1], childDetailF[2].rdv_3[2]],
                    rdv_10: [parseISO(childDetailF[2].rdv_10[0], 'Y-MM-dd'), childDetailF[2].rdv_10[1], childDetailF[2].rdv_10[2]],
                });
            };
        }


        if (updateformationRequest.response.status && updateformationRequest.response.status === 201) {
            dispatch({ type: UPDATE_FORMATIONS_BY_FORMATEUR.UPDATE_FORMATIONS_BY_FORMATEUR_RESET})
            handleClose('update');
            toast('Formation fixé avec succès', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    pauseOnFocusLoss: false,
                });
        }

    }, [child.detail, child.status, child.observation, updateformationRequest]);

    const getWeekNumber = (option) => {
        if (option.startDate !== undefined && option.endDate !== undefined) {
            let start = getWeek(parseISO(option.startDate));
            return `Semaine ${start}`;
        } else {
            return `Semaine __`;
        }
    }

    const getDateSteing = (option) => {
        if (option.startDate !== undefined && option.endDate !== undefined) {
            let start = format(parseISO(option.startDate), 'dd MMM', { locale: fr });
            let end = format(parseISO(option.endDate), 'dd MMM', { locale: fr });
            return `${start.slice(0, -1)} au ${end.slice(0, -1)}`;
        }
    }

    const setDate = (item, value) => {
        if (item === 'option1') {
            setOptionV1(value);
        } else if (item === 'option2') {
            setOptionV2(value);
        } else {
            setOptionV3(value);
        }
    }

    const validerFormation = () => {
        let op1 = {};
        let op2 = {};
        let op3 = {};
        const array = [];
        if (optionV1.rdv_type) {
            op1 = {
                optiion: 1,
                rdv_type: optionV1.rdv_type,
                rdv_1: [format(optionV1.rdv_1[0], 'Y-MM-dd'), optionV1.rdv_1[1], optionV1.rdv_1[2]],
                rdv_3: [format(optionV1.rdv_3[0], 'Y-MM-dd'), optionV1.rdv_3[1], optionV1.rdv_3[2]],
                rdv_10: [format(optionV1.rdv_10[0], 'Y-MM-dd'), optionV1.rdv_10[1], optionV1.rdv_10[2]],
            };
            array.push(op1);
        }

        if (optionV2.rdv_type) {
            op2 = {
                optiion: 2,
                rdv_type: optionV2.rdv_type,
                rdv_1: [format(optionV2.rdv_1[0], 'Y-MM-dd'), optionV2.rdv_1[1], optionV2.rdv_1[2]],
                rdv_3: [format(optionV2.rdv_3[0], 'Y-MM-dd'), optionV2.rdv_3[1], optionV2.rdv_3[2]],
                rdv_10: [format(optionV2.rdv_10[0], 'Y-MM-dd'), optionV2.rdv_10[1], optionV2.rdv_10[2]],
            };
            array.push(op2);
        }

        if (optionV3.rdv_type) {
            op3 = {
                optiion: 3,
                rdv_type: optionV3.rdv_type,
                rdv_1: [format(optionV3.rdv_1[0], 'Y-MM-dd'), optionV3.rdv_1[1], optionV3.rdv_1[2]],
                rdv_3: [format(optionV3.rdv_3[0], 'Y-MM-dd'), optionV3.rdv_3[1], optionV3.rdv_3[2]],
                rdv_10: [format(optionV3.rdv_10[0], 'Y-MM-dd'), optionV3.rdv_10[1], optionV3.rdv_10[2]],
            };
            array.push(op3);
        }

        const params = {
            "formation_id": child.id,
            "detail_formateur": JSON.stringify(array),
        };
        dispatch(updateFormationByFormateur(params));
    }

    return (
        <Modal
            show={show}
            onHide={() => handleClose('none')}
            size="xl"
            centered
        >
            <Modal.Body style={{
                borderTop: `20px solid ${color}`
            }}>
                <div className="d-md-none d-sm-flex flex-column align-items-center row mb-3 ">
                    <i className="fas fa-times" onClick={() => handleClose('none')} />
                </div>
                <div className="row">
                    <div className="col-md-4 col-12 text-left d-flex flex-row align-items-center">
                        <span className="span-title">Opticien</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.optician_name}</span>
                    </div>
                    <div className="col-md-4 col-12 text-left d-flex flex-row align-items-center justify-content-center">
                        <span className="span-title">Contact point de vente</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.trade_name}</span>
                    </div>
                    <div className="col-md-4 col-12 text-right d-flex flex-row align-items-center justify-content-sm-center justify-content-md-end">
                        <span className="span-title">NºJNJ</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.jnj_number}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-4 col-12 d-flex flex-row align-items-center">
                        <span className="span-title">Téléphone fixe</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.phone_fixe}</span>
                    </div>
                    <div className="col-md-4 col-12 d-flex flex-row align-items-center">
                        <span className="span-title">Téléphone mobile</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.phone_mobile}</span>
                    </div>
                    <div className="col-md-4 col-12 d-flex flex-row align-items-center">
                        <span className="span-title">E-mail</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.email}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 text-left d-flex flex-row align-items-start">
                        <span className="span-title">Adresse</span>
                        <div className="d-flex flex-column">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-12 d-flex flex-row">
                                    <div className="v-line ml-5 mr-2"/>
                                    <span className="span-text text-uppercase">{child.address}</span>
                                </div>

                                <div className="col-md-3 col-12 d-flex flex-row">
                                    <div className="v-line ml-5 mr-2"/>
                                    <span className="span-text text-uppercase">{child.postal_code}</span>
                                </div>

                                <div className="col-md-3 col-12 d-flex flex-row">
                                    <div className="v-line ml-5 mr-2"/>
                                    <span className="span-text text-uppercase">{child.city}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-4 col-12 d-flex flex-row align-items-center">
                        <span className="span-title">RDV JNJ</span>
                        <div className="v-line ml-3 mr-2"/>
                        <span className="span-text text-uppercase">{child.rdv.full_name}</span>
                    </div>
                    <div className="col-md-3 col-12 d-flex flex-row align-items-center">
                        <span className="span-title">Téléphone mobile</span>
                        <div className="v-line ml-3 mr-2"/>
                        <span className="span-text text-uppercase">{child.rdv.phone}</span>
                    </div>
                    <div className="col-md-5 col-12 d-flex flex-row align-items-center">
                        <span className="span-title">E-mail</span>
                        <div className="v-line ml-3 mr-2"/>
                        <span className="span-text text-uppercase">{child.rdv.email}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-5 col-12 text-left d-flex flex-row align-items-center">
                        <span className="span-title text-uppercase">périodes de formation proposées par le rdv jnj</span>
                    </div>
                    <div className="col-md-7 col-12 text-left row align-items-center">
                        <div className="col-md-4 col-12 d-flex flex-row align-items-baseline justify-content-center">
                            <span className="span-title mr-2">Option 1</span>
                            <div className="d-flex flex-column align-items-center">
                                <span className="span-text-semaine mr-2">{getWeekNumber(option1)}</span>
                                <span className="span-title-date">{getDateSteing(option1)}</span>
                            </div>
                        </div>
                        { option2.startDate ?
                            <div className="col-md-4 col-12 d-flex flex-row align-items-baseline justify-content-center">
                                <span className="span-title mr-2">Option 2</span>
                                <div className="d-flex flex-column align-items-center">
                                    <span className="span-text-semaine mr-2">{getWeekNumber(option2)}</span>
                                    <span className="span-title-date">{getDateSteing(option2)}</span>
                                </div>
                            </div>
                            : null
                        }
                        { option3.startDate ?
                            <div className="col-md-4 col-12 d-flex flex-row align-items-baseline justify-content-center">
                                <span className="span-title mr-2">Option 3</span>
                                <div className="d-flex flex-column align-items-center">
                                    <span className="span-text-semaine mr-2">{getWeekNumber(option3)}</span>
                                    <span className="span-title-date">{getDateSteing(option3)}</span>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 text-left d-flex flex-row align-items-center">
                        <span className="span-title">Observations notées par RDV / JNJ</span>
                    </div>
                    <div className="col-12 text-left d-flex flex-row align-items-center">
                        <p className="span-title">
                            {child.observation}
                        </p>
                    </div>
                </div>

                <div className="row mt-1 horizontal-line" />

                <div className="row mt-1">
                    <div className="col-12 text-left d-flex flex-row align-items-center">
                        <span className=" text-dark text-uppercase">
                            fixer un rendez-vous avec l'opticien et les collaborateurs du point de vente
                        </span>
                    </div>
                </div>

                <div className="row mt-1">
                    <RendezVousCard
                        child={child}
                        setDate={setDate}
                        item={option1}
                        option="option1"/>
                    {
                        option2.startDate ?
                            <RendezVousCard
                                child={child}
                                setDate={setDate}
                                item={option2}
                                option="option2"/>
                            : null
                    }
                    { option3.startDate ?
                            <RendezVousCard
                                child={child}
                                setDate={setDate}
                                item={option3}
                                option="option3"/>
                            : null
                    }


                </div>


                <div className="row mt-3 mb-3 justify-content-center">
                    <button className="button-envoyer"
                            type="button"
                            onClick={validerFormation}
                    >
                        ENVOYER
                    </button>
                </div>

            </Modal.Body>
        </Modal>
    );
}

export default ModalFormateur;