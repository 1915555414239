import { FIND_OPTICIAN_BY_JNJ } from '../../constants/types';

const initialState = { loading: false, error: '', response: {} }

const opticianByjnjReducer = (state = initialState, action) => {
    switch (action.type) {
        case FIND_OPTICIAN_BY_JNJ.FIND_OPTICIAN_BY_JNJ_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FIND_OPTICIAN_BY_JNJ.FIND_OPTICIAN_BY_JNJ_SUCCESS:
            return {
                loading: false,
                response: action.payload,
                error: ''
            }
        case FIND_OPTICIAN_BY_JNJ.FIND_OPTICIAN_BY_JNJ_FAILED:
            return {
                loading: false,
                response: {},
                error: action.payload
            }
        default: return state
    }
}

export default opticianByjnjReducer;