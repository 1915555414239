import axios from "axios";
import{ LIST_ITEM_BY_ADMIN } from "../../constants/types";
import {SESSION_ADMIN} from "../../constants/sessionName";
import {SERVER_URL} from "../../constants/constants";

export const opticianListAction = (pageNumber = 1, sort = null, dir = null, keyWord = null) => {
    const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
    const headers= {
        'Authorization': `Bearer ${admin_session.token}`
    }
    const params = {
        page: pageNumber,
        sort, dir, keyWord
    }
    return (dispatch) => {
        dispatch(opticianListActionRequest())
        axios
            .get(`${SERVER_URL}/api/admin/get-opticians-list` , { params, headers } )
            .then(response => {
                const resp = response;
                dispatch(opticianListActionSuccess(resp))
            })
            .catch(error => {
                dispatch(opticianListActionFailure(error.message))
            })
    }
}

const opticianListActionRequest = () => {
    return {
        type: LIST_ITEM_BY_ADMIN.LIST_ITEM_BY_ADMIN_REQUEST
    }
}

const opticianListActionSuccess = response => {
    return {
        type: LIST_ITEM_BY_ADMIN.LIST_ITEM_BY_ADMIN_SUCCESS,
        payload: response
    }
}

const opticianListActionFailure = error => {
    return {
        type: LIST_ITEM_BY_ADMIN.LIST_ITEM_BY_ADMIN_FAILED,
        payload: error
    }
}