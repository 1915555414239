import { FIND_RDV_FORMATEUR_NULL } from '../../constants/types';

const initialState = { loading: false, error: '', response: {} }

const findRdvFormateurNullReducer = (state = initialState, action) => {
    switch (action.type) {
        case FIND_RDV_FORMATEUR_NULL.FIND_RDV_FORMATEUR_NULL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FIND_RDV_FORMATEUR_NULL.FIND_RDV_FORMATEUR_NULL_SUCCESS:
            return {
                loading: false,
                response: action.payload,
                error: ''
            }
        case FIND_RDV_FORMATEUR_NULL.FIND_RDV_FORMATEUR_NULL_FAILED:
            return {
                loading: false,
                response: {},
                error: action.payload
            }
        case FIND_RDV_FORMATEUR_NULL.FIND_RDV_FORMATEUR_NULL_RESET:
            return initialState
        default: return state
    }
}

export default findRdvFormateurNullReducer;