import React, {useEffect, useState} from 'react';
import {ListGroup} from "react-bootstrap";

import './sideBar.scss';

function SideBar({ itemChanged, visibleModal, bollModal }) {
    const [showSubItems, setShowSubItems] = useState(false);
    const [selectedItem, setSelectedItem] = useState('dashboard');
    const [selectedSubItem, setSelectedSubItem] = useState('');

    useEffect(() => {
        if (!bollModal) {
            setSelectedSubItem('');
        }
    },[bollModal])

    const changeselectedItem = item => {
        if (item === 'dashboard') {
            setSelectedItem(item);
            setShowSubItems(false);
            setSelectedSubItem('');
            itemChanged('dashboard');
        } else if (item === 'zoning') {
            setSelectedItem(item);
            setShowSubItems(false);
            setSelectedSubItem('');
            itemChanged('zoning');
        } else if (item === 'administration') {
            setSelectedItem(item);
            setShowSubItems(true);
        }

    }

    const changeselecteSubdItem = item => {
        setSelectedSubItem(item);
        switch (item) {
            case 'administrateurs':
                itemChanged('administrateurs');
                visibleModal();
                break;
            case 'manager':
                itemChanged('manager');
                visibleModal();
                break;
            case 'dr':
                itemChanged('dr');
                visibleModal();
                break;
            case 'rdv1':
                itemChanged('rdv1');
                visibleModal();
                break;
            case 'formateurs':
                itemChanged('formateurs');
                visibleModal();
                break;
            case 'opticien':
                itemChanged('opticien');
                visibleModal();
                break;
            default:
                return null;
        }
    }

    const subItems  = showSubItems && <div>
        <ListGroup.Item
            className={`
            ${selectedSubItem === 'administrateurs' ? 'subitem-selected' : 'subitem-list'}
                border-top-0 border-bottom-0 pt-1 pb-1"
            `}
            onClick={() => changeselecteSubdItem('administrateurs')}
        >
            ADMINISTRATEURS
        </ListGroup.Item>
        <ListGroup.Item
            className={`
            ${selectedSubItem === 'manager' ? 'subitem-selected' : 'subitem-list'}
                border-top-0 border-bottom-0 pt-1 pb-1"
            `}
            onClick={() => changeselecteSubdItem('manager')}
        >
            MANAGER JJVC
        </ListGroup.Item>
        <ListGroup.Item
            className={`
            ${selectedSubItem === 'dr' ? 'subitem-selected' : 'subitem-list'}
                border-top-0 border-bottom-0 pt-1 pb-1"
            `}
            onClick={() => changeselecteSubdItem('dr')}
        >
            DR
        </ListGroup.Item>
        <ListGroup.Item
            className={`
            ${selectedSubItem === 'rdv1' ? 'subitem-selected' : 'subitem-list'}
                border-top-0 border-bottom-0 pt-1 pb-1"
            `}
            onClick={() => changeselecteSubdItem('rdv1')}
        >
            RDV
        </ListGroup.Item>
        <ListGroup.Item
            className={`
            ${selectedSubItem === 'formateurs' ? 'subitem-selected' : 'subitem-list'}
                border-top-0 border-bottom-0 pt-1 pb-1"
            `}
            onClick={() => changeselecteSubdItem('formateurs')}
        >
            FORMATEURS
        </ListGroup.Item>
        <ListGroup.Item
            className={`
            ${selectedSubItem === 'opticien' ? 'subitem-selected' : 'subitem-list'}
            border-top-0 pt-1 pb-1
            `}
            onClick={() => changeselecteSubdItem('opticien')}
        >
            OPTICIENS
        </ListGroup.Item>
    </div>



    return (
        <ListGroup>
            <ListGroup.Item
                className={`
                ${selectedItem === 'dashboard' ? "item-selected" : "item-list" }
                `}
                onClick={() => changeselectedItem('dashboard')}
            >DASH BOARD</ListGroup.Item>
            <ListGroup.Item
                className={`
                ${selectedItem === 'zoning' ? "item-selected" : "item-list" }
                `}
                onClick={() => changeselectedItem('zoning')}
            >BDD ZONING</ListGroup.Item>
            <ListGroup.Item
                className={`
                ${selectedItem === 'administration' ? "item-selected" : "item-list" }
                `}
                onClick={() => changeselectedItem('administration')}
            >ADMINISTRATION</ListGroup.Item>
            {subItems}
        </ListGroup>
    );
}

export default SideBar;