import React, {useEffect, useState} from 'react';
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import Select from 'react-select';
import {toast} from "react-toastify";
import axios from "axios";
import {SERVER_URL} from "../../../../constants/constants";
import {SESSION_ADMIN} from "../../../../constants/sessionName";

import './updateRdv.scss';

function UpdateRdv({ handleClose, show, child }) {
    const [options, setOptions] = useState([]);
    const [selectedGender, setSelectedGender] = useState(child.gender === 'Mme' ? 'Madame' : 'Monsieur');
    const [nom, setNom] = useState(child.last_name);
    const [prenom, setPrenom] = useState(child.first_name);
    const [email, setEmail] = useState(child.email);
    const [phone, setPhone] = useState(child.phone);
    const [sec1, setSec1] = useState(child.secteurs[0]);
    const [sec2, setSec2] = useState(child.secteurs[1]);
    const [sec3, setSec3] = useState(child.secteurs[2]);
    const [sec4, setSec4] = useState(child.secteurs[3]);
    const [sec5, setSec5] = useState(child.secteurs[4]);
    const [sec6, setSec6] = useState(child.secteurs[5]);
    const [sec7, setSec7] = useState(child.secteurs[6]);
    const [sec8, setSec8] = useState(child.secteurs[7]);
    const [sec9, setSec9] = useState(child.secteurs[8]);
    const [sec10, setSec10] = useState(child.secteurs[9]);
    const [directeur, setDirecteur] = useState(child.directeur);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
        const headers= {
            'Authorization': `Bearer ${admin_session.token}`
        }
        axios.get(`${SERVER_URL}/api/directeur/get-all-dr` , { headers })
            .then(response => {
                const data = response.data.data;

                if (data) {
                    let dr = [];
                    data.forEach(item => {
                        dr.push( {
                            value: item.userable_id,
                            label: `${item.gender} ${item.first_name} ${item.last_name}`
                        });

                    });

                    setOptions(dr);
                }
                else {
                    toast.configure();
                    onModalClose('none');
                    toast('Aucun directeur exist sur la base de donnee', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            pauseOnFocusLoss: false,
                        }
                    );
                }
            })
            .catch(error => {
                console.log(error);
            })

    },[]);

    function  resetData(){
        setSelectedGender('Madame');
        setNom('');
        setPrenom('');
        setEmail('');
        setPhone('');
        setErrors({});
        setDirecteur({});
        setOptions({})
    }

    const handleSelectChange = selectedOption => {
        setDirecteur(selectedOption);
    };

    const onGenderChanged = item => {
        if (item === 'Madame') {
            setSelectedGender('Madame');
        } else {
            setSelectedGender('Monsieur');
        }
    }

    const onSecteurChanged = event => {
        const { value, name } = event.target;
        if (name === 'secteur1') {
            setSec1(value);
        }
        else if (name === 'secteur2') {
            setSec2(value);
        }
        else if (name === 'secteur3') {
            setSec3(value);
        }
        else if (name === 'secteur4') {
            setSec4(value);
        }
        else if (name === 'secteur5') {
            setSec5(value);
        }
        else if (name === 'secteur6') {
            setSec6(value);
        }
        else if (name === 'secteur7') {
            setSec7(value);
        }
        else if (name === 'secteur8') {
            setSec8(value);
        }
        else if (name === 'secteur9') {
            setSec9(value);
        }
        else if (name === 'secteur10') {
            setSec10(value);
        }
    }

    const handleInputChange = event => {
        const { name, value } = event.target;
        if (name === 'nom') {
            setNom(value);
        }
        else if (name === 'prenom'){
            setPrenom(value);
        }
        else if (name === 'email') {
            setEmail(value);
        }
        else if (name === 'phone') {
            setPhone(value);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let secs = null;
        if (sec1 && sec1.length === 5) { secs = {...secs, sec1 }};
        if (sec2 && sec2.length === 5) { secs = {...secs, sec2 }};
        if (sec3 && sec3.length === 5) { secs = {...secs, sec3 }};
        if (sec4 && sec4.length === 5) { secs = {...secs, sec4 }};
        if (sec5 && sec5.length === 5) { secs = {...secs, sec5 }};
        if (sec6 && sec6.length === 5) { secs = {...secs, sec6 }};
        if (sec7 && sec7.length === 5) { secs = {...secs, sec7 }};
        if (sec8 && sec8.length === 5) { secs = {...secs, sec8 }};
        if (sec9 && sec9.length === 5) { secs = {...secs, sec9 }};
        if (sec10 && sec10.length === 5) { secs = {...secs, sec10 }};

        let params = {};
        if (selectedGender !== (child.gender === 'Mme' ? 'Madame' : 'Monsieur')) params = {...params, gender: selectedGender};
        if (nom !== child.last_name) params = {...params, nom};
        if (prenom !== child.first_name) params = {...params, prenom};
        if (email !== child.email) params = {...params, email};
        if (phone !== child.phone) params = {...params, phone};
        if (directeur !== child.directeur) params = {...params, directeur};
        if (directeur !== child.directeur) params = {...params, directeur_id: directeur.value};
        if (secs !== null) params = {...params, secteurs: JSON.stringify(secs)};
        if (params.gender || params.nom || params.prenom || params.email || params.phone || params.directeur_id || params.secteurs ){
            setLoading(true);
            event.preventDefault();
            const data = { ...params, user_id: child.id, rdv_id: child.userable_id };

            const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
            const headers= {
                'Authorization': `Bearer ${admin_session.token}`
            }

            axios.patch(`${SERVER_URL}/api/admin/update-rdv` , data, { headers } )
                .then(response => {
                    toast.configure();
                    onModalClose('update');
                    toast('Rdv modifié avec succès', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            pauseOnFocusLoss: false,
                        }
                    );
                })
                .catch(error => {
                    setErrors(error.response.data);
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            onModalClose('none');
        }

    };

    const onModalClose = (action) => {
        resetData();
        handleClose(action);
    }

    return (
        <Modal
            backdrop="static"
            show={show}
            onHide={onModalClose}
            size="lg"
            centered
        >
            <Modal.Body>
                <div className="row">
                    <div className="col text-right">
                        <span className="close-button" onClick={() => onModalClose('none')}>X</span>
                    </div>
                </div>
                <div className="d-flex flex-column pl-5 pr-5">
                    <h5>Modifier un Responsable des Ventes</h5>
                    <div className="horizontal-line-rdv w-25 mt-3 mb-3"/>
                </div>
                <div className="d-flex flex-column align-items-center">
                    <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                        <div className="row align-items-center w-100">
                            <div className={`
                            col-5
                            ${ selectedGender === 'Madame' ? "selected-span" : "unselected-span"}
                        `}
                                 onClick={() => onGenderChanged('Madame')}
                            >
                                <span>Madame</span>
                            </div>
                            <div className="col-2" />
                            <div className={`
                            col-5
                            ${ selectedGender === 'Monsieur' ? "selected-span" : "unselected-span"}
                        `}
                                 onClick={() => onGenderChanged('Monsieur')}
                            >
                                <span>Monsieur</span>
                            </div>
                        </div>
                    </div>

                    <Form className="w-100 d-flex flex-column align-items-center"  onSubmit={handleSubmit}>

                        <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                            <Form.Control size="sm" type="text" name="nom"
                                          placeholder="Nom" required
                                          className={`${errors.nom ? 'is-invalid border border-danger' : '' }`}
                                          value={nom}
                                          onChange={handleInputChange}
                            />
                            {
                                errors.nom ?
                                    <div className="invalid-feedback">
                                        {errors.nom[0]}
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                            <Form.Control size="sm" type="text" name="prenom"
                                          placeholder="Prénom" required
                                          className={`${errors.prenom ? 'is-invalid border border-danger' : '' }`}
                                          value={prenom}
                                          onChange={handleInputChange}
                            />
                            {
                                errors.prenom ?
                                    <div className="invalid-feedback">
                                        {errors.prenom[0]}
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                            <Form.Control size="sm" type="email" name="email"
                                          placeholder="E-mail" required
                                          className={`${errors.email ? 'is-invalid border border-danger' : '' }`}
                                          value={email}
                                          onChange={handleInputChange}
                            />
                            {
                                errors.email ?
                                    <div className="invalid-feedback">
                                        {errors.email[0]}
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                            <Form.Control size="sm" type="text" name="phone"
                                          placeholder="Téléphone Mobile" required
                                          className={`${errors.phone ? 'is-invalid border border-danger' : '' }`}
                                          value={phone}
                                          onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                              }
                                          }}
                                          onChange={handleInputChange}
                            />
                            {
                                errors.phone ?
                                    <div className="invalid-feedback">
                                        {errors.phone[0]}
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex flex-column pt-1 pb-1 w-75">
                            <h6>Directeur</h6>
                        </div>

                        <div className="d-flex flex-column pt-1 pb-1 w-75">
                            <Select
                                options={options}
                                value={directeur}
                                onChange={handleSelectChange}
                            />
                            {
                                errors.directeur_id ?
                                    <div className="text-danger">
                                        Choisi un Directeur.
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex flex-column pt-1 pb-1 w-75">
                            <h6>Secteurs</h6>
                        </div>

                        <div className="d-flex flex-column pt-1 pb-1 w-75">
                            <div className="d-flex flex-row">
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur1" maxLength="5"
                                        onChange={onSecteurChanged}
                                        value={sec1}
                                        type="text" placeholder="77001" required/>
                                </div>
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur2" maxLength="5"
                                        onChange={onSecteurChanged}
                                        value={sec2}
                                        type="text" placeholder="77001" />
                                </div>
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur3" maxLength="5"
                                        onChange={onSecteurChanged}
                                        value={sec3}
                                        type="text" placeholder="77001" />
                                </div>
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur4" maxLength="5"
                                        onChange={onSecteurChanged}
                                        value={sec4}
                                        type="text" placeholder="77001" />
                                </div>
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur5" maxLength="5"
                                        onChange={onSecteurChanged}
                                        value={sec5}
                                        type="text" placeholder="77001" />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column pt-1 pb-1 w-75">
                            <div className="d-flex flex-row">
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur6" maxLength="5"
                                        onChange={onSecteurChanged}
                                        value={sec6}
                                        type="text" placeholder="77001" />
                                </div>
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur7" maxLength="5"
                                        onChange={onSecteurChanged}
                                        value={sec7}
                                        type="text" placeholder="77001" />
                                </div>
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur8" maxLength="5"
                                        onChange={onSecteurChanged}
                                        value={sec8}
                                        type="text" placeholder="77001" />
                                </div>
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur9" maxLength="5"
                                        onChange={onSecteurChanged}
                                        value={sec9}
                                        type="text" placeholder="77001" />
                                </div>
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur10" maxLength="5"
                                        onChange={onSecteurChanged}
                                        value={sec10}
                                        type="text" placeholder="77001" />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column align-items-center pt-4 w-25">
                            <Button bsPrefix="w-100 button-r-form-valider" type="submit">
                                {isLoading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    : 'Valider'
                                }
                            </Button>
                        </div>

                    </Form>

                </div>
            </Modal.Body>
        </Modal>
    );
}

export default UpdateRdv;