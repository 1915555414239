import React from 'react';

import './rdv.scss';

function RdvTable({formations}) {
    return (
        <div className="border mb-1">
            <div className="d-flex p-2 flex-column">
                <p className="font-weight-bold">RDV</p>
                {
                    formations.map((item, index) =>
                        <div key={index} className="row p-2 w-100">
                                <div className="col-12">
                                    {item.name}
                                </div>
                                <div className="col-3 font-weight-bold text-center h5">
                                    {item.ok + item.pending + item.fixed}
                                </div>
                                <div className="col-3 font-weight-bold text-center ok-legend h5">
                                    {item.ok}
                                </div>
                                <div className="col-3 font-weight-bold text-center pending-legend h5">
                                    {item.pending}
                                </div>
                                <div className="col-3 font-weight-bold text-center fixed-legend h5">
                                    {item.fixed}
                                </div>
                            <div className="col-12 h-line"/>
                        </div>
                    )
                }

            </div>
        </div>
    );
}

export default RdvTable;