import axios from "axios";
import{ LIST_MANAGER } from "../../constants/types";
import {SESSION_ADMIN} from "../../constants/sessionName";
import {SERVER_URL} from "../../constants/constants";

export const managerListAction = (pageNumber = 1, sort = null, dir = null, keyWord = null) => {
    const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
    const headers= {
        'Authorization': `Bearer ${admin_session.token}`
    }
    const params = {
        page: pageNumber,
        sort, dir, keyWord
    }
    return (dispatch) => {
        dispatch(managerListActionRequest())
        axios
            .get(`${SERVER_URL}/api/admin/get-managers-list` , { params, headers } )
            .then(response => {
                const resp = response;
                dispatch(managerListActionSuccess(resp))
            })
            .catch(error => {
                dispatch(managerListActionFailure(error.response))
            })
    }
}

const managerListActionRequest = () => {
    return {
        type: LIST_MANAGER.LIST_MANAGER_REQUEST
    }
}

const managerListActionSuccess = response => {
    return {
        type: LIST_MANAGER.LIST_MANAGER_SUCCESS,
        payload: response
    }
}

const managerListActionFailure = error => {
    return {
        type: LIST_MANAGER.LIST_MANAGER_FAILED,
        payload: error
    }
}