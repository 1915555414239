import { FIND_FORMATIONS_BY_FORMATEUR } from '../../constants/types';

const initialState = { loading: false, error: '', response: {} }

const formationsByFormateurReducer = (state = initialState, action) => {
    switch (action.type) {
        case FIND_FORMATIONS_BY_FORMATEUR.FIND_FORMATIONS_BY_FORMATEUR_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FIND_FORMATIONS_BY_FORMATEUR.FIND_FORMATIONS_BY_FORMATEUR_SUCCESS:
            return {
                loading: false,
                response: action.payload,
                error: ''
            }
        case FIND_FORMATIONS_BY_FORMATEUR.FIND_FORMATIONS_BY_FORMATEUR_FAILED:
            return {
                loading: false,
                response: {},
                error: action.payload
            }
        default: return state
    }
}

export default formationsByFormateurReducer;