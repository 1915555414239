import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {DELETE_BY_ADMIN, GET_ALL_DR} from "../../../constants/types";
import {toast} from "react-toastify";
import {rdvListAction} from "../../../actions/rdv/rdvListAction";
import {confirmAlert} from "react-confirm-alert";
import SpinnerKalendar from "../../common/spinner/spinnerKalendar";
import {Button, FormControl, InputGroup, Table} from "react-bootstrap";
import Pagination from "react-js-pagination";
import RdvModal from "../modals/rdvModal/rdvModal";
import {deleteRdvAction} from "../../../actions/rdv/deleteRdvAction";
import UpdateRdv from "./modal/updateRdv";

import './rdvList.scss';

function RdvList(props) {
    const rdvListRequest = useSelector(state => state.listItemByAdminReducer, shallowEqual);
    const deleteByAdminRequest = useSelector(state => state.deleteByAdminRecducer, shallowEqual);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('users.id');
    const [dir, setDir] = useState('asc');
    const [keyWord, setKeyWord] = useState('');
    const[showAddModal, setShowAddModal] = useState(false);
    const[showModal, setShowModal] = useState(false);
    const[modalItem, setModalItem] = useState({});
    useEffect(() => {
        dispatch(rdvListAction());

        if (deleteByAdminRequest.response.status && deleteByAdminRequest.response.status === 200) {
            dispatch({ type: DELETE_BY_ADMIN.DELETE_BY_ADMIN_RESET });
            dispatch(rdvListAction());
            toast.configure();
            toast('Rdv supprimé avec succès', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    pauseOnFocusLoss: false,
                }
            );
        }
    },[dispatch, deleteByAdminRequest]);

    const getRdvData = (pageNumber, sortParam, dirParam, wordParam) => {
        setPage(pageNumber);
        dispatch(rdvListAction(pageNumber, sortParam, dirParam, wordParam));
    }

    const handleSortData = value => {
        let currentSortDir = '';
        let currentSort = '';
        if(value === sort) {
            currentSortDir = dir ==='asc'?'desc':'asc';
            setDir(currentSortDir);
        }
        currentSort = value;
        setSort(currentSort);
        getRdvData(page, currentSort, currentSortDir, keyWord);
    }

    const ShowModal = (item) => {
        setShowModal(true);
        setModalItem(item);
    };

    const ShowAddModal = (item) => {
        setShowAddModal(true);
    };

    const hideModal = (action) => {
        if (action === 'none') {
            setShowModal(false);
            setModalItem({});
        } else {
            setKeyWord('');
            dispatch(rdvListAction());
            setShowModal(false);
            setModalItem({});
        }

    };

    const hideAddModal = (action) => {
        if (action === 'none') {
            setShowAddModal(false);
        } else {
            setKeyWord( '');
            dispatch(rdvListAction());
            setShowAddModal(false);
        }
    };

    const handleDeleteDirecteur = (item) => {
        confirmAlert({
            title: 'Confirmation suppression',
            message: `Vous voulez vraiment de supprimer rdv : ${item.last_name}`,
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => {
                        dispatch(deleteRdvAction(item.userable_id));
                    }
                },
                {
                    label: 'Non',
                }
            ]
        });
    }

    const onKeyWordChanged = event => {
        setKeyWord(event.target.value);
    }

    const onSearchPress = event => {
        if (event.key === 'Enter') {
            getRdvData(page, sort, dir, event.target.value);
        }
    }

    return rdvListRequest.loading ? (
        <SpinnerKalendar />
    ) : rdvListRequest.error ? (
        <h2>{rdvListRequest.error}</h2>
    ) : (
        <div className="row w-100">
            <div className="col-6 d-flex flex-column justify-content-center mb-2">
                <InputGroup className="w-75">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Recherche</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        value={keyWord}
                        placeholder="Effectuez une recherche"
                        aria-label="search"
                        aria-describedby="basic-addon1"
                        onKeyPress={onSearchPress}
                        onChange={onKeyWordChanged}
                    />
                </InputGroup>
            </div>
            <div className="col-6 d-flex flex-column justify-content-center align-items-end mb-2">
                <Button variant="success" bsPrefix="btn-ajout text-uppercase px-3" onClick={ShowAddModal}>ajouter rdv</Button>
            </div>
            <div className="col-12">
                <div>
                    <Table className="border" responsive>
                        <thead className="table-header">
                        <tr className="h-25 text-center">
                            <th id="thead-td">
                                Civilité <i className={ sort === 'users.gender' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('users.gender')} />
                            </th>
                            <th id="thead-td">
                                Nom <i className={ sort === 'users.last_name' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('users.last_name')}/>
                            </th>
                            <th id="thead-td">
                                Prénom <i className={ sort === 'users.first_name' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('users.first_name')}/>
                            </th>
                            <th id="thead-td">
                                E-mail <i className={ sort === 'users.email' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('users.email')}/>
                            </th>
                            <th id="thead-td">
                                Téléphone mobile <i className={ sort === 'responsable_dvs.phone' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('responsable_dvs.phone')}/>
                            </th>
                            <th id="thead-td"></th>
                        </tr>
                        </thead>
                        <tbody className="table-body">
                        {
                            rdvListRequest.response.data && rdvListRequest.response.data.data ?
                                rdvListRequest.response.data.data.map(item =>
                                    <tr key={item.id}>
                                        <td id="tbody-td">
                                            {item.gender}
                                        </td>
                                        <td id="tbody-td">
                                            {item.last_name}
                                        </td>
                                        <td id="tbody-td">
                                            {item.first_name}
                                        </td>
                                        <td id="tbody-td">
                                            {item.email}
                                        </td>
                                        <td id="tbody-td">
                                            {item.phone}
                                        </td>
                                        <td id="tbody-td">
                                            <ul className="list-inline m-0">
                                                <li className="list-inline-item">
                                                    <div className="" onClick={() => ShowModal(item)}>
                                                        <i className="fa fa-edit text-success"></i>
                                                    </div>
                                                </li>
                                                <li className="list-inline-item">
                                                    <div className="" onClick={() => handleDeleteDirecteur(item)}>
                                                        <i className="fa fa-trash text-danger"></i>
                                                    </div>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                )
                                : null
                        }
                        </tbody>
                    </Table>
                    <div className="row w-100 mt-3 d-flex flex-row justify-content-end">
                        {
                            rdvListRequest.response.data && rdvListRequest.response.data.meta &&

                            <Pagination
                                innerClass="custom-pagination"
                                hideDisabled
                                activePage={rdvListRequest.response.data.meta.current_page}
                                totalItemsCount={rdvListRequest.response.data.meta.total}
                                itemsCountPerPage={rdvListRequest.response.data.meta.per_page}
                                onChange={(pageNumber) => getRdvData(pageNumber, sort, dir, keyWord)}
                                itemClass="c-page-item"
                                linkClass="c-page-link"
                                activeClass="c-active-class"
                                activeLinkClass="c-active-link"
                                hideFirstLastPages
                            />
                        }

                    </div>
                </div>
            </div>
            {
                modalItem.id ?
                    <UpdateRdv
                        show={showModal}
                        handleClose={hideModal}
                        child={modalItem}
                    />
                    : null
            }
            {
                showAddModal ?
                    <RdvModal
                        show={showAddModal}
                        handleClose={hideAddModal}
                    />
                : null
            }
        </div>
    );
}

export default RdvList;