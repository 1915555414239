import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {SESSION_DR} from "../../../constants/sessionName";
import {getPath} from "../../../routes/path";
import Header from "../../common/header/header";
import SideBarDr from "../sidebar/sideBarDr";

import './spaceDr.scss'
import OpticienDr from "../opticien/opticienDr";
import DashboardDr from "../dashboard/dashboardDr";

function SpaceDr(props) {
    const history = useHistory();
    const [loggedDr , setLoggedDr] = useState({});
    const [item , setItem] = useState('dashboard');
    // eslint-disable-next-line no-unused-vars
    const [displayContact , setDisplayContact] = useState(false);
    useEffect(() => {
        if(localStorage.hasOwnProperty(SESSION_DR)) {
            setLoggedDr(JSON.parse(localStorage.getItem(SESSION_DR)));
        } else {
            history.push(getPath('login'));
        }
    },[history]);
    const onItemChanged = item => {
        setItem(item);
    }

    const activeItem = active => {
        if (active === 'dashboard') {
            return <DashboardDr user={loggedDr}/>
        }
        else if (active === 'opticien') {
            return <OpticienDr />
        }
    };

    const handleDisplayContact =(value)=> {
        setDisplayContact(value);
    }

    return (
        <div className="w-100 h-100">
            {
                loggedDr.user !== undefined ?
                    <Header user={loggedDr.user} showContact={handleDisplayContact} />
                    : null
            }
            <div className="row mt-1 m-0 w-100">
                <div className="col-2">
                    <SideBarDr
                        itemChanged={onItemChanged}
                    />
                </div>
                <div className="col-10">
                    {activeItem(item)}
                </div>

            </div>
        </div>
    );
}

export default SpaceDr;