import React from 'react';
import logo from "../../../assets/images/logo.png";
import {Spinner} from "react-bootstrap";

import './spinnerKalendar.scss'

function SpinnerKalendar(props) {
    return (
        <div className="container-spinner">
            <div className="d-flex flex-column">
                <img className="spinner-logo" src={logo} alt="logo" />
                <span className="spinner-title">KALENDAR</span>
            </div>
            <Spinner animation="border" style={{ color: '#E17B71', height: '50px', width: '50px' }} />
        </div>
    );
}

export default SpinnerKalendar;