import axios from "axios";
import{ UPDATE_FORMATIONS_BY_FORMATEUR } from "../../constants/types";
import {SESSION_FORMATEUR} from "../../constants/sessionName";
import {SERVER_URL} from "../../constants/constants";

export const updateFormationByFormateur = (params) => {
    const formateur = JSON.parse(localStorage.getItem(SESSION_FORMATEUR));
    const headers= {
        'Authorization': `Bearer ${formateur.token}`
    }
    return (dispatch) => {
        dispatch(updateFormationByFormateurRequest())
        axios.patch(`${SERVER_URL}/api/formateur/update-formations-by-formateur`, params,{ headers })
            .then(response => {
                const resp = response;
                dispatch(updateFormationByFormateurSuccess(resp))
            })
            .catch(error => {
                dispatch(updateFormationByFormateurFailure(error.message))
            });

    }
}

const updateFormationByFormateurRequest = () => {
    return {
        type: UPDATE_FORMATIONS_BY_FORMATEUR.UPDATE_FORMATIONS_BY_FORMATEUR_REQUEST
    }
}

const updateFormationByFormateurSuccess = response => {
    return {
        type: UPDATE_FORMATIONS_BY_FORMATEUR.UPDATE_FORMATIONS_BY_FORMATEUR_SUCCESS,
        payload: response
    }
}

const updateFormationByFormateurFailure = error => {
    return {
        type: UPDATE_FORMATIONS_BY_FORMATEUR.UPDATE_FORMATIONS_BY_FORMATEUR_FAILED,
        payload: error
    }
}