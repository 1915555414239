import React, { useState, useEffect } from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {fr} from "date-fns/locale";
import {format, getWeek, parseISO} from "date-fns";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {finishedFormationFormateurAction} from "../../../../../actions/formateur/finishedFormationFormateurAction";
import {FINISHED_FORMATIONS_FOMATEUR} from "../../../../../constants/types";
import {toast} from "react-toastify";
import axios from "axios";
import {SESSION_FORMATEUR} from "../../../../../constants/sessionName";
import {SERVER_URL} from "../../../../../constants/constants";

import './modalFormateurOK.scss';

function ModalFormateurOK({ handleClose, show, child }) {
    const finishedFormationRequest = useSelector(state => state.finishedFormationFormateurReducer, shallowEqual);
    const dispatch = useDispatch();
    const [color, setColor] = useState('');
    const [option1, setOption1] = useState({});
    const [option2, setOption2] = useState({});
    const [option3, setOption3] = useState({});
    const [fileName, setFileName] = useState('Aucun fichier choisi');
    const [selectedFile, setSelectedFile] = useState(null);
    const [formationRendu, setFormationrendu] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        toast.configure();
        if (child.status === -1) {
            setColor('#F98579');
        } else if (child.status === 0) {
            setColor('#9BC3E5');
        } else {
            setColor('#A8D08C');
        }
        setOption1({});
        setOption2({});
        setOption3({});
        let childDetail = JSON.parse(child.detail);
        if (childDetail.length === 1) {
            setOption1(childDetail[0]);
        } else if (childDetail.length === 2) {
            setOption1(childDetail[0]);
            setOption2(childDetail[1]);

        } else if (childDetail.length === 3) {
            setOption1(childDetail[0]);
            setOption2(childDetail[1]);
            setOption3(childDetail[2]);
        };

        if (finishedFormationRequest.error.status && finishedFormationRequest.error.status === 422){
            setErrors(finishedFormationRequest.error.data);
            dispatch({ type: FINISHED_FORMATIONS_FOMATEUR.FINISHED_FORMATIONS_FOMATEUR_RESET });
        }
        else if (finishedFormationRequest.response.status && finishedFormationRequest.response.status === 200){
            handleClose('update');
            toast('Formation termine avec succès', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                pauseOnFocusLoss: false,
            });
            dispatch({ type: FINISHED_FORMATIONS_FOMATEUR.FINISHED_FORMATIONS_FOMATEUR_RESET });
        }

    }, [child.detail, child.status, child.observation, finishedFormationRequest]);

    const getWeekNumber = (option) => {
        if (option.startDate !== undefined && option.endDate !== undefined) {
            let start = getWeek(parseISO(option.startDate));
            return `Semaine ${start}`;
        } else {
            return `Semaine __`;
        }
    }

    const getDateSteing = (option) => {
        if (option.startDate !== undefined && option.endDate !== undefined) {
            let start = format(parseISO(option.startDate), 'dd MMM', { locale: fr });
            let end = format(parseISO(option.endDate), 'dd MMM', { locale: fr });
            return `${start.slice(0, -1)} au ${end.slice(0, -1)}`;
        }
    }

    const handleObservationChange = (event) => {
        setFormationrendu(event.target.value);
    }

    const onFileChanged = event => {
        const file = event.target.files[0];
        if (event.target.files[0]) {
            setSelectedFile(file);
            setErrors({});
            const fileSize = (file.size / 1000).toFixed(2);
            const fileNameAndSize = `${file.name} - ${fileSize}KB`;
            setFileName(fileNameAndSize);
        } else {
            setFileName('Aucun fichier choisi');
            setErrors({});
            setSelectedFile(null);
        }
    }

    const onRemoveFileClicked = () => {
        setFileName('Aucun fichier choisi');
        setSelectedFile(null);
        document.getElementById('file').value = '';
    }

    const validerFormation = () => {
        const formData = new FormData();
        formData.append("name", fileName);
        formData.append("file", selectedFile);
        formData.append("formation_id", child.id);
        formData.append("formation_rendu", formationRendu);
        if (selectedFile !== null) {
            dispatch(finishedFormationFormateurAction(formData));
        } else {
            setErrors({ file: ['The file field is required.']})
        }

    }

    const downloadModel= (event) => {
        if (localStorage.hasOwnProperty(SESSION_FORMATEUR)) {
            let session = JSON.parse(localStorage.getItem(SESSION_FORMATEUR));

            const headers = {
                'Authorization': `Bearer ${session.token}`
            }

        axios({
            url: `${SERVER_URL}/api/formateur/download-attestation`,
            method: 'get',
            responseType:"blob",
            headers: headers
        }).then(function (response) {
            const blob = new Blob(
                [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
            const aEle = document.createElement('a');     // Create a label
            const href = window.URL.createObjectURL(blob);       // Create downloaded link
            aEle.href = href;
            aEle.download = `modele_attestation_${child.jnj_number}.pdf`;  // File name after download
            document.body.appendChild(aEle);
            aEle.click();     // Click to download
            document.body.removeChild(aEle); // Download complete remove element
            window.URL.revokeObjectURL(href) // Release blob object
        })

        }
    }

    return (
        <Modal
            show={show}
            onHide={() => handleClose('none')}
            size="xl"
            centered
        >
            <Modal.Body style={{
                borderTop: `20px solid ${color}`
            }}>
                <div className="d-md-none d-sm-flex flex-column align-items-center row mb-3 ">
                    <i className="fas fa-times" onClick={() => handleClose('none')} />
                </div>
                <div className="row">
                    <div className="col-md-4 col-12 text-left d-flex flex-row align-items-center">
                        <span className="span-title">Opticien</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.optician_name}</span>
                    </div>
                    <div className="col-md-4 col-12 text-center d-flex flex-row align-items-center">
                        <span className="span-title">Contact point de vente</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.trade_name}</span>
                    </div>
                    <div className="col-md-4 col-12 d-flex flex-row align-items-center
                    justify-content-sm-center justify-content-md-end">
                        <span className="span-title">NºJNJ</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.jnj_number}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-3 col-12 d-flex flex-row align-items-center">
                        <span className="span-title">Téléphone fixe</span>
                        <div className="v-line ml-3 mr-2"/>
                        <span className="span-text text-uppercase">{child.phone_fixe}</span>
                    </div>
                    <div className="col-md-4 col-12 d-flex flex-row align-items-center">
                        <span className="span-title">Téléphone mobile</span>
                        <div className="v-line ml-3 mr-2"/>
                        <span className="span-text text-uppercase">{child.phone_mobile}</span>
                    </div>
                    <div className="col-md-4 col-12 d-flex flex-row align-items-center">
                        <span className="span-title">E-mail</span>
                        <div className="v-line ml-3 mr-2"/>
                        <span className="span-text text-uppercase">{child.email}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 text-left d-flex flex-row align-items-start">
                        <span className="span-title">Adresse</span>
                        <div className="d-flex flex-column">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-12 d-flex flex-row">
                                    <div className="v-line ml-5 mr-2"/>
                                    <span className="span-text text-uppercase">{child.address}</span>
                                </div>

                                <div className="col-md-3 col-12 d-flex flex-row">
                                    <div className="v-line ml-5 mr-2"/>
                                    <span className="span-text text-uppercase">{child.postal_code}</span>
                                </div>

                                <div className="col-md-3 col-12 d-flex flex-row">
                                    <div className="v-line ml-5 mr-2"/>
                                    <span className="span-text text-uppercase">{child.city}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-5 col-12 text-left d-flex flex-row align-items-center">
                        <span className="span-title text-uppercase">périodes de formation proposées par le rdv jnj</span>
                    </div>
                    <div className="col-md-7 col-12 text-left row text-center align-items-center">
                        <div className="col-md-4 col-12 d-flex flex-row align-items-baseline justify-content-center">
                            <span className="span-title mr-2">Option 1</span>
                            <div className="d-flex flex-column align-items-center">
                                <span className="span-text-semaine mr-2">{getWeekNumber(option1)}</span>
                                <span className="span-title-date">{getDateSteing(option1)}</span>
                            </div>
                        </div>
                        { option2.startDate ?
                            <div className="col-md-4 col-12 d-flex flex-row align-items-baseline justify-content-center">
                                <span className="span-title mr-2">Option 2</span>
                                <div className="d-flex flex-column align-items-center">
                                    <span className="span-text-semaine mr-2">{getWeekNumber(option2)}</span>
                                    <span className="span-title-date">{getDateSteing(option2)}</span>
                                </div>
                            </div>
                            : null
                        }
                        { option3.startDate ?
                            <div className="col-md-4 col-12 d-flex flex-row align-items-baseline justify-content-center">
                                <span className="span-title mr-2">Option 3</span>
                                <div className="d-flex flex-column align-items-center">
                                    <span className="span-text-semaine mr-2">{getWeekNumber(option3)}</span>
                                    <span className="span-title-date">{getDateSteing(option3)}</span>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 text-left d-flex flex-row align-items-center">
                        <span className="span-title">Observations notées par RDV / JNJ</span>
                    </div>
                    <div className="col-12 text-left d-flex flex-row align-items-center">
                        <Form.Control as="textarea" value={child.observation} rows={3} readOnly/>
                    </div>
                </div>

                {
                    child.status_finished !== 1 ?
                        <React.Fragment>
                            <div className="row mt-2">
                                <div className="col-12 text-left d-flex flex-row align-items-center">
                                    <span className="span-title">Compte rendu formation</span>
                                </div>
                                <div className="col-12 d-flex flex-column">
                                    <Form.Control as="textarea" onChange={(e) => handleObservationChange(e)} rows={3}/>
                                    {
                                        errors.formation_rendu ?
                                            <div className="text-danger">
                                                {errors.formation_rendu[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-8 col-12 text-left d-flex flex-row align-items-center">
                        <span className="span-title">
                            Attestation pré-remplie à faire signer par les participants lors de la formation :
                        </span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column">
                                    <Button variant="success" onClick={(event) => downloadModel(event)}>TELECHARGER
                                        ATTESTATION</Button>
                                </div>
                            </div>

                            <div className="row mt-1 d-flex flex-row align-items-start">
                                <div className="col-md-8 col-12 text-left d-flex flex-row align-items-center">
                        <span className="span-title">
                            Attestation signée par les participants à charger :
                        </span>
                                </div>
                                <div className="col-md-4 col-12 d-flex flex-column file-input">
                                    <input type="file" id="file" className="file" onChange={onFileChanged}/>
                                    <label htmlFor="file">
                                        CHOISIR FICHIER
                                    </label>
                                    <span>
                                        {fileName}
                                        {
                                            fileName !== 'Aucun fichier choisi' ?
                                                <span
                                                    className="ml-3 p-1 border border-secondary rounded remove-item"
                                                    onClick={onRemoveFileClicked}>
                                            X
                                        </span>
                                                : null
                                        }
                                    </span>
                                    {
                                        errors.file ?
                                            <div className="text-danger">
                                                {errors.file[0]}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>


                            <div className="row mt-3 mb-3 justify-content-center">
                                <button className="button-envoyer"
                                        type="button"
                                        onClick={validerFormation}
                                >
                                    VALIDER
                                </button>
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="row mt-3">
                                <div className="col-12 text-left d-flex flex-row align-items-center">
                                    <span className="span-title">Compte rendu formation</span>
                                </div>
                                <div className="col-12 text-left d-flex flex-row align-items-center">
                                    <Form.Control as="textarea" value={child.compte_rendu} rows={3} readOnly/>
                                </div>
                            </div>
                        </React.Fragment>
                }

            </Modal.Body>
        </Modal>
    );
}

export default ModalFormateurOK;