import React, {useEffect, useState} from 'react';
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import {toast} from "react-toastify";
import axios from "axios";
import {SERVER_URL} from "../../../../constants/constants";
import {SESSION_ADMIN} from "../../../../constants/sessionName";

import './updateDirecteur.scss';

function UpdateDirecteur({ handleClose, show, child }) {
    const [selectedGender, setSelectedGender] = useState(child.gender === 'Mme' ? 'Madame' : 'Monsieur');
    const [nom, setNom] = useState(child.last_name);
    const [prenom, setPrenom] = useState(child.first_name);
    const [email, setEmail] = useState(child.email);
    const [phone, setPhone] = useState(child.phone);
    const [zone, setZone] = useState(child.userable.zone);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {

    },[]);

    function  resetData(){
        setSelectedGender('Madame');
        setNom('');
        setPrenom('');
        setEmail('');
        setPhone('');
        setZone('');
        setErrors({});
    }

    const onGenderChanged = item => {
        if (item === 'Madame') {
            setSelectedGender('Madame');
        } else {
            setSelectedGender('Monsieur');
        }
    }

    const onSelectChange = (event) => {
        setZone(event.target.value);
    }

    const handleInputChange = event => {
        const { name, value } = event.target;
        if (name === 'nom') {
            setNom(value);
        }
        else if (name === 'prenom'){
            setPrenom(value);
        }
        else if (name === 'email') {
            setEmail(value);
        }
        else if (name === 'phone') {
            setPhone(value);
        }
    }

    const handleSubmit = (event) => {

        let params = {};
        if (selectedGender !== (child.gender === 'Mme' ? 'Madame' : 'Monsieur')) params = {...params, gender: selectedGender};
        if (nom !== child.last_name) params = {...params, nom};
        if (prenom !== child.first_name) params = {...params, prenom};
        if (email !== child.email) params = {...params, email};
        if (phone !== child.phone) params = {...params, phone};
        if (zone !== child.userable.zone) params = {...params, zone};
        if (params.gender || params.nom || params.prenom || params.email || params.phone || params.zone){
            setLoading(true);
            event.preventDefault();
            const data = { ...params, user_id: child.id, directeur_id: child.userable_id };

            const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
            const headers= {
                'Authorization': `Bearer ${admin_session.token}`
            }

            axios.patch(`${SERVER_URL}/api/admin/update-directeur` , data, { headers } )
                .then(response => {
                    toast.configure();
                    onModalClose('update');
                    toast('Directeur modifié avec succès', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            pauseOnFocusLoss: false,
                        }
                    );
                })
                .catch(error => {
                    setErrors(error.response.data);
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            onModalClose('none');
        }

    };

    const onModalClose = (action) => {
        resetData();
        handleClose(action);
    }

    return (
        <Modal
            backdrop="static"
            show={show}
            onHide={onModalClose}
            size="lg"
            centered
        >
            <Modal.Body>
                <div className="row">
                    <div className="col text-right">
                        <span className="close-button" onClick={() => onModalClose('none')}>X</span>
                    </div>
                </div>
                <div className="d-flex flex-column pl-5 pr-5">
                    <h5>Modifier un Directeur Régional</h5>
                    <div className="horizontal-line-dr w-25 mt-3 mb-3"/>
                </div>
                <div className="d-flex flex-column align-items-center">
                    <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                        <div className="row align-items-center w-100">
                            <div className={`
                            col-5
                            ${ selectedGender === 'Madame' ? "selected-span" : "unselected-span"}
                        `}
                                 onClick={() => onGenderChanged('Madame')}
                            >
                                <span>Madame</span>
                            </div>
                            <div className="col-2" />
                            <div className={`
                            col-5
                            ${ selectedGender === 'Monsieur' ? "selected-span" : "unselected-span"}
                        `}
                                 onClick={() => onGenderChanged('Monsieur')}
                            >
                                <span>Monsieur</span>
                            </div>
                        </div>
                    </div>

                    <Form className="w-100 d-flex flex-column align-items-center" onSubmit={handleSubmit}>

                        <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                            <Form.Control size="sm" type="text" name="nom"
                                          placeholder="Nom" required
                                          className={`${errors.nom ? 'is-invalid border border-danger' : '' }`}
                                          value={nom}
                                          onChange={handleInputChange}
                            />
                            {
                                errors.nom ?
                                    <div className="invalid-feedback">
                                        {errors.nom[0]}
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                            <Form.Control size="sm" type="text" name="prenom"
                                          placeholder="Prénom" required
                                          className={`${errors.prenom ? 'is-invalid border border-danger' : '' }`}
                                          value={prenom}
                                          onChange={handleInputChange}
                            />
                            {
                                errors.prenom ?
                                    <div className="invalid-feedback">
                                        {errors.prenom[0]}
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                            <Form.Control size="sm" type="email" name="email"
                                          placeholder="E-mail" required
                                          className={`${errors.email ? 'is-invalid border border-danger' : '' }`}
                                          value={email}
                                          onChange={handleInputChange}
                            />
                            {
                                errors.email ?
                                    <div className="invalid-feedback">
                                        {errors.email[0]}
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                            <Form.Control size="sm" type="text" name="phone"
                                          placeholder="Téléphone Mobile" required
                                          className={`${errors.phone ? 'is-invalid border border-danger' : '' }`}
                                          value={phone}
                                          onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                              }
                                          }}
                                          onChange={handleInputChange}
                            />
                            {
                                errors.phone ?
                                    <div className="invalid-feedback">
                                        {errors.phone[0]}
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                            <Form.Control
                                as="select"
                                className={`${errors.zone ? 'is-invalid border border-danger' : '' }`}
                                size="sm" onChange={(event) => onSelectChange(event)} value={zone} custom>
                                <option>EST</option>
                                <option>NORD</option>
                                <option>OUEST</option>
                                <option>SUD</option>
                            </Form.Control>
                            {
                                errors.zone ?
                                    <div className="invalid-feedback">
                                        {errors.zone[0]}
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex flex-column align-items-center pt-4 w-25">
                            <Button bsPrefix="w-100 button-d-form-valider" type="submit">

                                {isLoading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    : 'Valider'
                                }
                            </Button>
                        </div>

                    </Form>

                </div>
            </Modal.Body>
        </Modal>
    );
}

export default UpdateDirecteur;