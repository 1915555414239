import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {DELETE_BY_ADMIN} from '../../../constants/types';
import {toast} from 'react-toastify';
import {opticianListAction} from '../../../actions/optician/opticianListAction';
import SpinnerKalendar from '../../common/spinner/spinnerKalendar';
import {Button, FormControl, InputGroup, Table} from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import {confirmAlert} from 'react-confirm-alert';
import OpticienModal from '../modals/OpticienModal/opticienModal';
import {deleteOpticianAction} from '../../../actions/optician/deleteOpticianAction';

import './opticienList.scss';
import UpdateOptician from "./modal/updateOptician";


function OpticienList(props) {
    const opticianListRequest = useSelector(state => state.listItemByAdminReducer, shallowEqual);
    const deleteByAdminRequest = useSelector(state => state.deleteByAdminRecducer, shallowEqual);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('id');
    const [dir, setDir] = useState('asc');
    const [keyWord, setKeyWord] = useState('');
    const[showAddModal, setShowAddModal] = useState(false);
    const[showModal, setShowModal] = useState(false);
    const[modalItem, setModalItem] = useState({});
    useEffect(() => {
        dispatch(opticianListAction());

        if (deleteByAdminRequest.response.status && deleteByAdminRequest.response.status === 200) {
            dispatch({ type: DELETE_BY_ADMIN.DELETE_BY_ADMIN_RESET });
            dispatch(opticianListAction());
            toast.configure();
            toast('Opticien supprimé avec succès', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    pauseOnFocusLoss: false,
                }
            );
        }
    },[dispatch, deleteByAdminRequest]);

    const getOpticienData = (pageNumber, sortParam, dirParam, wordParam) => {
        setPage(pageNumber);
        dispatch(opticianListAction(pageNumber, sortParam, dirParam, wordParam));
    }

    const handleSortData = value => {
        let currentSortDir = '';
        let currentSort = '';
        if(value === sort) {
            currentSortDir = dir ==='asc'?'desc':'asc';
            setDir(currentSortDir);
        }
        currentSort = value;
        setSort(currentSort);
        getOpticienData(page, currentSort, currentSortDir, keyWord);
    }

    const ShowModal = (item) => {
        setShowModal(true);
        setModalItem(item);
    };

    const ShowAddModal = (item) => {
        setShowAddModal(true);
    };

    const hideModal = (action) => {
        if (action === 'none') {
            setShowModal(false);
            setModalItem({});
        } else {
            setKeyWord('');
            dispatch(opticianListAction());
            setShowModal(false);
            setModalItem({});
        }

    };

    const hideAddModal = (action) => {
        if (action === 'none') {
            setShowAddModal(false);
        } else {
            setKeyWord('');
            dispatch(opticianListAction());
            setShowAddModal(false);
        }
    };

    const handleDeleteOptician = (item) => {
        confirmAlert({
            title: 'Confirmation suppression',
            message: `Vous voulez vraiment de supprimer l'opticien : ${item.corporate_name}`,
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => {
                        dispatch(deleteOpticianAction(item.id));
                    }
                },
                {
                    label: 'Non',
                }
            ]
        });
    }

    const onKeyWordChanged = event => {
        setKeyWord(event.target.value);
    }

    const onSearchPress = event => {
        if (event.key === 'Enter') {
            getOpticienData(page, sort, dir, event.target.value);
        }
    }

    return opticianListRequest.loading ? (
        <SpinnerKalendar />
    ) : opticianListRequest.error ? (
        <h2>{opticianListRequest.error}</h2>
    ) : (
        <div className="row w-100">
            <div className="col-6 d-flex flex-column justify-content-center mb-2">
                <InputGroup className="w-75">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Recherche</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        value={keyWord}
                        placeholder="Effectuez une recherche"
                        aria-label="search"
                        aria-describedby="basic-addon1"
                        onKeyPress={onSearchPress}
                        onChange={onKeyWordChanged}
                    />
                </InputGroup>
            </div>
            <div className="col-6 d-flex flex-column justify-content-center align-items-end mb-2">
                <Button variant="success" bsPrefix="btn-ajout text-uppercase px-3" onClick={ShowAddModal}>ajouter opticien</Button>
            </div>
            <div className="col-12">
                <div>
                    <Table className="border" responsive>
                        <thead className="table-header">
                        <tr className="h-25 text-center">
                            <th id="thead-td" className="text-dark">
                                JNJ <i className={ sort === 'jnj_number' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('jnj_number')} />
                            </th>
                            <th id="thead-td">
                                Nom <i className={ sort === 'corporate_name' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('corporate_name')} />
                            </th>
                            <th id="thead-td">
                                Ville <i className={ sort === 'city' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('city')} />
                            </th>
                            <th id="thead-td">
                                CP <i className={ sort === 'postal_code' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('postal_code')} />
                            </th>
                            <th id="thead-td">
                                Téléphone Mobile <i className={ sort === 'phone_mobile' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('phone_mobile')} />
                            </th>
                            <th id="thead-td">
                                Téléphone Fixe <i className={ sort === 'phone_fixe' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('phone_fixe')} />
                            </th>
                            <th id="thead-td">
                                Date de formation
                            </th>
                            <th id="thead-td"></th>
                        </tr>
                        </thead>
                        <tbody className="table-body">
                        {
                            opticianListRequest.response.data && opticianListRequest.response.data.data ?
                                opticianListRequest.response.data.data.map(item =>
                                    <tr key={item.id}>
                                        <td id="tbody-td">
                                            {item.jnj_number}
                                        </td>
                                        <td id="tbody-td">
                                            {item.corporate_name}
                                        </td>
                                        <td id="tbody-td">
                                            {item.city}
                                        </td>
                                        <td id="tbody-td">
                                            {item.postal_code}
                                        </td>
                                        <td id="tbody-td">
                                            {item.phone_mobile}
                                        </td>
                                        <td id="tbody-td">
                                            {item.phone_fixe}
                                        </td>
                                        <td id="tbody-td">
                                            {item.date_formation}
                                        </td>
                                        <td id="tbody-td">
                                            <ul className="list-inline m-0">
                                                <li className="list-inline-item">
                                                    <div className="" onClick={() => ShowModal(item)}>
                                                        <i className="fa fa-edit text-success"></i>
                                                    </div>
                                                </li>
                                                <li className="list-inline-item">
                                                    <div className="" onClick={() => handleDeleteOptician(item)}>
                                                        <i className="fa fa-trash text-danger"></i>
                                                    </div>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                )
                                : null
                        }
                        </tbody>
                    </Table>
                    <div className="row w-100 mt-3 d-flex flex-row justify-content-end">
                        {
                            opticianListRequest.response.data && opticianListRequest.response.data.meta &&

                            <Pagination
                                innerClass="custom-pagination"
                                hideDisabled
                                activePage={opticianListRequest.response.data.meta.current_page}
                                totalItemsCount={opticianListRequest.response.data.meta.total}
                                itemsCountPerPage={opticianListRequest.response.data.meta.per_page}
                                onChange={(pageNumber) => getOpticienData(pageNumber, sort, dir, keyWord)}
                                itemClass="c-page-item"
                                linkClass="c-page-link"
                                activeClass="c-active-class"
                                activeLinkClass="c-active-link"
                                hideFirstLastPages
                            />
                        }

                    </div>
                </div>
            </div>
            {
                modalItem.id ?
                    <UpdateOptician
                        show={showModal}
                        handleClose={hideModal}
                        child={modalItem}
                    />
                    : null
            }
            {
                showAddModal ?
                    <OpticienModal
                        show={showAddModal}
                        handleClose={hideAddModal}
                    />
                : null
            }

        </div>
    );
}

export default OpticienList;