import React, {Component} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import logo from '../../../assets/images/logo.png';
import women from '../../../assets/images/women.png';
import { Popover, OverlayTrigger, Form } from 'react-bootstrap';
import { format, getDay, getWeek } from 'date-fns';
import { fr } from 'date-fns/locale';
import { DateRangePickerCalendar, START_DATE, END_DATE } from 'react-nice-dates';
import {storeFormationByRdv} from "../../../actions/rdv/formationActions";
import {getPath} from "../../../routes/path";

import './validerFormation.scss';
import 'react-nice-dates/build/style.css';

class ValiderFormation extends Component {
    constructor(props) {
        super();
        this.state = {
            date: new Date(),
            showOptionError: false,
            optician: {},
            option1: {},
            option2: {},
            option3: {},
            startDate: null,
            endDate: null,
            startDate2: null,
            endDate2: null,
            startDate3: null,
            endDate3: null,
            focus: START_DATE,
            observation: '',
            errors: {},
        }

    }

    componentDidMount() {
        this.setState({
            optician: this.props.location.state.optician
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const resp = nextProps.formationRequest.response;
        const error = nextProps.formationRequest.error;
        if (resp.status === 201) {
            nextProps.history.push({
                pathname: '/formation-confirmer',
                state: {
                    optician: prevState.optician
                }
            });
        } else if (error.status === 422) {
            return {
                errors: error.data
            }
        }
        return  null;
    }

    handleObservationChange = (event) => {
        this.setState({ observation: event.target.value });
    }

    handleFocusChange = newFocus => {

        if (newFocus === "endDate") {
            if (this.state.startDate === undefined) {
                this.setState({focus: START_DATE });
            }
            else {
                this.setState({focus: newFocus });
            }
        } else if (newFocus === "startDate") {
            if (this.state.endDate === undefined) {
                this.setState({focus: END_DATE });
            }
            else {
                this.setState({focus: newFocus });
            }
        }

    }

    onStartDateChange = (value) => {
        if (getDay(value) === 1 ){
            this.setState({ startDate: value });
            const op1= {...this.state.option1};
            op1.startDate= value;
            this.setState({ option1: op1 });
        }
    }

    onEndDateChange = (value) => {
        if (getDay(value) === 5 ){
            this.setState({ endDate: value });
            const op1= {...this.state.option1};
            op1.endDate= value;
            this.setState({ option1: op1 });
        }
    }

    onStartDateChange2 = (value) => {
        if (getDay(value) === 1 ){
            this.setState({ startDate2: value });
            const op2= {...this.state.option2};
            op2.startDate = value;
            this.setState({ option2: op2 });
        }
    }

    onEndDateChange2 = (value) => {
        if (getDay(value) === 5 ){
            this.setState({ endDate2: value });
            const op2 = {...this.state.option2};
            op2.endDate= value;
            this.setState({ option2: op2 });
        }
    }

    onStartDateChange3 = (value) => {
        if (getDay(value) === 1 ){
            this.setState({ startDate3: value });
            const op3= {...this.state.option3};
            op3.startDate = value;
            this.setState({ option3: op3 });
        }
    }

    onEndDateChange3 = (value) => {
        if (getDay(value) === 5 ){
            this.setState({ endDate3: value });
            const op3 = {...this.state.option3};
            op3.endDate= value;
            this.setState({ option3: op3 });
        }
    }

    validerFormation = () => {
        let array = [];
        let op1 = {};
        let op2 = {};
        let op3 = {};
        if (this.state.option1.startDate && this.state.option1.endDate) {
            op1 = {
                startDate: format(this.state.option1.startDate, 'Y-MM-dd'),
                endDate: format(this.state.option1.endDate, 'Y-MM-dd'),
            };
            array.push(op1);
        }

        if (this.state.option2.startDate && this.state.option2.endDate) {
            op2 = {
                startDate: format(this.state.option2.startDate, 'Y-MM-dd'),
                endDate: format(this.state.option2.endDate, 'Y-MM-dd'),
            };
            array.push(op2);
        }

        if (this.state.option3.startDate && this.state.option3.endDate) {
            op3 = {
                startDate: format(this.state.option3.startDate, 'Y-MM-dd'),
                endDate: format(this.state.option3.endDate, 'Y-MM-dd'),
            };
            array.push(op3);
        }

        const params = {
            "optician_id": this.state.optician.id,
            "detail": JSON.stringify(array),
            "observation": this.state.observation
        };
        if (op1.startDate || op2.startDate || op3.startDate) {
            this.props.storeFormationByRdv(params);
            this.setState({ showOptionError: false});
        } else {
            this.setState({ showOptionError: true});
        }

    }

    getDateSteing = (option) => {
        if (option.startDate !== undefined && option.endDate !== undefined) {
            let start = format(option.startDate, 'dd MMM', { locale: fr });
            let end = format(option.endDate, 'dd MMM', { locale: fr });
            return `${start.slice(0, -1)} au ${end.slice(0, -1)}`;
        }
    }

    getWeekNumber = (option) => {
        if (option.startDate !== undefined && option.endDate !== undefined) {
            let start = getWeek(option.startDate);
            return `Semaine ${start}`;
        } else {
            return `Semaine __`;
        }
    }

    onLogoClicked = () => {
        this.props.history.push(getPath('login'));
        window.location.reload();
    }

    render() {
        const modifiers = {
            disabled: date => getDay(date) === 6 || getDay(date) === 0,
        };

        const popover1 = (
            <Popover style={{ width: "350px"}} id="popover-date1">
                <Popover.Content>
                    <DateRangePickerCalendar
                        minimumDate={new Date()}
                        minimumLength={1}
                        maximumLength={5}
                        month={this.state.date}
                        weekdayFormat="EEEEE"
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        focus={this.state.focus}
                        modifiers={modifiers}
                        onStartDateChange={(e) => {this.onStartDateChange(e)}}
                        onEndDateChange={(e) => {this.onEndDateChange(e)}}
                        onFocusChange={this.handleFocusChange}
                        locale={fr}
                    />
                </Popover.Content>
            </Popover>
        );

        const popover2 = (
            <Popover style={{ width: "350px"}} id="popover-date2">
                <Popover.Content>
                    <DateRangePickerCalendar
                        minimumDate={new Date()}
                        minimumLength={1}
                        maximumLength={5}
                        month={this.state.date}
                        weekdayFormat="EEEEE"
                        startDate={this.state.startDate2}
                        endDate={this.state.endDate2}
                        focus={this.state.focus}
                        modifiers={modifiers}
                        onStartDateChange={(e) => {this.onStartDateChange2(e)}}
                        onEndDateChange={(e) => {this.onEndDateChange2(e)}}
                        onFocusChange={this.handleFocusChange}
                        locale={fr}
                    />
                </Popover.Content>
            </Popover>
        );

        const popover3 = (
            <Popover style={{ width: "350px" }} id="popover-date3">
                <Popover.Content>
                    <DateRangePickerCalendar
                        minimumDate={new Date()}
                        minimumLength={1}
                        maximumLength={5}
                        month={this.state.date}
                        weekdayFormat="EEEEE"
                        startDate={this.state.startDate3}
                        endDate={this.state.endDate3}
                        focus={this.state.focus}
                        modifiers={modifiers}
                        onStartDateChange={(e) => {this.onStartDateChange3(e)}}
                        onEndDateChange={(e) => {this.onEndDateChange3(e)}}
                        onFocusChange={this.handleFocusChange}
                        locale={fr}
                    />
                </Popover.Content>
            </Popover>
        );

        return (
            <div className="mx-0 w-100 px-0 validateinfo">
                <header className="w-100 header">
                    <div className="col-md-12 col-sm-12 d-flex justify-content-between align-items-center p-1">
                        <div className="col-md-9 col-sm-12" onClick={this.onLogoClicked}>
                            <img className="header__logo" src={logo} alt="logo" />
                            <h1 className="h4 font-weight-light">KALENDAR</h1>
                        </div>
                        <div className="col-md-3 col-sm-12 d-flex flex-row align-items-end">
                            <a className="d-flex flex-row align-items-center" href="mailto:schen@veniseactivation.com">
                                <img className="ml-1 header__menwomen-logo" src={women} alt="logo" />
                                <span className="text-dark font-weight-bold ml-2">CONTACT</span>
                            </a>
                        </div>
                    </div>
                </header>

                <div className="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                  content"
                >
                    <div className="col-10  mt-2 bg-white d-flex
                        flex-column p-2">

                        <div className="row">
                            <div className="col-md-6 col-sm-12 text-left d-flex flex-row align-items-center">
                                <span className="span-title">Opticien</span>
                                <div className="v-line ml-5 mr-2"/>
                                <span className="span-text text-uppercase">{this.state.optician.corporate_name}</span>
                            </div>
                            <div className="col-md-6 col-sm-12 text-right d-flex flex-row align-items-center
            justify-content-end">
                                <span className="span-title">NºJNJ</span>
                                <div className="v-line ml-5 mr-2"/>
                                <span className="span-text text-uppercase">{this.state.optician.jnj_number}</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12 text-left d-flex flex-row align-items-center">
                                <span className="span-title">Contact point de vente désigné dans le contrat</span>
                                <div className="v-line ml-5 mr-2"/>
                                <span className="span-text text-uppercase">{this.state.optician.trade_name}</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-6 col-sm-12 d-flex flex-row align-items-center">
                                <span className="span-title">Téléphone fixe</span>
                                <div className="v-line ml-5 mr-2"/>
                                <span className="span-text text-uppercase">{this.state.optician.phone_fixe}</span>
                            </div>
                            <div className="col-md-6 col-sm-12 d-flex flex-row align-items-center">
                                <span className="span-title">Téléphone mobile</span>
                                <div className="v-line ml-5 mr-2"/>
                                <span className="span-text text-uppercase">{this.state.optician.phone_mobile}</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-12 col-sm-12 text-left d-flex flex-row align-items-start">
                                <span className="span-title">Adresse</span>
                                <div className="row d-flex flex-column">
                                    <div className=" d-flex flex-row align-items-center">
                                        <div className="v-line ml-5 mr-2"/>
                                        <span className="span-text text-uppercase">{this.state.optician.address}</span>
                                    </div>
                                    <div className="mt-2 d-flex flex-row align-items-center">
                                        <div className="v-line ml-5 mr-2"/>
                                        <span className="span-text text-uppercase">{this.state.optician.postal_code}</span>
                                        <div className="v-line ml-5 mr-2"/>
                                        <span className="span-text text-uppercase">{this.state.optician.city}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12 text-left d-flex flex-row align-items-center">
                                <span className="span-title text-uppercase">périodes de formation proposées</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-4 col-sm-12 d-flex flex-row align-items-baseline justify-content-center">
                                <span className="span-title mr-2">Option 1</span>
                                <div className="d-flex flex-column align-items-center">
                                    <span className="span-text-semaine mr-2">{this.getWeekNumber(this.state.option1)}</span>
                                    <span className="span-title-date">{this.getDateSteing(this.state.option1)}</span>
                                </div>
                                <OverlayTrigger trigger="click" placement="top" overlay={popover1} rootClose>
                                    <i className="fas fa-calendar-alt text-danger" />
                                </OverlayTrigger>
                            </div>
                            <div className="col-md-4 col-sm-12 d-flex flex-row align-items-baseline justify-content-center">
                                <span className="span-title mr-2">Option 2</span>
                                <div className="d-flex flex-column align-items-center">
                                    <span className="span-text-semaine mr-2">{this.getWeekNumber(this.state.option2)}</span>
                                    <span className="span-title-date">{this.getDateSteing(this.state.option2)}</span>
                                </div>
                                <OverlayTrigger trigger="click" placement="top" overlay={popover2} rootClose>
                                    <i className="fas fa-calendar-alt text-danger" />
                                </OverlayTrigger>
                            </div>
                            <div className="col-md-4 col-sm-12 d-flex flex-row align-items-baseline justify-content-center">
                                <span className="span-title mr-2">Option 3</span>
                                <div className="d-flex flex-column align-items-center">
                                    <span className="span-text-semaine mr-2">{this.getWeekNumber(this.state.option3)}</span>
                                    <span className="span-title-date">{this.getDateSteing(this.state.option3)}</span>
                                </div>
                                <OverlayTrigger trigger="click" placement="top" overlay={popover3} rootClose>
                                    <i className="fas fa-calendar-alt text-danger" />
                                </OverlayTrigger>
                            </div>
                            {
                                this.state.showOptionError ?
                                    <div className="col-12 d-flex flex-row text-danger">
                                        choisie au moin une option
                                    </div>
                                : null
                            }
                        </div>

                        <div className="row mt-3">
                            <div className="col-12 d-flex flex-column">
                                <span className="span-title">Observations</span>
                            </div>
                            <div className="col-12 d-flex flex-column">
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" onChange={(e) => this.handleObservationChange(e)} rows={3} />
                                </Form.Group>
                            </div>
                        </div>

                        <div className="row mb-3 justify-content-center">
                            <button className="button-envoyer"
                                    type="button"
                                    onClick={this.validerFormation}
                            >
                                ENVOYER
                            </button>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formationRequest: state.formationReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        storeFormationByRdv: (params) => dispatch(storeFormationByRdv(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ValiderFormation));