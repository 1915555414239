import React, {useEffect} from 'react';
import {SESSION_ADMIN, SESSION_DR, SESSION_FORMATEUR, SESSION_MANAGER, SESSION_RDV} from "../constants/sessionName";
import {useHistory} from "react-router-dom";
import { getPath } from "../routes/path";

function Index() {
    const history = useHistory();
    useEffect(() => {
        if(localStorage.hasOwnProperty(SESSION_ADMIN)) {
            history.push(getPath('spaceAdmin'));
        } else if(localStorage.hasOwnProperty(SESSION_MANAGER)) {
            history.push(getPath('spaceManager'));
        } else if(localStorage.hasOwnProperty(SESSION_DR)) {
            history.push(getPath('spaceDr'));
        } else if(localStorage.hasOwnProperty(SESSION_RDV)) {
            history.push(getPath('spaceRdv'));
        } else if(localStorage.hasOwnProperty(SESSION_FORMATEUR)) {
            history.push(getPath('spaceFormateur'));
        } else {
            history.push(getPath('login'));
        }
        },[history]);
    return (
        <div>
            Index
        </div>
    );
}

export default Index;