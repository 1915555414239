import React, {useEffect, useState} from 'react';
import {FormControl, InputGroup, Table} from "react-bootstrap";
import Pagination from "react-js-pagination";
import ModalRdv from "../../rdv/formationtable/modal/modalRdv";
import SpinnerKalendar from "../../common/spinner/spinnerKalendar";
import {SESSION_RDV} from "../../../constants/sessionName";
import axios from "axios";
import {SERVER_URL} from "../../../constants/constants";
import {getPath} from "../../../routes/path";

import './formationTable.scss';

function FormationTable(props) {
    const [loading, setLoading] = useState(false);
    const [formations, setFormations] = useState({});
    const [errors, setErrors] = useState({});
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('formations.id');
    const [dir, setDir] = useState('asc');
    const [keyWord, setKeyWord] = useState('');
    const[showModal, setShowModal] = useState(false);
    const[modalItem, setModalItem] = useState({});

    useEffect(() => {
        fetchData(page, sort, dir, keyWord);
    },[]);

    const fetchData = (p, s, d, w) => {
        setLoading(true);
        if (localStorage.hasOwnProperty(SESSION_RDV)) {
            let session = JSON.parse(localStorage.getItem(SESSION_RDV));
            const headers= { 'Authorization': `Bearer ${session.token}` }
            const params = { page: p, sort: s, dir: d, keyWord: w, rdv_id: session.user.userable_id };

            axios.get(`${SERVER_URL}/api/rdv/get-formations-by-rdv` , { params, headers } )
                .then(response => {
                    setFormations(response.data);
                })
                .catch(error => {
                    if (error.response.status === 401){
                        history.push(getPath('login'));
                    } else {
                        setErrors(error.response);
                    }
                })
                .finally(() => {
                    setLoading(false);
                })
        }
        else {
            setErrors(false);
            history.push(getPath('login'));
        }
    }

    const opticianTD = (item) => {
        let color = '';

        switch (item.status) {
            case -1:
                color = '#F98579';
                break;
            case 0:
                color = '#9BC3E5';
                break;
            case 1:
                color = '#A8D08C';
                break;
            default:
                color = '#FFF';
        }

        return <td id="tbody-td" className="text-uppercase"
                   style={{ borderLeft: `15px solid ${color}` }}>
            {item.optician_name}</td>
    }

    const statusTD = (item) => {
        let color = '';
        let title = '';

        switch (item.status) {
            case -1:
                color = '#F98579';
                title = 'À fixer';
                break;
            case 0:
                color = '#9BC3E5';
                title = 'En cours';
                break;
            case 1:
                color = '#A8D08C';
                title = 'OK';
                break;
        }

        return <div className="text-white font-weight-bold text-left"
                    style={{
                        backgroundColor: `${color}`,
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        paddingLeft: '5px',
                    }}>  {title} </div>
    }

    const getFormationData = (pageNumber) => {
        setPage(pageNumber);
        fetchData(pageNumber, sort, dir, keyWord);
    }

    const handleSortData = value => {
        let currentSortDir = '';
        let currentSort = '';
        if(value === sort) {
            currentSortDir = dir ==='asc'?'desc':'asc';
            setDir(currentSortDir);
        }
        currentSort = value;
        setSort(currentSort);
        fetchData(page, currentSort, currentSortDir, keyWord);
    }

    const ShowModal = (item) => {
        setShowModal(true);
        setModalItem(item);
    };

    const hideModal = (action) => {
        if (action === 'none') {
            setShowModal(false);
            setModalItem({});
        } else {
            setKeyWord('');
            fetchData(page, sort, dir, keyWord);
            setShowModal(false);
            setModalItem({});
        }

    };

    const onKeyWordChanged = event => {
        setKeyWord(event.target.value);
    }

    const onSearchPress = event => {
        if (event.key === 'Enter') {
            fetchData(1, sort, dir, event.target.value);
        }
    }

    return loading ? (
        <SpinnerKalendar />
    ) : errors.status ? (
        <h2>Opps {errors.data.message}</h2>
    ) : (
        <div className="w-100 h-100">
                    <div className="col-12 px-0 mt-2">
                        <div>
                            <InputGroup style={{ width: '300px'}}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">Recherche</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    value={keyWord}
                                    placeholder="Effectuez une recherche"
                                    aria-label="search"
                                    aria-describedby="basic-addon1"
                                    onKeyPress={onSearchPress}
                                    onChange={onKeyWordChanged}
                                />
                            </InputGroup>

                            <Table className="mt-2 border" responsive>
                                <thead>
                                <tr>
                                    <th id="thead-td">
                                        Opticien <i className={ sort === 'opticians.corporate_name' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('opticians.corporate_name')} />
                                    </th>
                                    <th id="thead-td">
                                        N° JNJ <i className={ sort === 'opticians.jnj_number' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('opticians.jnj_number')} />
                                    </th>
                                    <th id="thead-td">
                                        CP <i className={ sort === 'opticians.postal_code' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('opticians.postal_code')} />
                                    </th>
                                    <th id="thead-td">
                                        Ville <i className={ sort === 'opticians.city' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('opticians.city')} />
                                    </th>
                                    <th id="thead-td">
                                        Date affiliation <i className={ sort === 'formations.date_affiliation' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('formations.date_affiliation')} />
                                    </th>
                                    <th id="thead-td">
                                        FORMATION <i className={ sort === 'directeurs.zone' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('formations.date_affiliation')} />
                                    </th>
                                    <th id="thead-td">
                                        CALL 1 <i className={ sort === 'directeurs.zone' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('formations.date_affiliation')} />
                                    </th>
                                    <th id="thead-td">
                                        CALL 2 <i className={ sort === 'directeurs.zone' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('formations.date_affiliation')} />
                                    </th>
                                    <th id="thead-td">
                                        Statut <i className={ sort === 'formations.status' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('formations.status')} />
                                    </th>
                                    <th id="thead-td"></th>
                                </tr>
                                </thead>
                                <tbody className="table-body">
                                {
                                    formations.data &&

                                    formations.data.map((item) => (
                                            <tr key={item.id}>
                                                {opticianTD(item)}
                                                <td id="tbody-td">{item.jnj_number}</td>
                                                <td id="tbody-td">{item.postal_code}</td>
                                                <td id="tbody-td" className="text-uppercase">{item.city}</td>
                                                <td id="tbody-td">{item.date_affiliation}</td>
                                                <td id="tbody-td">
                                                    {
                                                        item.status !== -1 ?
                                                        item.formation_initial
                                                        : ''
                                                    }
                                                </td>
                                                <td id="tbody-td">
                                                    {
                                                        item.status !== -1 ?
                                                            item.formation_3
                                                            : ''
                                                    }
                                                </td>
                                                <td id="tbody-td">
                                                    {
                                                        item.status !== -1 ?
                                                            item.formation_10
                                                            : ''
                                                    }
                                                </td>
                                                <td id="tbody-td">
                                                    {statusTD(item)}
                                                </td>
                                                <td id="tbody-td">
                                                    <i
                                                        className="far fa-eye text-dark h6"
                                                        onClick={() => ShowModal(item)}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                }
                                </tbody>
                            </Table>
                            <div className="row w-100 mt-3 d-flex flex-row justify-content-end">
                                {
                                    formations.data &&

                                        <Pagination
                                            innerClass="custom-pagination"
                                            hideDisabled
                                            activePage={formations.meta.current_page}
                                            totalItemsCount={formations.meta.total}
                                            itemsCountPerPage={formations.meta.per_page}
                                            onChange={(pageNumber) => getFormationData(pageNumber)}
                                            itemClass="c-page-item"
                                            linkClass="c-page-link"
                                            activeClass="c-active-class"
                                            activeLinkClass="c-active-link"
                                            hideFirstLastPages
                                        />
                                }

                            </div>
                        </div>
                    </div>
            {
                modalItem.id ?
                    <ModalRdv
                        show={showModal}
                        handleClose={hideModal}
                        child={modalItem}
                    />
                    : null
            }
        </div>
    )
    
}

export default FormationTable;