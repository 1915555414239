import React, {useEffect, useState} from 'react';
import OpticienChart from "../../common/opticienChart/opticienChart";
import FormateurTable from "../../common/formateurTable/formateurTable";
import SecteurTable from "../../common/secteurTable/secteurTable";

import './dashboardManager.scss';
import SpinnerKalendar from "../../common/spinner/spinnerKalendar";
import {SESSION_MANAGER} from "../../../constants/sessionName";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {SERVER_URL} from "../../../constants/constants";
import {getPath} from "../../../routes/path";

function DashboardManager(props) {
    const history = useHistory();
    const[loading, setLoading] = useState(false);
    const[errors, setErrors] = useState({});
    const[formations, setFormations] = useState({});
    useEffect(() => {
        setLoading(true);
        if (localStorage.hasOwnProperty(SESSION_MANAGER)) {
            let session = JSON.parse(localStorage.getItem(SESSION_MANAGER));
            const headers= { 'Authorization': `Bearer ${session.token}` };
            axios.get(`${SERVER_URL}/api/admin/get-formations-by-admin` , { headers } )
                .then(response => {
                    setFormations(response.data);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push(getPath('login'));
                    } else {
                        setErrors(error.response);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        else {
            setLoading(false);
            history.push(getPath('login'));
        }
    },[]);

    return loading ? (
        <SpinnerKalendar />
    ) : errors.status ? (
        <h2>Opps {errors.data.message}</h2>
    ) : (
        <div className="row">
            <div className="col-1"></div>
            <div className="col-4">
                {
                    formations.chart &&
                    <OpticienChart
                        former={formations.chart ?  formations.chart.OK : null }
                        encours={formations.chart ?  formations.chart.COURS : null }
                        afixer={formations.chart ?  formations.chart.FIXER : null }
                    />
                }


            </div>
            <div className="col-7">
                {
                    formations.formateur &&
                    <FormateurTable formations={formations.formateur ? formations.formateur : []}/>
                }
                {
                    formations.secteurs &&
                    <SecteurTable formations={formations.secteurs ? formations.secteurs : []}/>
                }
            </div>
        </div>
    )
}

export default DashboardManager;