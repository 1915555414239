import { STORE_FORMATION_RDV } from '../../constants/types';

const initialState = { loading: false, error: {}, response: {} }

const opticianReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_FORMATION_RDV.STORE_FORMATION_RDV_REQUEST:
            return {
                ...state,
                loading: true
            }
        case STORE_FORMATION_RDV.STORE_FORMATION_RDV_SUCCESS:
            return {
                loading: false,
                response: action.payload,
                error: ''
            }
        case STORE_FORMATION_RDV.STORE_FORMATION_RDV_FAILED:
            return {
                loading: false,
                response: {},
                error: action.payload
            }
        default: return state
    }
}

export default opticianReducer;