import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";
import Pagination from "react-js-pagination";
import SpinnerKalendar from "../../common/spinner/spinnerKalendar";
import {SESSION_ADMIN} from "../../../constants/sessionName";
import axios from "axios";
import {SERVER_URL} from "../../../constants/constants";
import {useHistory} from "react-router-dom";
import {getPath} from "../../../routes/path";

import './zoning.scss';

function Zoning(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [zoning, setZoning] = useState({});
    const [errors, setErrors] = useState({});
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('responsable_dvs.id');
    const [dir, setDir] = useState('asc');
    useEffect(() => {
        fetchData(page, sort, dir);
    },[]);

    const fetchData = (p, s, d) => {
        setLoading(true);
        if (localStorage.hasOwnProperty(SESSION_ADMIN)) {
            let session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
            const headers= { 'Authorization': `Bearer ${session.token}` }
            const params = { page: p, sort: s, dir: d };
            axios.get(`${SERVER_URL}/api/admin/get-all-rdv-zoning` , { params, headers } )
                .then(response => {
                    setZoning(response.data);
                })
                .catch(error => {
                    if (error.response.status === 401){
                        history.push(getPath('login'));
                    } else {
                        setErrors(error.response);
                    }
                })
                .finally(() => {
                    setLoading(false);
                })
        }
        else {
            setLoading(false);
            history.push(getPath('login'));
        }
    }

    const getZoningData = (pageNumber) => {
        setPage(pageNumber);
        fetchData(pageNumber, sort, dir);
    }

    const handleSortData = value => {
        let currentSortDir = 'asc';
        let currentSort;
        if(value === sort) {
            currentSortDir = dir ==='asc'?'desc':'asc';
        }
        setDir(currentSortDir);
        currentSort = value;
        setSort(currentSort);
        fetchData(page, currentSort, currentSortDir);
    }
    return loading ? (
        <SpinnerKalendar />
    ) : errors.status ? (
        <h2>Opps {errors.data.message}</h2>
    ) : (
        <div className="d-flex flex-column align-items-center">
            <Table responsive>
                <thead className="table-header text-center">
                <tr>
                    <th className="border-0">
                        ZONE <i className={ sort === 'directeurs.zone' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('directeurs.zone')} />
                    </th>
                    <th className="border-0">
                        DR <i className={ sort === 'user_directeur.last_name' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('user_directeur.last_name')} />
                    </th>
                    <th className="border-0">
                        RDV <i className={ sort === 'users.last_name' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('users.last_name')} />
                    </th>
                    <th className="border-0">
                        FORMATEUR <i className={ sort === 'user_formateur.first_name' ? 'text-danger fas fa-filter' : 'fas fa-filter'} onClick={() => handleSortData('user_formateur.first_name')} />
                    </th>
                    <th className="border-0">
                        SEC 1
                    </th>
                    <th className="border-0">
                        SEC 2
                    </th>
                    <th className="border-0">
                        SEC 3
                    </th>
                    <th className="border-0">
                        SEC 4
                    </th>
                    <th className="border-0" >
                        SEC 5
                    </th>
                    <th className="border-0">
                        SEC 6
                    </th>
                    <th className="border-0">
                        SEC 7
                    </th>
                    <th className="border-0">
                        SEC 8
                    </th>
                    <th className="border-0">
                        SEC 9
                    </th>
                    <th className="border-0">
                        SEC 10
                    </th>
                </tr>
                </thead>
                <tbody className="table-body text-center">
                {
                    zoning.data &&
                        zoning.data.map(item =>
                            <tr key={item.id}>
                                <td className="border-top-0 text-uppercase" id="tbody-td-z-admin">
                                    {item.zone}
                                </td>
                                <td className="border-top-0 text-uppercase" id="tbody-td-z-admin">
                                    {item.directeur}
                                </td>
                                <td className="border-top-0 text-uppercase" id="tbody-td-z-admin">
                                    {item.rdv_name}
                                </td>
                                <td className="border-top-0 text-uppercase" id="tbody-td-z-admin">
                                    {item.formateur}
                                </td>
                                <td className="border-top-0 text-uppercase" id="tbody-td-z-admin">
                                    { item.secteurs[0] !== undefined ? item.secteurs[0] : ' '}
                                </td>
                                <td className="border-top-0 text-uppercase" id="tbody-td-z-admin">
                                    { item.secteurs[1] !== undefined ? item.secteurs[1] : ' '}
                                </td>
                                <td className="border-top-0 text-uppercase" id="tbody-td-z-admin">
                                    { item.secteurs[2] !== undefined ? item.secteurs[2] : ' '}
                                </td>
                                <td className="border-top-0 text-uppercase" id="tbody-td-z-admin">
                                    { item.secteurs[3] !== undefined ? item.secteurs[3] : ' '}
                                </td>
                                <td className="border-top-0 text-uppercase" id="tbody-td-z-admin">
                                    { item.secteurs[4] !== undefined ? item.secteurs[4] : ' '}
                                </td>
                                <td className="border-top-0 text-uppercase" id="tbody-td-z-admin">
                                    { item.secteurs[5] !== undefined ? item.secteurs[5] : ' '}
                                </td>
                                <td className="border-top-0 text-uppercase" id="tbody-td-z-admin">
                                    { item.secteurs[6] !== undefined ? item.secteurs[6] : ' '}
                                </td>
                                <td className="border-top-0 text-uppercase" id="tbody-td-z-admin">
                                    { item.secteurs[7] !== undefined ? item.secteurs[7] : ' '}
                                </td>
                                <td className="border-top-0 text-uppercase" id="tbody-td-z-admin">
                                    { item.secteurs[8] !== undefined ? item.secteurs[8] : ' '}
                                </td>
                                <td className="border-top-0 text-uppercase" id="tbody-td-z-admin">
                                    { item.secteurs[9] !== undefined ? item.secteurs[9] : ' '}
                                </td>
                            </tr>
                        )
                }
                </tbody>
            </Table>
            <div className="row w-100 mt-3 d-flex flex-row justify-content-end">
                {
                    zoning.data &&

                        <Pagination
                            innerClass="custom-pagination"
                            hideDisabled
                            activePage={zoning.meta.current_page}
                            totalItemsCount={zoning.meta.total}
                            itemsCountPerPage={zoning.meta.per_page}
                            onChange={(pageNumber) => getZoningData(pageNumber)}
                            itemClass="c-page-item"
                            linkClass="c-page-link"
                            activeClass="c-active-class"
                            activeLinkClass="c-active-link"
                            hideFirstLastPages
                        />
                }

            </div>

        </div>
    )
}

export default Zoning;