import React, {useEffect, useState} from 'react';
import OpticienChart from "../../common/opticienChart/opticienChart";
import RdvTable from "../rdvtable/rdvTable";
import FormateurTable from "../../common/formateurTable/formateurTable";
import SpinnerKalendar from "../../common/spinner/spinnerKalendar";
import {useHistory} from "react-router-dom";
import {SESSION_DR} from "../../../constants/sessionName";
import axios from "axios";
import {SERVER_URL} from "../../../constants/constants";
import {getPath} from "../../../routes/path";

import './dashboardDr.scss';

function DashboardDr({user}) {
    const history = useHistory();
    const[loading, setLoading] = useState(false);
    const[errors, setErrors] = useState({});
    const[formations, setFormations] = useState({});
    useEffect(() => {
        setLoading(true);
        if (localStorage.hasOwnProperty(SESSION_DR)) {
            const session = JSON.parse(localStorage.getItem(SESSION_DR));
            const headers= { 'Authorization': `Bearer ${session.token}` }
            const params= { directeur_id: session.user.userable_id }

            axios.get(`${SERVER_URL}/api/directeur/count-formations-by-directeur` , { params, headers } )
                .then(response => {
                    setFormations(response.data);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push(getPath('login'))
                    } else {
                        setErrors(error.response);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        else {
            setLoading(false);
            history.push(getPath('login'))
        }
    },[]);

    return loading ? (
        <SpinnerKalendar />
    ) : errors.status ? (
        <h2>Opps {error.data.message}</h2>
    ) : (
        <div className="row">
            <div className="col-1"></div>
            <div className="col-4">
                {
                    formations.chart &&
                    <OpticienChart
                        region={ user.user ?  user.user.userable.zone : ''}
                        former={formations.chart ?  formations.chart.OK : null }
                        encours={formations.chart ?  formations.chart.COURS : null }
                        afixer={formations.chart ?  formations.chart.FIXER : null }
                    />
                }
            </div>
            <div className="col-7">

                <div className="row">
                    <div className="col-5">
                        {
                            formations &&  formations.rdv &&
                            <RdvTable formations={formations.rdv ? formations.rdv : []}/>
                        }
                    </div>
                    <div className="col-7 w-100 h-100">
                        {
                            formations &&  formations.formateur &&
                            <FormateurTable formations={formations.formateur ? formations.formateur : []}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default DashboardDr;