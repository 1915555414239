import React, {useEffect, useState} from 'react';
import logo from "../../assets/images/logo.png";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {findUserByEmail} from "../../actions/global/findUserByEmailAction";
import {verifyCodeSMS} from "../../actions/global/verifyCodeSMSAction";
import {FIND_USER_BY_EMAIL, VERIFY_CODE_SMS} from "../../constants/types";

import './login.scss'
import {useHistory} from "react-router-dom";
import {getPath} from "../../routes/path";
import {SESSION_ADMIN, SESSION_DR, SESSION_FORMATEUR, SESSION_MANAGER} from "../../constants/sessionName";
import {Button} from "react-bootstrap";

function Login() {
    const history = useHistory();
    const userByEmailRequest = useSelector(state => state.userByEmailReducer, shallowEqual);
    const codeSMSRequest = useSelector(state => state.verifyCodeSMSReducer, shallowEqual);
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [codesms, setCodesms] = useState('');
    const [user, setUser] = useState({ type: '', data: null });
    const [showButton, setShowButton] = useState(false);
    const [checkButton, setCheckButton] = useState(false);

    useEffect(() => {
        localStorage.clear();
        if (userByEmailRequest.response.data) {
            setShowButton(true);
            setUser(userByEmailRequest.response.data);
        }

        if (codeSMSRequest.response.data && codeSMSRequest.response.data.verified) {
            if (user.type === 'Admin') {
                const loggedUser = {
                    user: user.data,
                    token: codeSMSRequest.response.data.token
                }
                localStorage.setItem(SESSION_ADMIN, JSON.stringify(loggedUser));
                history.push(getPath('spaceAdmin'))
            } else if (user.type === 'Manager'){
                const loggedUser = {
                    user: user.data,
                    token: codeSMSRequest.response.data.token
                }
                localStorage.setItem(SESSION_MANAGER, JSON.stringify(loggedUser));
                history.push(getPath('spaceManager'))
            } else if (user.type === 'Directeur'){
                const loggedUser = {
                    user: user.data,
                    token: codeSMSRequest.response.data.token
                }
                localStorage.setItem(SESSION_DR, JSON.stringify(loggedUser));
                history.push(getPath('spaceDr'))
            } else if (user.type === 'Formateur'){
                const loggedUser = {
                    user: user.data,
                    token: codeSMSRequest.response.data.token
                }
                localStorage.setItem(SESSION_FORMATEUR, JSON.stringify(loggedUser));
                history.push(getPath('spaceFormateur'))
            }
        }

    },[email, user, userByEmailRequest, codeSMSRequest, history]);

    const handleEmailChange = event => {
        const { value } = event.target;
        setEmail(value);
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ( re.test(value) ) {
            setCheckButton(true);
        }
        else {
            dispatch({ type: FIND_USER_BY_EMAIL.FIND_USER_BY_EMAIL_RESET })
            setShowButton(false);
            setCheckButton(false);
            setUser({ type: '', data: null });
        }
    }

    const handleCheckEmail = () => {
        const params = { email }
        dispatch(findUserByEmail(params));
    }

    const onBackSpacePress = code => {
        if (code === 'Backspace' && codesms.length === 1) {
            dispatch({ type: VERIFY_CODE_SMS.VERIFY_CODE_SMS_RESET })
            setCodesms('');
        }
    }

    const handleCodesmsChange = event => {
        const { value } = event.target;
        if (Number(value)) {
            setCodesms(value);
            if (value.length === 9) {
                const params = {
                    id: user.data.userable_id,
                    type: user.type,
                    code_sms: Number(value),
                };
                dispatch(verifyCodeSMS(params));
            } else {
                dispatch({ type: VERIFY_CODE_SMS.VERIFY_CODE_SMS_RESET })
            }
        }
    }

    const handleRdvClicked = event => {
        if (event.target.name === "Affilier"){
            history.push({
                pathname: '/verifier-rdv',
                state : {type: 'Affilier', user: user.data },
            });
        } else {
            history.push({
                pathname: '/verifier-rdv',
                state : {type: 'Espace', user: user.data },
            });
        }
    }

    const submitComponent = () =>{
        if (user.exist === false ) {
            return <div className="w-100 align-items-center mb-3">
                <p className="text-white text-center">
                    Nous sommes désolés mais nous ne reconnaissons<br/>pas votre adresse email de login.
                </p>
                <p className="text-white text-center">
                    Veuillez nous contacter au 07 60 17 26 80

                </p>
            </div>
        } else {
            if (user.type === 'Admin' || user.type === 'Manager' ||
                user.type === 'Directeur' || user.type === 'Formateur') {
                return  <div className="w-100 mb-1">
                    <input
                        className="w-100 mb-3 input-codesms" type="email"
                        placeholder="SAISIR LE CODE SMS"
                        maxLength="9"
                        value={codesms}
                        onKeyDown={(e) => onBackSpacePress(e.code) }
                        onChange={handleCodesmsChange}/>

                    <p className="text-white text-center h6"> Saisissez votre code reçu par SMS </p>
                </div>
            }
            else {
                return  <div className="w-100 mb-1">
                    <button
                        className="button-form-login text-uppercase w-100 mb-2"
                        onClick={handleRdvClicked}
                        name="Affilier"
                        type="button">
                        AFFILIER UN OPTICIEN
                    </button>
                    <button
                        className="button-form-login text-uppercase w-100 mb-5"
                        onClick={handleRdvClicked}
                        name="Espace"
                        type="button">
                        accéder à votre espace
                    </button>
                </div>
            }
        }
    }
    return (
        <div className="login-page align-items-center  justify-content-center">
            <div className="d-flex flex-column align-items-center container-custom-login">
                <div className="d-flex flex-column">
                    <img className="login-page__logo" src={logo} alt="logo" />
                    <span className="login-page__title">KALENDAR<i className="fas fa-power-off h6 ml-3" /></span>
                </div>
                <div className="d-flex flex-column align-items-center w-100 mt-3">
                    <div style={{ minHeight: '40px' }}>
                        {user.data !== null && <p className="text-white h6">Bonjour {user.data.first_name} !</p> }
                    </div>
                    <div className="d-flex flex-row w-100">
                        <input
                            className="w-100 mb-3 input-email" type="email"
                            placeholder="VOTRE ADRESSE EMAIL"
                            value={email}
                            onChange={handleEmailChange}
                        />
                        {   checkButton ?
                                <Button bsPrefix="btn-login-ok mb-3" onClick={handleCheckEmail}><i className="fas fa-arrow-right" /></Button>
                            : null
                        }
                    </div>
                    <div className="container-custom-login" style={{ minHeight: '130px' }}>
                        { showButton ?
                            submitComponent()
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;