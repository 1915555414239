import  { combineReducers } from "redux";
import userReducer from "./rdv/userReducer";
import opticianByjnjReducer from "./rdv/opticianByjnjReducer";
import formationReducer from "./rdv/formationReducer";
import formationsByRdvReducer from "./rdv/formationsByRdvReducer";
import formateurReducer from "./formateur/formateurReducer";
import updateFormationReducer from "./rdv/updateFormationReducer";
import formationByIdReducer from "./rdv/formationByIdReducer";
import formationsByFormateurReducer from "./formateur/formationsByFormateurReducer";
import updateFormationByFormateurReducer from "./formateur/updateFormationByFormateurReducer";
import userByEmailReducer from "./global/userByEmailReducer";
import verifyCodeSMSReducer from "./global/verifyCodeSMSReducer";
import storeAdminReducer from "./admin/storeAdminReducer";
import storeManagerReducer from "./manager/storeManagerReducer";
import storeDrReducer from "./admin/storeDrReducer";
import storeRdvReducer from "./admin/storeRdvReducer";
import storeFormateurReducer from "./admin/storeFormateurReducer";
import findRdvFormateurNullReducer from "./admin/findRdvFormateurNullReducer";
import getAllRdvReducer from "./global/getAllRdvReducer";
import storeOpticienReducer from "./admin/storeOpticienReducer";
import uploadOpticianReducer from "./admin/uploadOpticianReducer";
import logoutReducer from "./global/logoutReducer";
import zoningAdminReducer from "./admin/zoningAdminReducer";
import rdvListByFormateurReducer from "./formateur/rdvListByFormateurReducer";
import formationsByManagerReducer from "./manager/formationsByManagerReducer";
import formationsByDirecteurReducer from "./directeur/formationsByDirecteurReducer";
import opticianListByFormateurReducer from "./formateur/opticianListByFormateurReducer";
import countFormationsAdminReducer from "./admin/countFormationsAdminReducer";
import countFormationsByRdvReducer from "./rdv/countFormationsByRdvReducer";
import countFormationsDrReducer from "./directeur/countFormationsDrReducer";
import countFormationsFormateurReducer from "./formateur/countFormationsFormateurReducer";
import finishedFormationFormateurReducer from "./formateur/finishedFormationFormateurReducer";
import listManagerReducer from "./admin/listManagerReducer";
import listAdminsReducer from "./admin/listAdminsReducer";
import deleteByAdminReducer from "./admin/deleteByAdminReducer";
import getAllDrReducer from "./global/getDrReducer";
import updateByAdminReducer from "./admin/updateByAdminReducer";
import listItemByAdminReducer from "./admin/listItemByAdminReducer";

export default combineReducers({
    userByEmailReducer          : userByEmailReducer,
    verifyCodeSMSReducer        : verifyCodeSMSReducer,
    getAllRdvReducer            : getAllRdvReducer,
    getAllDrReducer             : getAllDrReducer,
    logoutReducer               : logoutReducer,

    storeAdminReducer           : storeAdminReducer,
    storeManagerReducer         : storeManagerReducer,
    storeDrReducer              : storeDrReducer,
    storeRdvReducer             : storeRdvReducer,
    storeFormateurReducer       : storeFormateurReducer,
    storeOpticienReducer        : storeOpticienReducer,
    uploadOpticienReducer       : uploadOpticianReducer,
    findRdvFormateurNullReducer : findRdvFormateurNullReducer,
    zoningAdmminReducer         : zoningAdminReducer,
    countFormationsAdminReducer : countFormationsAdminReducer,
    listManagerReducer          : listManagerReducer,
    listAdminReducer            : listAdminsReducer,
    listItemByAdminReducer      : listItemByAdminReducer,
    deleteByAdminRecducer       : deleteByAdminReducer,
    updateByAdminRecducer       : updateByAdminReducer,


    formationsByRdvReducer: formationsByRdvReducer,
    countFormationsByRdvReducer  : countFormationsByRdvReducer,

    rdvListByFormateurReducer               : rdvListByFormateurReducer,
    formationsByFormateurReducer            : formationsByFormateurReducer,
    countFormationsFormateurReducer         : countFormationsFormateurReducer,
    finishedFormationFormateurReducer       : finishedFormationFormateurReducer,

    formationsByManagerReducer  : formationsByManagerReducer,

    formationsByDirecteurReducer  : formationsByDirecteurReducer,
    countFormationsByDrReducer    : countFormationsDrReducer,

    opticianListByFormateurReducer: opticianListByFormateurReducer,

    userReducer: userReducer,
    opticianByjnjReducer: opticianByjnjReducer,
    formationReducer: formationReducer,
    formateurReducer: formateurReducer,
    updateFormationReducer: updateFormationReducer,
    formationByIdReducer: formationByIdReducer,

    updateFormationByFormateurReducer: updateFormationByFormateurReducer,
});