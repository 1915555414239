import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import man from "../../../assets/images/man.png";
import women from "../../../assets/images/women.png";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {SESSION_RDV} from "../../../constants/sessionName";
import {getPath} from "../../../routes/path";
import {verifyCodeSMS} from "../../../actions/global/verifyCodeSMSAction";

import './securePage.scss';

function SecurePage() {
    const location = useLocation();
    const history = useHistory();
    const codeSMSRequest = useSelector(state => state.verifyCodeSMSReducer, shallowEqual);
    const dispatch = useDispatch();
    const [phone, setPhone] = useState('');
    const [codesms, setCodesms] = useState('');

    useEffect(() => {
        let hashphone = location.state.user.phone.split(' ').join('');
        hashphone = `${`${hashphone.substring(0 ,3) } ${ hashphone.substring(3 ,4)}`}* ** ** ** ${hashphone.slice(-2)}`;
        setPhone(hashphone);

        if (codeSMSRequest.response.data && codeSMSRequest.response.data.verified) {
            const loggedUser = {
                user: location.state.user,
                token: codeSMSRequest.response.data.token
            }
            localStorage.setItem(SESSION_RDV, JSON.stringify(loggedUser));
            if (location.state.type === 'Espace') {
                history.push(getPath('spaceRdv'));
            } else {
                history.push(getPath('affilierOpticien'));
            }
        }
    },[location, history, codeSMSRequest]);

    const  handleCodesmsChange = event => {
        const { value } = event.target;
        if (Number(value)) {
            setCodesms(value);
            if (value.length === 9) {
                const params = {
                    id: location.state.user.userable_id,
                    type: "ResponsableDV",
                    code_sms: Number(value),
                };
                dispatch(verifyCodeSMS(params));
            }
        }
    }
    const onBackSpacePress = (code) => {
        if (code === 'Backspace' && codesms.length === 1) {
            setCodesms('');
        }
    }

    const onLogoClicked = () => {
        history.push(getPath('login'));
        window.location.reload();
    }

    return (
        <div className="row mx-0 validateFornation">
            <header className="px-3 header">
                <div className="col-md-12 col-sm-12 d-flex justify-content-between align-items-center p-1">
                    <div className="col-md-9 col-sm-12" onClick={onLogoClicked}>
                        <img className="header__logo" src={logo} alt="logo" />
                        <h1 className="h4 font-weight-light">KALENDAR</h1>
                    </div>
                    <div className="col-md-3 col-sm-12 d-flex flex-row align-items-end">
                        <a className="d-flex flex-row align-items-center" href="mailto:schen@veniseactivation.com">
                            <img className="header__menwomen-logo" src={women} alt="logo" />
                            <span className="text-dark font-weight-bold ml-2">CONTACT</span>
                        </a>

                    </div>
                </div>
            </header>
            <div className="p-0 m-0 d-flex flex-column align-items-center justify-content-center content">
                <p className="p-desc">{location.state.user.first_name},</p>
                <p className="p-desc">
                    Pour des raisons de sécurité nous allons nous assurer qu’il s’agit bien de vous
                </p>
                <p className="p-desc">
                    veuillez saisir ci-dessus le code reçu par SMS au numéro {phone}
                </p>
                <input className="input-numero" type="text"
                       placeholder="CODE"
                       maxLength="9"
                       value={codesms}
                       onKeyDown={(e) =>  onBackSpacePress(e.code)}
                       onChange={handleCodesmsChange}
                />
            </div>
        </div>
    );
}

export default SecurePage;