import axios from "axios";
import{ FIND_USER_BY_EMAIL } from "../../constants/types";
import{ SERVER_URL } from "../../constants/constants";

export const findUserByEmail = (params) => {
    return (dispatch) => {
        dispatch(findUserByEmailRequest())
        axios
            .get(`${SERVER_URL}/api/get-user-by-email` , { params })
            .then(response => {
                const resp = response;
                dispatch(findUserByEmailSuccess(resp))
            })
            .catch(error => {
                dispatch(findUserByEmailFailure(error.message))
            })
    }
}

const findUserByEmailRequest = () => {
    return {
        type: FIND_USER_BY_EMAIL.FIND_USER_BY_EMAIL_REQUEST
    }
}

const findUserByEmailSuccess = response => {
    return {
        type: FIND_USER_BY_EMAIL.FIND_USER_BY_EMAIL_SUCCESS,
        payload: response
    }
}

const findUserByEmailFailure = error => {
    return {
        type: FIND_USER_BY_EMAIL.FIND_USER_BY_EMAIL_FAILED,
        payload: error
    }
}