import axios from "axios";
import{ DELETE_BY_ADMIN } from "../../constants/types";
import {SESSION_ADMIN} from "../../constants/sessionName";
import {SERVER_URL} from "../../constants/constants";

export const deleteOpticianAction = (id) => {
    const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
    const headers= {
        'Authorization': `Bearer ${admin_session.token}`
    }
    return (dispatch) => {
        dispatch(deleteOpticianActionRequest())
        axios
            .delete(`${SERVER_URL}/api/admin/delete-optician?optician_id=${id}` , { headers } )
            .then(response => {
                const resp = response;
                dispatch(deleteOpticianActionSuccess(resp))
            })
            .catch(error => {
                dispatch(deleteOpticianActionFailure(error.response))
            })
    }
}

const deleteOpticianActionRequest = () => {
    return {
        type: DELETE_BY_ADMIN.DELETE_BY_ADMIN_REQUEST
    }
}

const deleteOpticianActionSuccess = response => {
    return {
        type: DELETE_BY_ADMIN.DELETE_BY_ADMIN_SUCCESS,
        payload: response
    }
}

const deleteOpticianActionFailure = error => {
    return {
        type: DELETE_BY_ADMIN.DELETE_BY_ADMIN_FAILED,
        payload: error
    }
}