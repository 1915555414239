import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import rootReducer from "../reducers/index";
import {SERVER_ENV} from "../constants/constants";

let store = null;
if (SERVER_ENV === 'local') {
    store = createStore(
        rootReducer,
        composeWithDevTools(applyMiddleware(thunk, logger))
    )
} else if (SERVER_ENV === 'build') {
    store = createStore(
        rootReducer,
        composeWithDevTools(applyMiddleware(thunk))
    )
}


export default store;