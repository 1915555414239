import React, {useState} from 'react';
import {ListGroup} from "react-bootstrap";

import './sideBar.scss';

function SideBar({ itemChanged }) {
    const [selectedItem, setSelectedItem] = useState('dashboard');

    const changeselectedItem = item => {
        if (item === 'dashboard') {
            setSelectedItem(item);
            itemChanged('dashboard');
        } else if (item === 'zoning') {
            setSelectedItem(item);
            itemChanged('zoning');
        } else if (item === 'opticien') {
            setSelectedItem(item);
            itemChanged('opticien');
        }

    }

    return (
        <ListGroup>
            <ListGroup.Item
                className={`
                text-center
                ${selectedItem === 'dashboard' ? "item-selected" : "item-list" }
                `}
                onClick={() => changeselectedItem('dashboard')}
            >DASH BOARD</ListGroup.Item>
            <ListGroup.Item
                className={`
                text-center
                ${selectedItem === 'zoning' ? "item-selected" : "item-list" }
                `}
                onClick={() => changeselectedItem('zoning')}
            >BDD ZONING</ListGroup.Item>
            <ListGroup.Item
                className={`
                text-center
                ${selectedItem === 'opticien' ? "item-selected" : "item-list" }
                `}
                onClick={() => changeselectedItem('opticien')}
            >BDD OPTICIENS</ListGroup.Item>
        </ListGroup>
    );
}

export default SideBar;