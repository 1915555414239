import React, { useEffect, createRef } from 'react';
import { Doughnut, Chart } from "react-chartjs-2";

import './opticienChart.scss';

function OpticienChart({ former, encours, afixer, extra = '', region = '' }) {

    const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
    Chart.defaults.global.legend.display = false;
    Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
        draw: function() {
            originalDoughnutDraw.apply(this, arguments);

            const chart = this.chart;
            const width = chart.chart.width,
                height = chart.chart.height,
                ctx = chart.chart.ctx;

            const fontSize = (height / 114).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "middle";

            let sum = 0;
            for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
                sum += chart.config.data.datasets[0].data[i];
            }

            const text = sum,
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 1.9;

            ctx.fillText(text, textX, textY);
        }
    });

    const data = {
        labels: [ 'OK FORMES', 'EN COURS', 'RDV A FIXER' ],
        datasets: [{
            data: [former, encours, afixer],
            backgroundColor: [ '#A8D08C', '#9BC3E5', '#F98579'],
            hoverBackgroundColor: [ '#A8D08C', '#9BC3E5', '#F98579'],
        }]
    };


    return(
        <div className="border">
            <p className="pl-4 pt-3 font-weight-bold">OPTICIENS {region}</p>

            <Doughnut
                data={data}
            />


            <div className="d-flex mt-4 flex-column align-items-center">
            <div className="w-75">
            <div className="d-flex flex-row">
            <div className="col px-0">
            <p className="d-flex flex-row">STATUTS FORMATION</p>
            </div>
            </div>
            <div className="d-flex align-items-center ok-legend">
            <div className="col-10 px-0">
            <p className="text-left h5">Formation OK</p>
            </div>
            <div className="col-2 px-0">
            <p className="text-right font-weight-bold h3">{former === null ? 0 : former}</p>
            </div>
            </div>
            <div className="d-flex align-items-center pending-legend">
            <div className="col-10 px-0">
            <p className="text-left h5">RdV fixé</p>
            </div>
            <div className="col-2 px-0">
            <p className="text-right font-weight-bold h3">{encours === null ? 0 : encours}</p>
            </div>
            </div>
            <div className="d-flex align-items-center fixed-legend">
            <div className="col-10 px-0">
            <p className="text-left h5">RdV à prendre</p>
            </div>
            <div className="col-2 px-0">
            <p className="text-right font-weight-bold h3">{afixer === null ? 0 : afixer}</p>
            </div>
            </div>
                {
                    extra !== '' ?
                        <div className="d-flex flex-column align-items-center mt-4">
                            <div className="col-12 px-0">
                                <p className="text-left h5">FORMATEUR</p>
                            </div>
                            <div className="col-12 px-0">
                                <p className="text-left h6">{extra}</p>
                            </div>
                        </div>
                    : null
                }

            </div>
            </div>

        </div>
        );
}

export default OpticienChart;