import React, {useEffect, useState} from 'react';
import logo from "../../../assets/images/logo.png";
import man from "../../../assets/images/man.png";
import women from "../../../assets/images/women.png";
import {SESSION_RDV} from "../../../constants/sessionName";
import {getPath} from "../../../routes/path";
import {useHistory} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

import './affilierOpticien.scss'
import {findOpticianByJnj} from "../../../actions/rdv/opticianActions";

function AffilierOpticien(props) {
    const history = useHistory();
    const opticianByjnjRequest = useSelector(state => state.opticianByjnjReducer, shallowEqual);
    const dispatch = useDispatch();
    const [loggedRdv, setLoggedRdv] = useState({});
    const [showButton, setShowButton] = useState(false);
    const [numOptician, setNumOptician] = useState('');
    const [optician, setOptician] = useState({});
    const [action, setAction] = useState({});

    useEffect(() => {
        if(localStorage.hasOwnProperty(SESSION_RDV)) {
            setLoggedRdv(JSON.parse(localStorage.getItem(SESSION_RDV)));
        } else {
            history.push(getPath('login'));
        }

        if (opticianByjnjRequest.response.data && opticianByjnjRequest.response.data.exist && action === 'valider'){
            setShowButton(true);
            setOptician(opticianByjnjRequest.response.data.data);
            setNumOptician(opticianByjnjRequest.response.data.data.jnj_number);
        }
        else if (opticianByjnjRequest.response.data &&  !opticianByjnjRequest.response.data.exist && action === 'valider'){
            setShowButton(true);
            setOptician({});
            setNumOptician('OOPS !');
        }
    },[history, opticianByjnjRequest, action]);

    const handleNumOptician = event => {
        const { value } = event.target;
        if (value.length < 7) {
            setAction('none');
            setShowButton(false);
        }
        if (Number(value)) {
            setNumOptician(value);
            if (value.length === 7) {
                setAction('valider');
                const params = {
                    num_optician: Number(value),
                    rdv_id: loggedRdv.user.userable_id

                };
                dispatch(findOpticianByJnj(params));
            }
        }
    }

    const onBackSpacePress = (code) => {
        if (code === 'Backspace' && numOptician.length === 1) {
            setNumOptician('');
        }
    }

    const onSubmitClicked = () => {
        if (numOptician  === 'OOPS !') {
            setAction('none');
            setShowButton(false);
            setNumOptician('');
            setOptician({});
        } else {
            history.push({
                pathname: '/sauvegarder-formation',
                state: {
                    user: loggedRdv.user,
                    optician: optician,
                }
            });
        }
    }

    const buttonSubmit = showButton && (
        <div className="d-flex flex-column align-items-center justify-content-center">
            {
                optician.corporate_name ?
                    <div  className="div-optique">
                        <p className="p-desc
          font-weight-bold text-uppercase">{optician.corporate_name}
                        </p>
                    </div>
                    :
                    null
            }
            <button
                className="button-form-valider" type="button"
                onClick={onSubmitClicked}>
                { optician.corporate_name ?
                    "VALIDER"
                    :
                    "RECOMMENCER"
                }

            </button>
            <p className="text-center font-weight-bold text-white h5 mt-3 mb-4">
                { optician.corporate_name ?
                    "S’il ne s’agit pas du bon point de vente, merci de ressaisir le code."
                    :
                    "Il n’y a aucune correspondance de point de vente, merci de recommencer."
                }

            </p>
        </div>
    );

    const onLogoClicked = () => {
        history.push(getPath('login'));
        window.location.reload();
    }

    return (
        <div className="row mx-0 affiliateOptician">
            <header className="header">
                <div className="col-md-12 col-sm-12 d-flex justify-content-between align-items-center p-1">
                    <div className="col-md-9 col-sm-12" onClick={onLogoClicked}>
                        <img className="header__logo" src={logo} alt="logo" />
                        <h1 className="h4 font-weight-light">KALENDAR</h1>
                    </div>
                    <div className="col-md-3 col-sm-12 d-flex flex-row align-items-end">
                        <a className="d-flex flex-row align-items-center" href="mailto:schen@veniseactivation.com">
                            <img className="ml-1 header__menwomen-logo" src={women} alt="women" />
                            <span className="text-dark font-weight-bold ml-2">CONTACT</span>
                        </a>

                    </div>
                </div>
            </header>
            <div className="d-flex flex-column align-items-center justify-content-center content">
                {
                    loggedRdv.user !== undefined ?
                        <p className="p-desc mb-4 h5">{loggedRdv.user.first_name}</p>
                        : null
                }
                <p className="p-desc mb-5 h5">
                    Veulliez saisir le Nº de client JJVC à 7 chiffres de l&apos;opticien<br/>
                    qui doit être formé au programme MYCONTACT ACUVUE®
                </p>
                <input className="input-numero"
                       placeholder="Nº client opticien"
                       maxLength="7"
                       value={numOptician}
                       onKeyDown={(e) =>  onBackSpacePress(e.code)}
                       onChange={handleNumOptician}
                />

                {buttonSubmit}

            </div>
        </div>
    );
}

export default AffilierOpticien;