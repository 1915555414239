import React, {useEffect, useState} from 'react';
import 'moment/locale/fr';
import logo from '../../../assets/images/logo.png';
import moment from "moment";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    SESSION_ADMIN,
    SESSION_DR,
    SESSION_FORMATEUR,
    SESSION_MANAGER,
    SESSION_RDV
} from "../../../constants/sessionName";
import {logoutAction} from "../../../actions/global/logoutAction";
import {getPath} from "../../../routes/path";
import {useHistory} from "react-router-dom";
import {FIND_USER_BY_EMAIL, VERIFY_CODE_SMS} from "../../../constants/types";

import './header.scss';

function Header({user, showContact = false}) {
    const history = useHistory();
    const logoutRequest = useSelector(state => state.logoutReducer, shallowEqual);
    const dispatch = useDispatch();
    const [session, setSession] = useState('');
    const [title, setTitle] = useState('');
    const [contact, setContact] = useState(false);

    useEffect(() => {
            if ( user.userable_type !== undefined && (
                user.userable_type === "App\\Models\\ResponsableDV" || user.userable_type === "App\\Models\\Manager" ||
                user.userable_type === "App\\Models\\Directeur"
            )

                ) {
                setContact(true);
            }
        if (logoutRequest.response.status && logoutRequest.response.status === 201 ) {
            localStorage.removeItem(session);
            dispatch({ type: VERIFY_CODE_SMS.VERIFY_CODE_SMS_RESET });
            dispatch({ type: FIND_USER_BY_EMAIL.FIND_USER_BY_EMAIL_RESET });
            history.push(getPath('login'));
        }
    },[logoutRequest, history, session, dispatch, user, showContact]);
    const fullName = () =>{
        if (user !== undefined){
            return user.first_name +" "+ user.last_name;
        }
    }
    const getCurrentDate = () =>{
        const currentDate = moment().locale("fr").format('dddd DD MMMM YYYY');
        return currentDate;
    }

    const getLastConnexion = () =>{
        if (user !== undefined){
            if (user.last_connexion !== null) {
                let date = user.last_connexion.slice(0, 10);
                let momentDATE = moment(date, "YYYY-MM-DD");
                momentDATE = momentDATE.locale('fr').format('DD MMMM YYYY');
                return momentDATE;
            } else {
                return 'La premiere connexion'
            }

        }
    }

    const handleLogout = () =>{
        let token = '';
        if(localStorage.hasOwnProperty(SESSION_ADMIN)) {
            const local_storage = JSON.parse(localStorage.getItem(SESSION_ADMIN));
            setSession(SESSION_ADMIN);
            token = local_storage.token;
        } else if (localStorage.hasOwnProperty(SESSION_MANAGER)){
            const local_storage = JSON.parse(localStorage.getItem(SESSION_MANAGER));
            setSession(SESSION_MANAGER);
            token = local_storage.token;
        } else if (localStorage.hasOwnProperty(SESSION_DR)){
            const local_storage = JSON.parse(localStorage.getItem(SESSION_DR));
            setSession(SESSION_DR);
            token = local_storage.token;
        } else if (localStorage.hasOwnProperty(SESSION_RDV)){
            const local_storage = JSON.parse(localStorage.getItem(SESSION_RDV));
            setSession(SESSION_RDV);
            token = local_storage.token;
        } else if (localStorage.hasOwnProperty(SESSION_FORMATEUR)){
            const local_storage = JSON.parse(localStorage.getItem(SESSION_FORMATEUR));
            setSession(SESSION_FORMATEUR);
            token = local_storage.token;
        }
        const params = { token, session }
        dispatch(logoutAction(params));
    }

    const onRedirectClicked = () => {
        if(localStorage.hasOwnProperty(SESSION_ADMIN)) {
            window.location.reload();
            history.push(getPath('spaceAdmin'));
        } else if (localStorage.hasOwnProperty(SESSION_MANAGER)){
            window.location.reload();
            history.push(getPath('spaceManager'));
        } else if (localStorage.hasOwnProperty(SESSION_DR)){
            window.location.reload();
            history.push(getPath('spaceDr'));
        } else if (localStorage.hasOwnProperty(SESSION_RDV)){
            window.location.reload();
            history.push(getPath('spaceRdv'));
        } else if (localStorage.hasOwnProperty(SESSION_FORMATEUR)){
            window.location.reload();
            history.push(getPath('spaceFormateur'));
        }
    }

    const titleType = () => {
        if (user.userable_type === "App\\Models\\Admin" || user.userable_type === "App\\Models\\Formateur") {
           return 'ACTIVATION';
        } else if (user.userable_type === "App\\Models\\ResponsableDV"){
            return 'JJVC';
        } else {
            return 'JJVC';
        }
    }

    return (
            <header className="header">
                {/*<div className="row">*/}
                {/*    <div className="col-md-6">*/}
                {/*        <div className="row p-2 align-items-center">*/}
                {/*            <div className="col-6">*/}
                {/*                <div id="logo-redirect" onClick={onRedirectClicked}>*/}
                {/*                    <img className="header__logo" src={logo} alt="logo" />*/}
                {/*                    <h1 className="h5 font-lato-light">KALENDAR</h1>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col-6">*/}
                {/*                <h2 className="text-right font-lato-black h5 font-weight-bold text-white pr-4">*/}
                {/*                    {fullName()}*/}
                {/*                    <br />*/}
                {/*                    { titleType() }*/}
                {/*                </h2>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-6 border-left border-white">*/}
                {/*        <div className="row p-2 align-items-center">*/}
                {/*            <div className="col-6">*/}
                {/*                <div className="font-lato-medium font-size-lg text-height-2 text-white font-weight-bold">*/}
                {/*                    {getCurrentDate()}*/}
                {/*                </div>*/}
                {/*                <div className="font-lato-light text-height-2 text-white">*/}
                {/*                    Dernière connexion*/}
                {/*                    <br />*/}
                {/*                    {getLastConnexion()}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col-6">*/}
                {/*                <div className="btn-power d-flex ">*/}
                {/*                    {*/}
                {/*                        contact &&*/}
                {/*                        <div*/}
                {/*                            className="d-flex align-items-center text-uppercase text-black"*/}
                {/*                            onClick={() => showContact(true)}*/}
                {/*                        >*/}
                {/*                            {*/}
                {/*                                user.userable_type === "App\\Models\\ResponsableDV" ?*/}
                {/*                                    <React.Fragment>*/}
                {/*                                        <i className="fas fa-user text-white mr-1" />*/}
                {/*                                        <div>CONTACT</div>*/}
                {/*                                    </React.Fragment>*/}
                {/*                                    :*/}
                {/*                                    <a className="d-flex flex-row align-items-center" href="mailto:schen@veniseactivation.com">*/}
                {/*                                        <i className="fas fa-user text-white mr-1" />*/}
                {/*                                        <div className="text-dark">CONTACT</div>*/}
                {/*                                    </a>*/}
                {/*                            }*/}

                {/*                        </div>*/}
                {/*                    }*/}
                {/*                    <div*/}
                {/*                        className="d-flex align-items-center text-uppercase text-black ml-3"*/}
                {/*                        onClick={handleLogout}*/}
                {/*                    >*/}
                {/*                        <i className="fas fa-power-off fa-1x px-3" />*/}
                {/*                        <div>Déconnexion</div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="d-flex justify-content-between align-items-center p-2">
                    <div id="logo-redirect" onClick={onRedirectClicked}>
                        <img className="header__logo" src={logo} alt="logo" />
                        <h1 className="h5 font-lato-light">KALENDAR</h1>
                    </div>
                    <div className=" d-none d-md-flex">
                        <h2 className="text-right font-lato-black h5 font-weight-bold text-white pr-4">
                            {fullName()}
                            <br />
                            { titleType() }
                        </h2>
                        <div className="pl-4 header__info-section border-left border-white">
                            <div className="font-lato-medium font-size-lg text-height-2 text-white font-weight-bold">
                                {getCurrentDate()}
                            </div>
                            <div className="font-lato-light text-height-2 text-white">
                                Dernière connexion
                                <br />
                                {getLastConnexion()}
                            </div>
                        </div>
                    </div>
                    <div className="btn-power d-md-flex">
                        {
                            contact &&
                            <div
                                className="d-flex align-items-center justify-content-end text-uppercase text-black"
                                onClick={() => showContact(true)}
                            >
                                {
                                    user.userable_type === "App\\Models\\ResponsableDV" ?
                                        <React.Fragment>
                                            <i className="fas fa-user text-white mr-1" />
                                            <div>CONTACT</div>
                                        </React.Fragment>
                                        :
                                        <a className="d-flex align-items-center" href="mailto:schen@veniseactivation.com">
                                            <i className="fas fa-user text-white mr-1" />
                                            <div className="text-dark">CONTACT</div>
                                        </a>
                                }

                            </div>
                        }
                        <div
                            className="d-flex align-items-center text-uppercase text-black ml-3"
                            onClick={handleLogout}
                        >
                            <i className="fas fa-power-off fa-1x px-1" />
                            <div>Déconnexion</div>
                        </div>
                    </div>
                </div>
            </header>
    );
}

export default Header;
