import axios from "axios";
import {SESSION_FORMATEUR} from "../../constants/sessionName";
import {SERVER_URL} from "../../constants/constants";
import {FINISHED_FORMATIONS_FOMATEUR} from "../../constants/types";

export const finishedFormationFormateurAction = (params) => {
    const admin_session = JSON.parse(localStorage.getItem(SESSION_FORMATEUR));
    const headers= {
        'Authorization': `Bearer ${admin_session.token}`,
        'content-type': 'multipart/form-data'
    }
    return (dispatch) => {
        dispatch(finishedFormationFormateurRequest())
        axios
            .post(`${SERVER_URL}/api/formateur/formation-finished` , params, { headers } )
            .then(response => {
                const resp = response;
                dispatch(finishedFormationFormateurSuccess(resp))
            })
            .catch(error => {
                dispatch(finishedFormationFormateurFailure(error.response))
            })
    }
}

const finishedFormationFormateurRequest = () => {
    return {
        type: FINISHED_FORMATIONS_FOMATEUR.FINISHED_FORMATIONS_FOMATEUR_REQUEST
    }
}

const finishedFormationFormateurSuccess = response => {
    return {
        type: FINISHED_FORMATIONS_FOMATEUR.FINISHED_FORMATIONS_FOMATEUR_SUCCESS,
        payload: response
    }
}

const finishedFormationFormateurFailure = error => {
    return {
        type: FINISHED_FORMATIONS_FOMATEUR.FINISHED_FORMATIONS_FOMATEUR_FAILED,
        payload: error
    }
}