import React, {useEffect, useState} from 'react';
import {Form, Modal, OverlayTrigger} from "react-bootstrap";
import {format, getWeek, parseISO} from "date-fns";
import {fr} from "date-fns/locale";

function OpticianDrModal({show, handleClose, child}) {
    const [color, setColor] = useState('');
    const [option1, setOption1] = useState({});
    const [option2, setOption2] = useState({});
    const [option3, setOption3] = useState({});
    const [observation, setObservation] = useState('');
    useEffect(() => {
        if (child.status === -1) {
            setColor('#F98579');
        } else if (child.status === 0) {
            setColor('#9BC3E5');
        } else {
            setColor('#A8D08C');
        }

        setOption1({});
        setOption2({});
        setOption3({});
        let childDetail = JSON.parse(child.detail);
        if (childDetail.length === 1) {
            setOption1(childDetail[0]);
        } else if (childDetail.length === 2) {
            setOption1(childDetail[0]);
            setOption2(childDetail[1]);

        } else if (childDetail.length === 3) {
            setOption1(childDetail[0]);
            setOption2(childDetail[1]);
            setOption3(childDetail[2]);
        }

        setObservation(child.observation);
    }, []);

    const getWeekNumber = (option) => {
        if (option.startDate !== undefined && option.endDate !== undefined) {
            let start = getWeek(parseISO(option.startDate));
            return `Semaine ${start}`;
        } else {
            return `Semaine __`;
        }
    }

    const getDateSteing = (option) => {
        if (option.startDate !== undefined && option.endDate !== undefined) {
            let start = format(parseISO(option.startDate), 'dd MMM', { locale: fr });
            let end = format(parseISO(option.endDate), 'dd MMM', { locale: fr });
            return `${start.slice(0, -1)} au ${end.slice(0, -1)}`;
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => handleClose('none')}
            size="lg"
            centered
        >
            <Modal.Body style={{
                borderTop: `20px solid ${color}`
            }}>
                <div className="row">
                    <div className="col-6 text-left d-flex flex-row align-items-center">
                        <span className="span-title">Opticien</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.optician_name}</span>
                    </div>
                    <div className="col-6 text-right d-flex flex-row align-items-center
            justify-content-end">
                        <span className="span-title">NºJNJ</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.jnj_number}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 text-left d-flex flex-row align-items-center">
                        <span className="span-title">Contact point de vente désigné dans le contrat</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.trade_name}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-5 d-flex flex-row align-items-center">
                        <span className="span-title">Téléphone fixe</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.phone_fixe}</span>
                    </div>
                    <div className="col d-flex flex-row align-items-center">
                        <span className="span-title">Téléphone mobile</span>
                        <div className="v-line ml-5 mr-2"/>
                        <span className="span-text text-uppercase">{child.phone_mobile}</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 text-left d-flex flex-row align-items-start">
                        <span className="span-title">Adresse</span>
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row align-items-center">
                                <div className="v-line ml-5 mr-2"/>
                                <span className="span-text text-uppercase">{child.address}</span>
                            </div>
                            <div className="mt-2 d-flex flex-row align-items-center">
                                <div className="v-line ml-5 mr-2"/>
                                <span className="span-text text-uppercase">{child.postal_code}</span>
                                <div className="v-line ml-5 mr-2"/>
                                <span className="span-text text-uppercase">{child.city}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 text-left d-flex flex-row align-items-center">
                        <span className="span-title text-uppercase">périodes de formation proposées</span>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-4 d-flex flex-row align-items-baseline justify-content-center">
                        <span className="span-title mr-2">Option 1</span>
                        <div className="d-flex flex-column align-items-center">
                            <span className="span-text-semaine mr-2">{getWeekNumber(option1)}</span>
                            <span className="span-title-date">{getDateSteing(option1)}</span>
                        </div>
                    </div>
                    { option2.startDate ?
                        <div className="col-4 d-flex flex-row align-items-baseline justify-content-center">
                            <span className="span-title mr-2">Option 2</span>
                            <div className="d-flex flex-column align-items-center">
                                <span className="span-text-semaine mr-2">{getWeekNumber(option2)}</span>
                                <span className="span-title-date">{getDateSteing(option2)}</span>
                            </div>
                        </div>
                        : null
                    }
                    { option3.startDate ?
                        <div className="col-4 d-flex flex-row align-items-baseline justify-content-center">
                            <span className="span-title mr-2">Option 3</span>
                            <div className="d-flex flex-column align-items-center">
                                <span className="span-text-semaine mr-2">{getWeekNumber(option3)}</span>
                                <span className="span-title-date">{getDateSteing(option3)}</span>
                            </div>
                        </div>
                        : null
                    }
                </div>

                <div className="row mt-3">
                    <div className="col-12 d-flex flex-column">
                        <span className="span-title">Observations</span>
                    </div>
                    <div className="col-12 d-flex flex-column">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" value={observation} rows={3}  readOnly/>
                        </Form.Group>
                    </div>
                </div>

                {
                    child.status_finished === 1 ?
                        <div className="row mt-3">
                            <div className="col-12 d-flex flex-column">
                                <span className="span-title">Compte rendu formation</span>
                            </div>
                            <div className="col-12 d-flex flex-column">
                                <Form.Control as="textarea" value={child.compte_rendu} rows={3} readOnly/>

                            </div>
                        </div>
                        : null

                }

            </Modal.Body>
        </Modal>
    );
}

export default OpticianDrModal;