import {Provider} from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import store from "./store";
import { getRoutes } from './routes/routes';
import Notfound from "./components/notfound";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';


function App() {
  return (
    <Provider store={store}>
        <Router>
            <Switch>
                {
                    getRoutes().map((route, index) => {
                        return <Route exact {...route} key={index} />
                    })
                }
                <Route component={Notfound} />
            </Switch>
        </Router>
    </Provider>
  );
}

export default App;
