import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import {toast} from "react-toastify";
import axios from "axios";
import {SERVER_URL} from "../../../../constants/constants";
import {SESSION_ADMIN} from "../../../../constants/sessionName";

import './updateFormateur.scss';

function UpdateFormateur({ handleClose, show, child }) {
    const [options, setOptions] = useState([]);
    const [selectedGender, setSelectedGender] = useState(child.gender === 'Mme' ? 'Madame' : 'Monsieur');
    const [nom, setNom] = useState(child.last_name);
    const [prenom, setPrenom] = useState(child.first_name);
    const [email, setEmail] = useState(child.email);
    const [phone, setPhone] = useState(child.phone);
    const [rdvList, setRdvList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {

        const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
        const headers= {
            'Authorization': `Bearer ${admin_session.token}`
        }

        axios.get(`${SERVER_URL}/api/admin/get-rdv-formateur-null` , { headers } )
            .then(response => {
                let op = [];
                const data = response.data.data;

                if (child.rdv_list.length > 0) {
                    child.rdv_list.forEach(item => {
                        op.push( {
                            value: item.id,
                            label: `${item.gender} ${item.first_name} ${item.last_name}`
                        });

                        rdvList.push( {
                            value: item.id,
                            label: `${item.gender} ${item.first_name} ${item.last_name}`
                        });
                    });
                }

                if (data.length > 0 ){

                    data.forEach(item => {
                        op.push( {
                            value: item.userable_id,
                            label: `${item.gender} ${item.first_name} ${item.last_name}`
                        });
                    });
                }

                setOptions(op);

            })
            .catch(error => {
                console.log("error", error);
            });

    },[]);

    const handleChange = selectedOption => {
        setRdvList(selectedOption);
    };

    function  resetData(){
        setSelectedGender('Madame');
        setNom('');
        setPrenom('');
        setEmail('');
        setPhone('');
        setRdvList([]);
        setOptions([]);
        setErrors({});
    }

    const onGenderChanged = item => {
        if (item === 'Madame') {
            setSelectedGender('Madame');
        } else {
            setSelectedGender('Monsieur');
        }
    }

    const handleInputChange = event => {
        const { name, value } = event.target;
        if (name === 'nom') {
            setNom(value);
        }
        else if (name === 'prenom'){
            setPrenom(value);
        }
        else if (name === 'email') {
            setEmail(value);
        }
        else if (name === 'phone') {
            setPhone(value);
        }
    }

    const handleSubmit = (event) => {
        let params = {};
        if (selectedGender !== (child.gender === 'Mme' ? 'Madame' : 'Monsieur')) params = {...params, gender: selectedGender};
        if (nom !== child.last_name) params = {...params, nom};
        if (prenom !== child.first_name) params = {...params, prenom};
        if (email !== child.email) params = {...params, email};
        if (phone !== child.phone) params = {...params, phone};
        if (rdvList !== null ) {
            let RdvIds = [];
            rdvList.forEach(rdv => {
                RdvIds.push(rdv.value);
            });
            params = {...params, rdv_ids: RdvIds};
        } else {
            params = {...params, rdv_ids: [] };
        }
        if (params.gender || params.nom || params.prenom || params.email || params.phone || params.rdv_ids){
            setLoading(true);
            event.preventDefault();
            const data = { ...params, user_id: child.id, formateur_id: child.userable_id };

            const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
            const headers = {
                'Authorization': `Bearer ${admin_session.token}`
            }

            axios.patch(`${SERVER_URL}/api/admin/update-formateur` , data, { headers } )
                .then(response => {
                    toast.configure();
                    onCloseClicked('update');
                    toast('Formateur modifié avec succès', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            pauseOnFocusLoss: false,
                        }
                    );
                })
                .catch(error => {
                    setErrors(error.response.data);
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            onCloseClicked('none');
        }

    };

    const onCloseClicked = (action) => {
        resetData();
        handleClose(action);

    }


    return (
        <Modal
            backdrop="static"
            show={show}
            onHide={onCloseClicked}
            size="lg"
            centered
        >
            <Modal.Body>
                <div className="row">
                    <div className="col text-right">
                        <span className="close-button" onClick={() => onCloseClicked('none')}>X</span>
                    </div>
                </div>
                <div className="d-flex flex-column pl-5 pr-5">
                    <h5>Modifier un Formateur</h5>
                    <div className="horizontal-line-formateur w-25 mt-3 mb-3"/>
                </div>
                <div className="d-flex flex-column align-items-center">
                    <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                        <div className="row align-items-center w-100">
                            <div className={`
                            col-5
                            ${ selectedGender === 'Madame' ? "selected-span" : "unselected-span"}
                        `}
                                 onClick={() => onGenderChanged('Madame')}
                            >
                                <span>Madame</span>
                            </div>
                            <div className="col-2" />
                            <div className={`
                            col-5
                            ${ selectedGender === 'Monsieur' ? "selected-span" : "unselected-span"}
                        `}
                                 onClick={() => onGenderChanged('Monsieur')}
                            >
                                <span>Monsieur</span>
                            </div>
                        </div>
                    </div>

                    <Form className="w-100 d-flex flex-column align-items-center"  onSubmit={handleSubmit}>

                        <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                            <Form.Control size="sm" type="text" name="nom"
                                          placeholder="Nom" required
                                          className={`${errors.nom ? 'is-invalid border border-danger' : '' }`}
                                          value={nom}
                                          onChange={handleInputChange}
                            />
                            {
                                errors.nom ?
                                    <div className="invalid-feedback">
                                        {errors.nom[0]}
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                            <Form.Control size="sm" type="text" name="prenom"
                                          placeholder="Prénom" required
                                          className={`${errors.prenom ? 'is-invalid border border-danger' : '' }`}
                                          value={prenom}
                                          onChange={handleInputChange}
                            />
                            {
                                errors.prenom ?
                                    <div className="invalid-feedback">
                                        {errors.prenom[0]}
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                            <Form.Control size="sm" type="email" name="email"
                                          placeholder="E-mail" required
                                          className={`${errors.email ? 'is-invalid border border-danger' : '' }`}
                                          value={email}
                                          onChange={handleInputChange}
                            />
                            {
                                errors.email ?
                                    <div className="invalid-feedback">
                                        {errors.email[0]}
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                            <Form.Control size="sm" type="text" name="phone"
                                          placeholder="Téléphone Mobile" required
                                          className={`${errors.phone ? 'is-invalid border border-danger' : '' }`}
                                          value={phone}
                                          onKeyPress={(event) => {
                                              if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                              }
                                          }}
                                          onChange={handleInputChange}
                            />
                            {
                                errors.phone ?
                                    <div className="invalid-feedback">
                                        {errors.phone[0]}
                                    </div>
                                    : null
                            }
                        </div>

                        <div className="d-flex flex-column pt-1 pb-1 w-75">
                            <h6>RDV</h6>
                        </div>

                        <div className="d-flex flex-column pt-1 pb-1 w-75">
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                value={rdvList}
                                onChange={handleChange}
                                options={options}
                            />
                        </div>

                        <div className="d-flex flex-column align-items-center pt-4 w-25">
                            <Button bsPrefix="w-100 button-f-form-valider" type="submit">
                                {isLoading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    : 'Valider'
                                }
                            </Button>
                        </div>

                    </Form>

                </div>
            </Modal.Body>
        </Modal>
    );
}

export default UpdateFormateur;