import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {SESSION_RDV} from "../../../constants/sessionName";
import {getPath} from "../../../routes/path";
import Header from "../../common/header/header";
import OpticienChart from "../../common/opticienChart/opticienChart";
import FormationTable from "../formationtable/formationTable";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {COUNT_FORMATIONS_ADMIN} from "../../../constants/types";
import {countFormationsByRdvAction} from "../../../actions/rdv/countFormationsByRdvAction";
import ContactRdv from "../contact/contactRdv";
import axios from "axios";
import {SERVER_URL} from "../../../constants/constants";

import './spaceRdv.scss';

export default function SpaceRdv(props) {
    const history = useHistory();
    const formationsCountRequest = useSelector(state => state.countFormationsByRdvReducer, shallowEqual);
    const dispatch = useDispatch();
    const [formations, setFormations] = useState({});
    const [loggedRdv , setLoggedRdv] = useState({});
    const [displayContact , setDisplayContact] = useState(false);
    useEffect(() => {
        if(localStorage.hasOwnProperty(SESSION_RDV)) {
            let rdv = JSON.parse(localStorage.getItem(SESSION_RDV));
            setLoggedRdv(rdv);

            const headers= {
                'Authorization': `Bearer ${rdv.token}`
            }
            const params= {
                rdv_id: rdv.user.userable_id
            }

            axios
                .get(`${SERVER_URL}/api/rdv/count-formations-by-rdv` , { params, headers } )
                .then(response => {
                    const data = response.data;
                    setFormations(data);
                })
                .catch(error => {
                    console.log(error);
                    if (error.response.status === 401) {
                        history.push(getPath('login'));
                    }
                })

        } else {
            history.push(getPath('login'));
        }
    },[]);

    const handleDisplayContact =(value)=> {
        setDisplayContact(value);
    }
    return (
        <div className="box w-100 h-100">
            {
                loggedRdv.user !== undefined ?
                    <Header user={loggedRdv.user} showContact={handleDisplayContact} />
                    : null
            }
            {
                displayContact ?
                    <div className="row content">
                        <ContactRdv display={setDisplayContact}/>
                    </div>
                :
                    <div className="row mt-1 m-0">
                        {
                            formationsCountRequest.loading ?
                                <div className="col-12">Loading</div>
                            :
                                <div className="col-md-2 col-sm-12">
                                    {
                                        formations.OK !== undefined ?
                                            <OpticienChart
                                                former={formations.OK ?  formations.OK : null }
                                                encours={formations.COURS ?  formations.COURS : null }
                                                afixer={formations.FIXER ?  formations.FIXER : null }
                                                extra={loggedRdv.user.others.formateur.first_name+' '+loggedRdv.user.others.formateur.last_name}
                                            />
                                            : null
                                    }
                                </div>
                        }
                        <div className="col-md-10 col-sm-12">
                            <FormationTable />
                        </div>

                    </div>
            }
        </div>
    );
}