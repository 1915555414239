import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import {toast} from "react-toastify";
import axios from "axios";
import {SESSION_ADMIN} from "../../../../constants/sessionName";
import {SERVER_URL} from "../../../../constants/constants";

import './formateurModal.scss';

function FormateurModal({ show, handleClose }) {
    const [options, setOptions] = useState([]);
    const [selectedGender, setSelectedGender] = useState('Madame');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [rdvList, setRdvList] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
        const headers= {
            'Authorization': `Bearer ${admin_session.token}`
        }

        axios.get(`${SERVER_URL}/api/admin/get-rdv-formateur-null` , { headers } )
            .then(response => {
                const data = response.data.data;

                if (data.length > 0 ){
                    let op = [];
                    data.forEach(item => {
                        op.push( {
                            value: item.userable_id,
                            label: `${item.gender} ${item.first_name} ${item.last_name}`
                        });
                    });

                    setOptions(op);
                }
                else {
                    toast.configure();
                    onCloseClicked('none');
                    toast('Aucun rdv a affecte au formateur', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            pauseOnFocusLoss: false,
                        }
                    );
                }

            })
            .catch(error => {
                console.log("error", error);
            })

    },[]);

    const handleChange = selectedOption => {
        setRdvList(selectedOption);
    };

    function  resetData(){
        setSelectedGender('Madame');
        setNom('');
        setPrenom('');
        setEmail('');
        setPhone('');
        setRdvList([]);
        setErrors({});
        setOptions([]);
    }

    const onGenderChanged = item => {
        if (item === 'Madame') {
            setSelectedGender('Madame');
        } else {
            setSelectedGender('Monsieur');
        }
    }

    const handleInputChange = event => {
        const { name, value } = event.target;
        if (name === 'nom') {
            setNom(value);
        }
        else if (name === 'prenom'){
            setPrenom(value);
        }
        else if (name === 'email') {
            setEmail(value);
        }
        else if (name === 'phone') {
            setPhone(value);
        }
    }

    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        let RdvIds = [];
        rdvList.forEach(rdv => {
           RdvIds.push(rdv.value);
        });
        const params = {
            gender: selectedGender, nom,
            prenom, email, phone, rdv_ids: RdvIds };

        const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
        const headers= {
            'Authorization': `Bearer ${admin_session.token}`
        }

        axios.post(`${SERVER_URL}/api/admin/store-formateur` , params, { headers })
            .then(resp => {
                onCloseClicked('add');
                toast('Formateur ajouté avec succès', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        pauseOnFocusLoss: false,
                    }
                );
            })
            .catch(err => {
                setErrors(err.response.data);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const onCloseClicked = (action) => {
        resetData();
        handleClose(action);

    }


    return (
        <Modal
            backdrop="static"
            show={show}
            onHide={onCloseClicked}
            size="lg"
            centered
        >
            <Modal.Body>
                <div className="row">
                    <div className="col text-right">
                        <span className="close-button" onClick={() => onCloseClicked('none')}>X</span>
                    </div>
                </div>
                <div className="d-flex flex-column pl-5 pr-5">
                    <h5>Ajouter un nouveau Formateur</h5>
                    <div className="horizontal-line-formateur w-25 mt-3 mb-3"/>
                </div>
                <div className="d-flex flex-column align-items-center">
                    <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                        <div className="row align-items-center w-100">
                            <div className={`
                            col-5
                            ${ selectedGender === 'Madame' ? "selected-span" : "unselected-span"}
                        `}
                                 onClick={() => onGenderChanged('Madame')}
                            >
                                <span>Madame</span>
                            </div>
                            <div className="col-2" />
                            <div className={`
                            col-5
                            ${ selectedGender === 'Monsieur' ? "selected-span" : "unselected-span"}
                        `}
                                 onClick={() => onGenderChanged('Monsieur')}
                            >
                                <span>Monsieur</span>
                            </div>
                        </div>
                    </div>

                    <Form className="w-100 d-flex flex-column align-items-center"  onSubmit={handleSubmit}>

                    <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                        <Form.Control size="sm" type="text" name="nom"
                                      placeholder="Nom" required
                                      className={`${errors && errors.nom ? 'is-invalid border border-danger' : '' }`}
                                      value={nom}
                                      onChange={handleInputChange}
                        />
                        {
                            errors.nom ?
                                <div className="invalid-feedback">
                                    {errors && errors.nom[0]}
                                </div>
                                : null
                        }
                    </div>

                    <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                        <Form.Control size="sm" type="text" name="prenom"
                                      placeholder="Prénom" required
                                      className={`${errors && errors.prenom ? 'is-invalid border border-danger' : '' }`}
                                      value={prenom}
                                      onChange={handleInputChange}
                        />
                        {
                            errors.prenom ?
                                <div className="invalid-feedback">
                                    {errors && errors.prenom[0]}
                                </div>
                                : null
                        }
                    </div>

                    <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                        <Form.Control size="sm" type="email" name="email"
                                      placeholder="E-mail" required
                                      className={`${errors && errors.email ? 'is-invalid border border-danger' : '' }`}
                                      value={email}
                                      onChange={handleInputChange}
                        />
                        {
                            errors.email ?
                                <div className="invalid-feedback">
                                    {errors && errors.email[0]}
                                </div>
                                : null
                        }
                    </div>

                    <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                        <Form.Control size="sm" type="text" name="phone"
                                      placeholder="Téléphone Mobile" required
                                      className={`${errors && errors.phone ? 'is-invalid border border-danger' : '' }`}
                                      value={phone}
                                      onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                          }
                                      }}
                                      onChange={handleInputChange}
                        />
                        {
                            errors.phone ?
                                <div className="invalid-feedback">
                                    {errors && errors.phone[0]}
                                </div>
                                : null
                        }
                    </div>

                    <div className="d-flex flex-column pt-1 pb-1 w-75">
                        <h6>RDV</h6>
                    </div>

                    <div className="d-flex flex-column pt-1 pb-1 w-75">
                        <Select
                            isMulti
                            closeMenuOnSelect={false}
                            value={rdvList}
                            onChange={handleChange}
                            options={options}
                        />
                    </div>

                    <div className="d-flex flex-column align-items-center pt-4 w-25">
                        <Button bsPrefix="w-100 button-f-form-valider" type="submit">
                            {isLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                : 'Valider'
                            }
                        </Button>
                    </div>

                    </Form>

                </div>
            </Modal.Body>
        </Modal>
    );
}

export default FormateurModal;