import React, {useEffect, useState} from 'react';
import { Form } from "react-bootstrap";
import {DatePicker} from "react-nice-dates";
import { parseISO, addDays, getDay } from "date-fns";
import TimePicker from 'react-time-picker';
import {fr} from "date-fns/locale";
import 'react-nice-dates/build/style.css'

function RendezVousCard({ item, setDate, option, child }) {
    const [name, setName] = useState('');
    const [rendezVous, setRendezVous] = useState('présentiel');
    const [date1, setDate1] = useState();
    const [date2, setDate2] = useState();
    const [date3, setDate3] = useState();
    const [timeD1, setTimeD1] = useState('08:00');
    const [timeF1, setTimeF1] = useState('08:00');
    const [timeD2, setTimeD2] = useState('08:00');
    const [timeF2, setTimeF2] = useState('08:00');
    const [timeD3, setTimeD3] = useState('08:00');
    const [timeF3, setTimeF3] = useState('08:00');
    const [dateS, setDateS] = useState({});
    const [disabledDate, setDisabledDate] = useState({});

    useEffect(() => {
        if (option === 'option1') {
            setName('Rendez-vous 1');
        } else if (option === 'option2') {
            setName('Rendez-vous 2');
        } else {
            setName('Rendez-vous 3');
        }

        if (child.detail_formateur === null ) {
            setDate1(parseISO(item.startDate));
            setDate2(addDays(parseISO(item.startDate), 3));
            setDate3(addDays(parseISO(item.startDate), 10));
            setDateS({
                rdv_type: rendezVous,
                rdv_1   : [parseISO(item.startDate), timeD1, timeF1],
                rdv_3   : [addDays(parseISO(item.startDate), 3), timeD2, timeF2],
                rdv_10  : [addDays(parseISO(item.startDate), 10), timeD3, timeF3],
            });
            setDate(option, {
                rdv_type: rendezVous,
                rdv_1   : [parseISO(item.startDate), timeD1, timeF1],
                rdv_3   : [addDays(parseISO(item.startDate), 3), timeD2, timeF2],
                rdv_10  : [addDays(parseISO(item.startDate), 10), timeD3, timeF3],
            });
        } else {
            const detailF = JSON.parse(child.detail_formateur);
            if (option === 'option1') {
                setRendezVous(detailF[0].rdv_type);
                setDate1(parseISO(detailF[0].rdv_1[0]));
                setDate2(parseISO(detailF[0].rdv_3[0]));
                setDate3(parseISO(detailF[0].rdv_10[0]));
                setTimeD1(detailF[0].rdv_1[1]);
                setTimeD2(detailF[0].rdv_3[1]);
                setTimeD3(detailF[0].rdv_10[1]);
                setTimeF1(detailF[0].rdv_1[2]);
                setTimeF2(detailF[0].rdv_3[2]);
                setTimeF3(detailF[0].rdv_10[2]);

                setDateS({
                    rdv_type: detailF[0].rdv_type,
                    rdv_1   : [parseISO(detailF[0].rdv_1[0]), detailF[0].rdv_1[1], detailF[0].rdv_1[2]],
                    rdv_3   : [parseISO(detailF[0].rdv_3[0]), detailF[0].rdv_3[1], detailF[0].rdv_3[2]],
                    rdv_10  : [parseISO(detailF[0].rdv_10[0]), detailF[0].rdv_10[1], detailF[0].rdv_10[2]],
                });
                setDate(option, {
                    rdv_type: detailF[0].rdv_type,
                    rdv_1   : [parseISO(detailF[0].rdv_1[0]), detailF[0].rdv_1[1], detailF[0].rdv_1[2]],
                    rdv_3   : [parseISO(detailF[0].rdv_3[0]), detailF[0].rdv_3[1], detailF[0].rdv_3[2]],
                    rdv_10  : [parseISO(detailF[0].rdv_10[0]), detailF[0].rdv_10[1], detailF[0].rdv_10[2]],
                });
                let obj = {};
                if (parseISO(detailF[0].rdv_1[0])  < new Date()){  obj = {...obj, disabled1: true  } }
                if (parseISO(detailF[0].rdv_3[0])  < new Date()){  obj = {...obj, disabled2: true  } }
                if (parseISO(detailF[0].rdv_10[0])  < new Date()){  obj = {...obj, disabled3: true  } }
                setDisabledDate(obj);

            } else if (option === 'option2') {
                setRendezVous(detailF[1].rdv_type);
                setDate1(parseISO(detailF[1].rdv_1[0]));
                setDate2(parseISO(detailF[1].rdv_3[0]));
                setDate3(parseISO(detailF[1].rdv_10[0]));
                setTimeD1(detailF[1].rdv_1[1]);
                setTimeD2(detailF[1].rdv_3[1]);
                setTimeD3(detailF[1].rdv_10[1]);
                setTimeF1(detailF[1].rdv_1[2]);
                setTimeF2(detailF[1].rdv_3[2]);
                setTimeF3(detailF[1].rdv_10[2]);

                setDateS({
                    rdv_type: detailF[1].rdv_type,
                    rdv_1   : [parseISO(detailF[1].rdv_1[0]), detailF[1].rdv_1[1], detailF[1].rdv_1[2]],
                    rdv_3   : [parseISO(detailF[1].rdv_3[0]), detailF[1].rdv_3[1], detailF[1].rdv_3[2]],
                    rdv_10  : [parseISO(detailF[1].rdv_10[0]), detailF[1].rdv_10[1], detailF[1].rdv_10[2]],
                });
                setDate(option, {
                    rdv_type: detailF[1].rdv_type,
                    rdv_1   : [parseISO(detailF[1].rdv_1[0]), detailF[1].rdv_1[1], detailF[1].rdv_1[2]],
                    rdv_3   : [parseISO(detailF[1].rdv_3[0]), detailF[1].rdv_3[1], detailF[1].rdv_3[2]],
                    rdv_10  : [parseISO(detailF[1].rdv_10[0]), detailF[1].rdv_10[1], detailF[1].rdv_10[2]],
                });

                let obj = {};
                if (parseISO(detailF[1].rdv_1[0])  < new Date()){  obj = {...obj, disabled1: true  } }
                if (parseISO(detailF[1].rdv_3[0])  < new Date()){  obj = {...obj, disabled2: true  } }
                if (parseISO(detailF[1].rdv_10[0])  < new Date()){  obj = {...obj, disabled3: true  } }
                setDisabledDate(obj);
            } else {
                setRendezVous(detailF[2].rdv_type);
                setDate1(parseISO(detailF[2].rdv_1[0]));
                setDate2(parseISO(detailF[2].rdv_3[0]));
                setDate3(parseISO(detailF[2].rdv_10[0]));
                setTimeD1(detailF[2].rdv_1[1]);
                setTimeD2(detailF[2].rdv_3[1]);
                setTimeD3(detailF[2].rdv_10[1]);
                setTimeF1(detailF[2].rdv_1[2]);
                setTimeF2(detailF[2].rdv_3[2]);
                setTimeF3(detailF[2].rdv_10[2]);

                setDateS({
                    rdv_type: detailF[2].rdv_type,
                    rdv_1   : [parseISO(detailF[2].rdv_1[0]), detailF[2].rdv_1[1], detailF[2].rdv_1[2]],
                    rdv_3   : [parseISO(detailF[2].rdv_3[0]), detailF[2].rdv_3[1], detailF[2].rdv_3[2]],
                    rdv_10  : [parseISO(detailF[2].rdv_10[0]), detailF[2].rdv_10[1], detailF[2].rdv_10[2]],
                });
                setDate(option, {
                    rdv_type: detailF[2].rdv_type,
                    rdv_1   : [parseISO(detailF[2].rdv_1[0]), detailF[2].rdv_1[1], detailF[2].rdv_1[2]],
                    rdv_3   : [parseISO(detailF[2].rdv_3[0]), detailF[2].rdv_3[1], detailF[2].rdv_3[2]],
                    rdv_10  : [parseISO(detailF[2].rdv_10[0]), detailF[2].rdv_10[1], detailF[2].rdv_10[2]],
                });

                let obj = {};
                if (parseISO(detailF[2].rdv_1[0])  < new Date()){  obj = {...obj, disabled1: true  } }
                if (parseISO(detailF[2].rdv_3[0])  < new Date()){  obj = {...obj, disabled2: true  } }
                if (parseISO(detailF[2].rdv_10[0])  < new Date()){  obj = {...obj, disabled3: true  } }
                setDisabledDate(obj);
            }
        }

    },[setDate1, item.startDate, option, child]);

    const onSelectChange = (event) => {
        setRendezVous(event.target.value);
        let obj = dateS;
        dateS.rdv_type = event.target.value;
        setDateS(obj);
        setDate(option, dateS);
    }

    const onDateChange = value => {
        setDate1(value);
        let arr = dateS.rdv_1;
        arr[0] = value;
        setDateS({...dateS , rdv_1: arr});

        setDate(option, dateS);
    }

    const onDateChange2 = value => {
        setDate2(value);
        let arr = dateS.rdv_3;
        arr[0] = value;
        setDateS({...dateS , rdv_3: arr});

        setDate(option, dateS);
    }

    const onDateChange3 = value => {
        setDate3(value);
        let arr = dateS.rdv_10;
        arr[0] = value;
        setDateS({...dateS , rdv_10: arr});

        setDate(option, dateS);
    }

    const onTimechange = (item, value) => {
        let arr = [];
        switch (item) {
            case 'timeD1':
                setTimeD1(value);
                arr = dateS.rdv_1;
                arr[1] = value;
                setDateS({...dateS , rdv_1: arr});

                setDate(option, dateS);
                break;
            case 'timeF1':
                setTimeF1(value);
                arr = dateS.rdv_1;
                arr[2] = value;
                setDateS({...dateS , rdv_1: arr});

                setDate(option, dateS);
                break;
            case 'timeD2':
                setTimeD2(value);
                arr = dateS.rdv_3;
                arr[1] = value;
                setDateS({...dateS , rdv_3: arr});

                setDate(option, dateS);
                break;
            case 'timeF2':
                setTimeF2(value);
                arr = dateS.rdv_3;
                arr[2] = value;
                setDateS({...dateS , rdv_3: arr});

                setDate(option, dateS);
                break;
            case 'timeD3':
                setTimeD3(value);
                arr = dateS.rdv_10;
                arr[1] = value;
                setDateS({...dateS , rdv_10: arr});

                setDate(option, dateS);
                break;
            case 'timeF3':
                setTimeF3(value);
                arr = dateS.rdv_10;
                arr[2] = value;
                setDateS({...dateS , rdv_10: arr});

                setDate(option, dateS);
                break;
            default:
                return null;
        }
    }

    const modifiers = {
        disabled: date => getDay(date) === 6 || getDay(date) === 0, // Disables Saturdays
    }

    return (
        <div className="col-4 d-flex flex-column leftBorder">
            <div className="row align-items-center">
                <div className="col-md-6 col-12">
                    <span className="span-rendezvous">{name}</span>
                </div>
                <div className="col-md-6 col-12">
                    <Form.Control
                        as="select"
                        size="sm" onChange={(event) => onSelectChange(event)} value={rendezVous} custom>
                        <option>présentiel</option>
                        <option>distanciel</option>
                    </Form.Control>
                </div>

            </div>

            <div className="row mt-2 d-flex flex-row align-items-center">
                <div className="col-md-4 col-12 d-flex flex-row align-items-center justify-content-center">
                    <div className="customDatePickerWidth">
                        <DatePicker
                            modifiers={modifiers}
                            maximumDate={ item ? parseISO(item.endDate) : null}
                            minimumDate={ item ? parseISO(item.startDate) : null}
                            locale={fr}
                            date={date1}
                            onDateChange={(value) => onDateChange(value)}
                        >
                            {({ inputProps, focused }) => (
                                <input
                                    style={{ width: 100 }}
                                    className={'input' + (focused ? ' -focused' : '')}
                                    {...inputProps}
                                    disabled={!!disabledDate.disabled1}
                                />
                            )}
                        </DatePicker>
                    </div>

                </div>
                <div className="col-md-1 col-12 d-flex flex-row align-items-center justify-content-center">
                    <i className="fas fa-calendar-alt text-danger" />
                </div>
                <div className="col-md-3 col-12 d-flex flex-row align-items-center justify-content-center">
                    <TimePicker
                        maxTime={new Date(null, null, null, 18, 0, 0)}
                        minTime={new Date(null, null, null, 8, 0, 0)}
                        clearIcon={null}
                        onChange={(value) => onTimechange('timeD1', value)}
                        value={timeD1}
                        disabled={!!disabledDate.disabled1}
                    />
                </div>
                <div className="col-md-1 col-12 d-flex flex-row align-items-center justify-content-center">
                    <span>á</span>
                </div>
                <div className="col-md-3 col-12 d-flex flex-row align-items-center justify-content-center">
                    <TimePicker
                        maxTime={new Date(null, null, null, 18, 0, 0)}
                        minTime={new Date(null, null, null, 8, 0, 0)}
                        clearIcon={null}
                        onChange={(value) => onTimechange('timeF1', value)}
                        value={timeF1}
                        disabled={!!disabledDate.disabled1}
                    />
                </div>

            </div>

            <div className="row">
                <div className="col-12">
                                <span className="span-rendezvous">
                                    Rendez-vous généralement consacre a
                                    l'administrateur et au set-up de la plateforme
                                </span>

                </div>
            </div>

            <div className="row d-flex flex-row align-items-center">
                <div className="col-12 d-flex flex-row align-items-center">
                    <span>Relance distancielle J+3</span>
                </div>
                <div className="col-md-4 col-12 d-flex flex-row align-items-center justify-content-center">
                    <div className="customDatePickerWidth">
                        <DatePicker
                            minimumDate={new Date()}
                            modifiers={modifiers}
                            date={date2}
                            locale={fr}
                            onDateChange={(value) => onDateChange2(value)}
                        >
                            {({ inputProps, focused }) => (
                                <input
                                    style={{ width: 100 }}
                                    className={'input' + (focused ? ' -focused' : '')}
                                    {...inputProps}
                                    disabled={!!disabledDate.disabled2}
                                />
                            )}
                        </DatePicker>
                    </div>

                </div>
                <div className="col-md-1 col-12 d-flex flex-row align-items-center justify-content-center">
                    <i className="fas fa-calendar-alt text-danger" />
                </div>
                <div className="col-md-3 col-12 d-flex flex-row align-items-center justify-content-center">
                    <TimePicker
                        className="class1"
                        maxTime={new Date(null, null, null, 18, 0, 0)}
                        minTime={new Date(null, null, null, 8, 0, 0)}
                        clearIcon={null}
                        onChange={(value) => onTimechange('timeD2', value)}
                        value={timeD2}
                        disabled={!!disabledDate.disabled2}

                    />
                </div>
                <div className="col-md-1 col-12 d-flex flex-row align-items-center justify-content-center">
                    <span>á</span>
                </div>
                <div className="col-md-3 col-12 d-flex flex-row align-items-center justify-content-center">
                    <TimePicker
                        maxTime={new Date(null, null, null, 18, 0, 0)}
                        minTime={new Date(null, null, null, 8, 0, 0)}
                        className="class1"
                        clearIcon={null}
                        onChange={(value) => onTimechange('timeF2', value)}
                        value={timeF2}
                        disabled={!!disabledDate.disabled2}
                    />
                </div>

            </div>

            <div className="row d-flex flex-row align-items-center">
                <div className="col-12 d-flex flex-row align-items-center">
                    <span>Relance distancielle J+10</span>
                </div>
                <div className="col-md-4 col-12 d-flex flex-row align-items-center justify-content-center">
                    <div className="customDatePickerWidth">
                        <DatePicker
                            minimumDate={new Date()}
                            modifiers={modifiers}
                            date={date3}
                            locale={fr}
                            locale={fr}
                            onDateChange={(value) => onDateChange3(value)}
                        >
                            {({ inputProps, focused }) => (
                                <input
                                    style={{ width: 100 }}
                                    className={'input' + (focused ? ' -focused' : '')}
                                    {...inputProps}
                                    disabled={!!disabledDate.disabled3}
                                />
                            )}
                        </DatePicker>
                    </div>

                </div>
                <div className="col-md-1 col-12 d-flex flex-row align-items-center justify-content-center">
                    <i className="fas fa-calendar-alt text-danger" />
                </div>
                <div className="col-md-3 col-12 d-flex flex-row align-items-center justify-content-center">
                    <TimePicker
                        maxTime={new Date(null, null, null, 18, 0, 0)}
                        minTime={new Date(null, null, null, 8, 0, 0)}
                        clearIcon={null}
                        onChange={(value) => onTimechange('timeD3', value)}
                        value={timeD3}
                        disabled={!!disabledDate.disabled3}
                    />
                </div>
                <div className="col-md-1 col-12 d-flex flex-row align-items-center justify-content-center">
                    <span>á</span>
                </div>
                <div className="col-md-3 col-12 d-flex flex-row align-items-center justify-content-center">
                    <TimePicker
                        maxTime={new Date(null, null, null, 18, 0, 0)}
                        minTime={new Date(null, null, null, 8, 0, 0)}
                        clearIcon={null}
                        onChange={(value) => onTimechange('timeF3', value)}
                        value={timeF3}
                        disabled={!!disabledDate.disabled3}
                    />
                </div>

            </div>

        </div>
    );
}

export default RendezVousCard;