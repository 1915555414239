/*----------------------------ADMIN TYPES------------------------------------*/
export const STORE_ADMIN = {
    STORE_ADMIN_REQUEST     :        "STORE_ADMIN_REQUEST",
    STORE_ADMIN_SUCCESS     :        "STORE_ADMIN_SUCCESS",
    STORE_ADMIN_FAILED      :        "STORE_ADMIN_FAILED",
    STORE_ADMIN_RESET       :        "STORE_ADMIN_RESET",
};

export const STORE_MANAGER = {
    STORE_MANAGER_REQUEST     :        "STORE_MANAGER_REQUEST",
    STORE_MANAGER_SUCCESS     :        "STORE_MANAGER_SUCCESS",
    STORE_MANAGER_FAILED      :        "STORE_MANAGER_FAILED",
    STORE_MANAGER_RESET       :        "STORE_MANAGER_RESET",
};

export const STORE_DR = {
    STORE_DR_REQUEST     :        "STORE_DR_REQUEST",
    STORE_DR_SUCCESS     :        "STORE_DR_SUCCESS",
    STORE_DR_FAILED      :        "STORE_DR_FAILED",
    STORE_DR_RESET       :        "STORE_DR_RESET",
};

export const STORE_RDV = {
    STORE_RDV_REQUEST     :        "STORE_RDV_REQUEST",
    STORE_RDV_SUCCESS     :        "STORE_RDV_SUCCESS",
    STORE_RDV_FAILED      :        "STORE_RDV_FAILED",
    STORE_RDV_RESET       :        "STORE_RDV_RESET",
};

export const STORE_FORMATEUR = {
    STORE_FORMATEUR_REQUEST     :        "STORE_FORMATEUR_REQUEST",
    STORE_FORMATEUR_SUCCESS     :        "STORE_FORMATEUR_SUCCESS",
    STORE_FORMATEUR_FAILED      :        "STORE_FORMATEUR_FAILED",
    STORE_FORMATEUR_RESET       :        "STORE_FORMATEUR_RESET",
};

export const FIND_RDV_FORMATEUR_NULL = {
    FIND_RDV_FORMATEUR_NULL_REQUEST     :        "FIND_RDV_FORMATEUR_NULL_REQUEST",
    FIND_RDV_FORMATEUR_NULL_SUCCESS     :        "FIND_RDV_FORMATEUR_NULL_SUCCESS",
    FIND_RDV_FORMATEUR_NULL_FAILED      :        "FIND_RDV_FORMATEUR_NULL_FAILED",
    FIND_RDV_FORMATEUR_NULL_RESET       :        "FIND_RDV_FORMATEUR_NULL_RESET",
};

export const STORE_OPTICIEN = {
    STORE_OPTICIEN_REQUEST     :        "STORE_OPTICIEN_REQUEST",
    STORE_OPTICIEN_SUCCESS     :        "STORE_OPTICIEN_SUCCESS",
    STORE_OPTICIEN_FAILED      :        "STORE_OPTICIEN_FAILED",
    STORE_OPTICIEN_RESET       :        "STORE_OPTICIEN_RESET",
};

export const UPLOAD_OPTICIEN = {
    UPLOAD_OPTICIEN_REQUEST     :        "UPLOAD_OPTICIEN_REQUEST",
    UPLOAD_OPTICIEN_SUCCESS     :        "UPLOAD_OPTICIEN_SUCCESS",
    UPLOAD_OPTICIEN_FAILED      :        "UPLOAD_OPTICIEN_FAILED",
    UPLOAD_OPTICIEN_RESET       :        "UPLOAD_OPTICIEN_RESET",
};

export const ZONING_ADMIN = {
    ZONING_ADMIN_REQUEST     :        "ZONING_ADMIN_REQUEST",
    ZONING_ADMIN_SUCCESS     :        "ZONING_ADMIN_SUCCESS",
    ZONING_ADMIN_FAILED      :        "ZONING_ADMIN_FAILED",
    ZONING_ADMIN_RESET       :        "ZONING_ADMIN_RESET",
};

export const COUNT_FORMATIONS_ADMIN = {
    COUNT_FORMATIONS_ADMIN_REQUEST     :        "COUNT_FORMATIONS_ADMIN_REQUEST",
    COUNT_FORMATIONS_ADMIN_SUCCESS     :        "COUNT_FORMATIONS_ADMIN_SUCCESS",
    COUNT_FORMATIONS_ADMIN_FAILED      :        "COUNT_FORMATIONS_ADMIN_FAILED",
    COUNT_FORMATIONS_ADMIN_RESET       :        "COUNT_FORMATIONS_ADMIN_RESET",
};

export const LIST_ADMIN = {
    LIST_ADMIN_REQUEST     :        "LIST_ADMIN_REQUEST",
    LIST_ADMIN_SUCCESS     :        "LIST_ADMIN_SUCCESS",
    LIST_ADMIN_FAILED      :        "LIST_ADMIN_FAILED",
    LIST_ADMIN_RESET       :        "LIST_ADMIN_RESET",
};

export const LIST_MANAGER = {
    LIST_MANAGER_REQUEST     :        "LIST_MANAGER_REQUEST",
    LIST_MANAGER_SUCCESS     :        "LIST_MANAGER_SUCCESS",
    LIST_MANAGER_FAILED      :        "LIST_MANAGER_FAILED",
    LIST_MANAGER_RESET       :        "LIST_MANAGER_RESET",
};

export const LIST_ITEM_BY_ADMIN = {
    LIST_ITEM_BY_ADMIN_REQUEST     :        "LIST_ITEM_BY_ADMIN_REQUEST",
    LIST_ITEM_BY_ADMIN_SUCCESS     :        "LIST_ITEM_BY_ADMIN_SUCCESS",
    LIST_ITEM_BY_ADMIN_FAILED      :        "LIST_ITEM_BY_ADMIN_FAILED",
    LIST_ITEM_BY_ADMIN_RESET       :        "LIST_ITEM_BY_ADMIN_RESET",
};

export const UPDATE_BY_ADMIN = {
    UPDATE_BY_ADMIN_REQUEST     :        "UPDATE_BY_ADMIN_REQUEST",
    UPDATE_BY_ADMIN_SUCCESS     :        "UPDATE_BY_ADMIN_SUCCESS",
    UPDATE_BY_ADMIN_FAILED      :        "UPDATE_BY_ADMIN_FAILED",
    UPDATE_BY_ADMIN_RESET       :        "UPDATE_BY_ADMIN_RESET",
};

export const DELETE_BY_ADMIN = {
    DELETE_BY_ADMIN_REQUEST     :        "DELETE_BY_ADMIN_REQUEST",
    DELETE_BY_ADMIN_SUCCESS     :        "DELETE_BY_ADMIN_SUCCESS",
    DELETE_BY_ADMIN_FAILED      :        "DELETE_BY_ADMIN_FAILED",
    DELETE_BY_ADMIN_RESET       :        "DELETE_BY_ADMIN_RESET",
};
/*----------------------------RDV TYPES------------------------------------*/
export const FIND_USER_BY_EMAIL = {
    FIND_USER_BY_EMAIL_REQUEST: "FIND_USER_BY_EMAIL_REQUEST",
    FIND_USER_BY_EMAIL_SUCCESS: "FIND_USER_BY_EMAIL_SUCCESS",
    FIND_USER_BY_EMAIL_FAILED: "FIND_USER_BY_EMAIL_FAILED",
    FIND_USER_BY_EMAIL_RESET: "FIND_USER_BY_EMAIL_RESET"
};

export const RDV_CODE_SMS = {
    RDV_CODE_SMS_REQUEST: "RDV_CODE_SMS_REQUEST",
    RDV_CODE_SMS_SUCCESS: "RDV_CODE_SMS_SUCCESS",
    RDV_CODE_SMS_FAILED: "RDV_CODE_SMS_FAILED",
    RDV_CODE_SMS_RESET: "RDV_CODE_SMS_RESET"
};

export const FIND_OPTICIAN_BY_JNJ = {
    FIND_OPTICIAN_BY_JNJ_REQUEST: "FIND_OPTICIAN_BY_JNJ_REQUEST",
    FIND_OPTICIAN_BY_JNJ_SUCCESS: "FIND_OPTICIAN_BY_JNJ_SUCCESS",
    FIND_OPTICIAN_BY_JNJ_FAILED: "FIND_OPTICIAN_BY_JNJ_FAILED"
};

export const FIND_CODE_SMS = {
    FIND_CODE_SMS_REQUEST: "FIND_CODE_SMS_REQUEST",
    FIND_CODE_SMS_SUCCESS: "FIND_CODE_SMS_SUCCESS",
    FIND_CODE_SMS_FAILED: "FIND_CODE_SMS_FAILED"
};

export const STORE_FORMATION_RDV = {
    STORE_FORMATION_RDV_REQUEST: "STORE_FORMATION_RDV_REQUEST",
    STORE_FORMATION_RDV_SUCCESS: "STORE_FORMATION_RDV_SUCCESS",
    STORE_FORMATION_RDV_FAILED: "STORE_FORMATION_RDV_FAILED"
};

export const FIND_FORMATIONS_BY_RDV = {
    FIND_FORMATIONS_BY_RDV_REQUEST: "FIND_FORMATIONS_BY_RDV_REQUEST",
    FIND_FORMATIONS_BY_RDV_SUCESS: "FIND_FORMATIONS_BY_RDV_SUCESS",
    FIND_FORMATIONS_BY_RDV_FAILED: "FIND_FORMATIONS_BY_RDV_FAILED"
};

export const UPDATE_FORMATIONS_BY_RDV = {
    UPDATE_FORMATIONS_BY_RDV_REQUEST: "UPDATE_FORMATIONS_BY_RDV_REQUEST",
    UPDATE_FORMATIONS_BY_RDV_SUCCESS: "UPDATE_FORMATIONS_BY_RDV_SUCCESS",
    UPDATE_FORMATIONS_BY_RDV_FAILED: "UPDATE_FORMATIONS_BY_RDV_FAILED"
};

export const FIND_FORMATIONS_BY_ID = {
    FIND_FORMATIONS_BY_ID_RQUEST: "FIND_FORMATIONS_BY_ID_RQUEST",
    FIND_FORMATIONS_BY_ID_SUCCESS: "FIND_FORMATIONS_BY_ID_SUCCESS",
    FIND_FORMATIONS_BY_ID_FAILED: "FIND_FORMATIONS_BY_ID_FAILED"
};

export const COUNT_FORMATIONS_RDV = {
    COUNT_FORMATIONS_RDV_REQUEST     :        "COUNT_FORMATIONS_RDV_REQUEST",
    COUNT_FORMATIONS_RDV_SUCCESS     :        "COUNT_FORMATIONS_RDV_SUCCESS",
    COUNT_FORMATIONS_RDV_FAILED      :        "COUNT_FORMATIONS_RDV_FAILED",
    COUNT_FORMATIONS_RDV_RESET       :        "COUNT_FORMATIONS_RDV_RESET",
};
/*----------------------------FORMATEUR TYPES------------------------------------*/
export const FIND_FORMATEUR_BY_EMAIL = {
    FIND_FORMATEUR_BY_EMAIL_REQUEST: "FIND_FORMATEUR_BY_EMAIL_REQUEST",
    FIND_FORMATEUR_BY_EMAIL_SUCCESS: "FIND_FORMATEUR_BY_EMAIL_SUCCESS",
    FIND_FORMATEUR_BY_EMAIL_FAILED: "FIND_FORMATEUR_BY_EMAIL_FAILED"
}

export const LOGOUT_FORMATEUR = {
    LOGOUT_FORMATEUR_REQUEST: "LOGOUT_FORMATEUR_REQUEST",
    LOGOUT_FORMATEUR_SUCCESS: "LOGOUT_FORMATEUR_SUCCESS",
    LOGOUT_FORMATEUR_FAILED: "LOGOUT_FORMATEUR_FAILED"
}

export const FIND_FORMATIONS_BY_FORMATEUR = {
    FIND_FORMATIONS_BY_FORMATEUR_REQUEST: "FIND_FORMATIONS_BY_FORMATEUR_REQUEST",
    FIND_FORMATIONS_BY_FORMATEUR_SUCCESS: "FIND_FORMATIONS_BY_FORMATEUR_SUCCESS",
    FIND_FORMATIONS_BY_FORMATEUR_FAILED: "FIND_FORMATIONS_BY_FORMATEUR_FAILED"
}

export const UPDATE_FORMATIONS_BY_FORMATEUR = {
    UPDATE_FORMATIONS_BY_FORMATEUR_REQUEST: "UPDATE_FORMATIONS_BY_FORMATEUR_REQUEST",
    UPDATE_FORMATIONS_BY_FORMATEUR_SUCCESS: "UPDATE_FORMATIONS_BY_FORMATEUR_SUCCESS",
    UPDATE_FORMATIONS_BY_FORMATEUR_FAILED: "UPDATE_FORMATIONS_BY_FORMATEUR_FAILED",
    UPDATE_FORMATIONS_BY_FORMATEUR_RESET: "UPDATE_FORMATIONS_BY_FORMATEUR_RESET",
};

export const RDV_LIST_BY_FORMATEUR = {
    RDV_LIST_BY_FORMATEUR_REQUEST: "RDV_LIST_BY_FORMATEUR_REQUEST",
    RDV_LIST_BY_FORMATEUR_SUCCESS: "RDV_LIST_BY_FORMATEUR_SUCCESS",
    RDV_LIST_BY_FORMATEUR_FAILED: "RDV_LIST_BY_FORMATEUR_FAILED",
    RDV_LIST_BY_FORMATEUR_RESET: "RDV_LIST_BY_FORMATEUR_RESET"
};

export const OPTICIAN_LIST_BY_FORMATEUR = {
    OPTICIAN_LIST_BY_FORMATEUR_REQUEST: "OPTICIAN_LIST_BY_FORMATEUR_REQUEST",
    OPTICIAN_LIST_BY_FORMATEUR_SUCCESS: "OPTICIAN_LIST_BY_FORMATEUR_SUCCESS",
    OPTICIAN_LIST_BY_FORMATEUR_FAILED: "OPTICIAN_LIST_BY_FORMATEUR_FAILED",
    OPTICIAN_LIST_BY_FORMATEUR_RESET: "OPTICIAN_LIST_BY_FORMATEUR_RESET"
};

export const COUNT_FORMATIONS_FOMATEUR = {
    COUNT_FORMATIONS_FOMATEUR_REQUEST     :        "COUNT_FORMATIONS_FOMATEUR_REQUEST",
    COUNT_FORMATIONS_FOMATEUR_SUCCESS     :        "COUNT_FORMATIONS_FOMATEUR_SUCCESS",
    COUNT_FORMATIONS_FOMATEUR_FAILED      :        "COUNT_FORMATIONS_FOMATEUR_FAILED",
    COUNT_FORMATIONS_FOMATEUR_RESET       :        "COUNT_FORMATIONS_FOMATEUR_RESET",
};

export const FINISHED_FORMATIONS_FOMATEUR = {
    FINISHED_FORMATIONS_FOMATEUR_REQUEST     :        "FINISHED_FORMATIONS_FOMATEUR_REQUEST",
    FINISHED_FORMATIONS_FOMATEUR_SUCCESS     :        "FINISHED_FORMATIONS_FOMATEUR_SUCCESS",
    FINISHED_FORMATIONS_FOMATEUR_FAILED      :        "FINISHED_FORMATIONS_FOMATEUR_FAILED",
    FINISHED_FORMATIONS_FOMATEUR_RESET       :        "FINISHED_FORMATIONS_FOMATEUR_RESET",
};
/*----------------------------MANAGER TYPES------------------------------------*/

export const FIND_FORMATIONS_BY_MANAGER = {
    FIND_FORMATIONS_BY_MANAGER_REQUEST: "FIND_FORMATIONS_BY_MANAGER_REQUEST",
    FIND_FORMATIONS_BY_MANAGER_SUCCESS: "FIND_FORMATIONS_BY_MANAGER_SUCCESS",
    FIND_FORMATIONS_BY_MANAGER_FAILED: "FIND_FORMATIONS_BY_MANAGER_FAILED",
    FIND_FORMATIONS_BY_MANAGER_RESET: "FIND_FORMATIONS_BY_MANAGER_RESET"
};
/*----------------------------DIRECTEUR TYPES------------------------------------*/

export const FIND_FORMATIONS_BY_DIRECTEUR = {
    FIND_FORMATIONS_BY_DIRECTEUR_REQUEST: "FIND_FORMATIONS_BY_DIRECTEUR_REQUEST",
    FIND_FORMATIONS_BY_DIRECTEUR_SUCCESS: "FIND_FORMATIONS_BY_DIRECTEUR_SUCCESS",
    FIND_FORMATIONS_BY_DIRECTEUR_FAILED: "FIND_FORMATIONS_BY_DIRECTEUR_FAILED",
    FIND_FORMATIONS_BY_DIRECTEUR_RESET: "FIND_FORMATIONS_BY_DIRECTEUR_RESET"
};

export const COUNT_FORMATIONS_DR = {
    COUNT_FORMATIONS_DR_REQUEST     :        "COUNT_FORMATIONS_DR_REQUEST",
    COUNT_FORMATIONS_DR_SUCCESS     :        "COUNT_FORMATIONS_DR_SUCCESS",
    COUNT_FORMATIONS_DR_FAILED      :        "COUNT_FORMATIONS_DR_FAILED",
    COUNT_FORMATIONS_DR_RESET       :        "COUNT_FORMATIONS_DR_RESET",
};
/*----------------------------GLOBAL TYPES------------------------------------*/
export const VERIFY_CODE_SMS = {
    VERIFY_CODE_SMS_REQUEST: "VERIFY_CODE_SMS_REQUEST",
    VERIFY_CODE_SMS_SUCCESS: "VERIFY_CODE_SMS_SUCCESS",
    VERIFY_CODE_SMS_FAILED: "VERIFY_CODE_SMS_FAILED",
    VERIFY_CODE_SMS_RESET: "VERIFY_CODE_SMS_RESET"
};

export const GET_ALL_RDV = {
    GET_ALL_RDV_REQUEST: "GET_ALL_RDV_REQUEST",
    GET_ALL_RDV_SUCCESS: "GET_ALL_RDV_SUCCESS",
    GET_ALL_RDV_FAILED: "GET_ALL_RDV_FAILED",
    GET_ALL_RDV_RESET: "GET_ALL_RDV_RESET",
};

export const GET_ALL_DR = {
    GET_ALL_DR_REQUEST: "GET_ALL_DR_REQUEST",
    GET_ALL_DR_SUCCESS: "GET_ALL_DR_SUCCESS",
    GET_ALL_DR_FAILED: "GET_ALL_DR_FAILED",
    GET_ALL_DR_RESET: "GET_ALL_DR_RESET",
};

export const LOGOUT = {
    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAILED: "LOGOUT_FAILED"
}