import axios from "axios";
import{ UPDATE_FORMATIONS_BY_RDV } from "../../constants/types";
import {SESSION_RDV} from "../../constants/sessionName";
import {SERVER_URL} from "../../constants/constants";

export const updateFormationByRdv = (params) => {
    const rdv = JSON.parse(localStorage.getItem(SESSION_RDV));
    const headers= {
        'Authorization': `Bearer ${rdv.token}`
    }
    return (dispatch) => {
        dispatch(updateFormationByRdvRequest())
        axios.patch(`${SERVER_URL}/api/rdv/update-formations-by-rdv`, params,{ headers })
            .then(response => {
                const resp = response;
                dispatch(updateFormationByRdvSuccess(resp))
            })
            .catch(error => {
                dispatch(updateFormationByRdvFailure(error.message))
            });

    }
}

const updateFormationByRdvRequest = () => {
    return {
        type: UPDATE_FORMATIONS_BY_RDV.UPDATE_FORMATIONS_BY_RDV_REQUEST
    }
}

const updateFormationByRdvSuccess = response => {
    return {
        type: UPDATE_FORMATIONS_BY_RDV.UPDATE_FORMATIONS_BY_RDV_SUCCESS,
        payload: response
    }
}

const updateFormationByRdvFailure = error => {
    return {
        type: UPDATE_FORMATIONS_BY_RDV.UPDATE_FORMATIONS_BY_RDV_FAILED,
        payload: error
    }
}