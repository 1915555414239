import Login from "../components/auth/login";
import Index from "../components";
import SpaceAdmin from "../components/admin/space/spaceAdmin";
import SpaceManager from "../components/manager/space/spaceManager";
import SpaceDr from "../components/dr/space/spaceDr";
import SecurePage from "../components/rdv/securepage/securePage";
import SpaceRdv from "../components/rdv/space/spaceRdv";
import AffilierOpticien from "../components/rdv/affilieropticien/affilierOpticien";
import SpaceFormateur from "../components/formateur/space/spaceFormateur";
import ValiderFormation from "../components/rdv/validerformation/validerFormation";
import FormationConfirmer from "../components/rdv/formationconfirmer/formationConfirmer";

const routes = [
    {
        'name': 'login',
        'path': '/login',
        'component': Login,
    },
    {
        'name': 'spaceAdmin',
        'path': '/espace-admin',
        'component': SpaceAdmin,
    },
    {
        'name': 'spaceManager',
        'path': '/espace-manager',
        'component': SpaceManager,
    },
    {
        'name': 'spaceDr',
        'path': '/espace-directeur-regional',
        'component': SpaceDr,
    },
    {
        'name': 'codesmsRdv',
        'path': '/verifier-rdv',
        'component': SecurePage,
    },
    {
        'name': 'spaceRdv',
        'path': '/espace-responsable-de-vente',
        'component': SpaceRdv,
    },
    {
        'name': 'affilierOpticien',
        'path': '/affilier-opticien',
        'component': AffilierOpticien,
    },
    {
        'name': 'validerFormation',
        'path': '/sauvegarder-formation',
        'component': ValiderFormation,
    },
    {
        'name': 'formationConfirmer',
        'path': '/formation-confirmer',
        'component': FormationConfirmer,
    },
    {
        'name': 'spaceFormateur',
        'path': '/espace-formateur',
        'component': SpaceFormateur,
    },
    {
        'name': 'index',
        'path': '/',
        'component': Index,
        'roles': ['ROLE_USER'],
    },
];

const compile = (parentRoute: Route, subRoutes: Route[]): Route[] => {
    return subRoutes.flatMap(subRoute => {
        const newRoute: Route = {
            'name': subRoute.name,
            'path': parentRoute.path + subRoute.path,
            'component': subRoute.component,
            'roles': (parentRoute.roles || []).concat((subRoute.roles || [])),
        };
        return (subRoute.routes) ? [...compile(newRoute, subRoute.routes)] : newRoute;
    });
}

export const getRoutes = () => {
    const parentRoute = {
        'name': '',
        'path': '',
    };
    const flatRoutes = compile(parentRoute, routes);
    return flatRoutes;
}