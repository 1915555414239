import { STORE_DR } from '../../constants/types';

const initialState = { loading: false, error: '', response: {} }

const storeDrReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_DR.STORE_DR_REQUEST:
            return {
                ...state,
                loading: true
            }
        case STORE_DR.STORE_DR_SUCCESS:
            return {
                loading: false,
                response: action.payload,
                error: ''
            }
        case STORE_DR.STORE_DR_FAILED:
            return {
                loading: false,
                response: {},
                error: action.payload
            }
        case STORE_DR.STORE_DR_RESET:
            return initialState
        default: return state
    }
}

export default storeDrReducer;