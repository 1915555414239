import React, {useState} from 'react';
import {ListGroup} from "react-bootstrap";

import './sideBarFormateur.scss';

function SideBarFormateur({ itemChanged }) {
    const [selectedItem, setSelectedItem] = useState('action');

    const changeselectedItem = item => {
        if (item === 'action') {
            setSelectedItem(item);
            itemChanged('action');
        } else if (item === 'opticien') {
            setSelectedItem(item);
            itemChanged('opticien');
        } else if (item === 'rdv') {
            setSelectedItem(item);
            itemChanged('rdv');
        }

    }
    return (
        <ListGroup>
            <ListGroup.Item
                className={`
                text-center
                ${selectedItem === 'action' ? "item-selected" : "item-list" }
                `}
                onClick={() => changeselectedItem('action')}
            >SUIVI ACTION</ListGroup.Item>
            <ListGroup.Item
                className={`
                text-center
                ${selectedItem === 'opticien' ? "item-selected" : "item-list" }
                `}
                onClick={() => changeselectedItem('opticien')}
            >OPTICIENS</ListGroup.Item>
            <ListGroup.Item
                className={`
                text-center
                ${selectedItem === 'rdv' ? "item-selected" : "item-list" }
                `}
                onClick={() => changeselectedItem('rdv')}
            >RDV JJVC</ListGroup.Item>
        </ListGroup>
    );
}

export default SideBarFormateur;