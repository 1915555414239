import React, {useEffect, useState} from 'react';
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import Select from 'react-select';
import {toast} from "react-toastify";
import axios from "axios";
import {SERVER_URL} from "../../../../constants/constants";
import {SESSION_ADMIN} from "../../../../constants/sessionName";

import './rdvModal.scss';

function RdvModal({ show, handleClose }) {
    const [options, setOptions] = useState([]);
    const [selectedGender, setSelectedGender] = useState('Madame');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [secteurs, setSecteurs] = useState({});
    const [directeur, setDirecteur] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
        const headers= {
            'Authorization': `Bearer ${admin_session.token}`
        }
        axios.get(`${SERVER_URL}/api/directeur/get-all-dr` , { headers })
            .then(response => {
                const data = response.data.data;

                if (data) {
                    let dr = [];
                    data.forEach(item => {
                        dr.push( {
                            value: item.userable_id,
                            label: `${item.gender} ${item.first_name} ${item.last_name}`
                        });

                    });

                    setOptions(dr);
                }
                else {
                    toast.configure();
                    onModalClose('none');
                    toast('Aucun directeur exist sur la base de donnee', {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            pauseOnFocusLoss: false,
                        }
                    );
                }
            })
            .catch(error => {
                console.log(error);
            })

    },[]);

    function  resetData(){
        setSelectedGender('Madame');
        setNom('');
        setPrenom('');
        setEmail('');
        setPhone('');
        setErrors({});
        setOptions([]);
        setDirecteur({});
    }

    const handleSelectChange = selectedOption => {
        setDirecteur(selectedOption);
    };

    const onGenderChanged = item => {
        if (item === 'Madame') {
            setSelectedGender('Madame');
        } else {
            setSelectedGender('Monsieur');
        }
    }

    const onSecteurChanged = event => {
        const { value, name } = event.target;
        if (name === 'secteur1') {
            if (value.length === 5) {
                setSecteurs({...secteurs, sec1: value})
            } else {
                delete secteurs.sec1;
            }
        }
        else if (name === 'secteur2') {
            if (value.length === 5) {
                setSecteurs({...secteurs, sec2: value})
            } else {
                delete secteurs.sec2;
            }
        }
        else if (name === 'secteur3') {
            if (value.length === 5) {
                setSecteurs({...secteurs, sec3: value})
            } else {
                delete secteurs.sec3;
            }
        }
        else if (name === 'secteur4') {
            if (value.length === 5) {
                setSecteurs({...secteurs, sec4: value})
            } else {
                delete secteurs.sec4;
            }
        }
        else if (name === 'secteur5') {
            if (value.length === 5) {
                setSecteurs({...secteurs, sec5: value})
            } else {
                delete secteurs.sec5;
            }
        }
        else if (name === 'secteur6') {
            if (value.length === 5) {
                setSecteurs({...secteurs, sec6: value})
            } else {
                delete secteurs.sec6;
            }
        }
        else if (name === 'secteur7') {
            if (value.length === 5) {
                setSecteurs({...secteurs, sec7: value})
            } else {
                delete secteurs.sec7;
            }
        }
        else if (name === 'secteur8') {
            if (value.length === 5) {
                setSecteurs({...secteurs, sec8: value})
            } else {
                delete secteurs.sec8;
            }
        }
        else if (name === 'secteur9') {
            if (value.length === 5) {
                setSecteurs({...secteurs, sec9: value})
            } else {
                delete secteurs.sec9;
            }
        }
        else if (name === 'secteur10') {
            if (value.length === 5) {
                setSecteurs({...secteurs, sec10: value})
            } else {
                delete secteurs.sec10;
            }
        }
    }

    const handleInputChange = event => {
        const { name, value } = event.target;
        if (name === 'nom') {
            setNom(value);
        }
        else if (name === 'prenom'){
            setPrenom(value);
        }
        else if (name === 'email') {
            setEmail(value);
        }
        else if (name === 'phone') {
            setPhone(value);
        }
    }

    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        const params = {
            "gender": selectedGender, nom, prenom, email, phone,
            secteurs: JSON.stringify(secteurs), directeur_id: directeur.value
        };

        const admin_session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
        const headers= {
            'Authorization': `Bearer ${admin_session.token}`
        }

        axios.post(`${SERVER_URL}/api/admin/store-rdv`, params, { headers } )
            .then(resp => {
                onModalClose('add');
                toast('Responsable de vente ajouté avec succès', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        pauseOnFocusLoss: false,
                    }
                );
            }).catch(err => {
                setErrors(err.response.data);
            })
            .finally(() => {
                setLoading(false);
            })

    };

    const onModalClose = (action) => {
        resetData();
        handleClose(action);
    }

    return (
        <Modal
            backdrop="static"
            show={show}
            onHide={onModalClose}
            size="lg"
            centered
        >
            <Modal.Body>
                <div className="row">
                    <div className="col text-right">
                        <span className="close-button" onClick={() => onModalClose('none')}>X</span>
                    </div>
                </div>
                <div className="d-flex flex-column pl-5 pr-5">
                    <h5>Ajouter un nouveau Responsable des Ventes</h5>
                    <div className="horizontal-line-rdv w-25 mt-3 mb-3"/>
                </div>
                <div className="d-flex flex-column align-items-center">
                    <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                        <div className="row align-items-center w-100">
                            <div className={`
                            col-5
                            ${ selectedGender === 'Madame' ? "selected-span" : "unselected-span"}
                        `}
                                 onClick={() => onGenderChanged('Madame')}
                            >
                                <span>Madame</span>
                            </div>
                            <div className="col-2" />
                            <div className={`
                            col-5
                            ${ selectedGender === 'Monsieur' ? "selected-span" : "unselected-span"}
                        `}
                                 onClick={() => onGenderChanged('Monsieur')}
                            >
                                <span>Monsieur</span>
                            </div>
                        </div>
                    </div>

                    <Form className="w-100 d-flex flex-column align-items-center"  onSubmit={handleSubmit}>

                    <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                        <Form.Control size="sm" type="text" name="nom"
                                      placeholder="Nom" required
                                      className={`${errors && errors.nom ? 'is-invalid border border-danger' : '' }`}
                                      value={nom}
                                      onChange={handleInputChange}
                        />
                        {
                            errors.nom ?
                                <div className="invalid-feedback">
                                    {errors && errors.nom[0]}
                                </div>
                                : null
                        }
                    </div>

                    <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                        <Form.Control size="sm" type="text" name="prenom"
                                      placeholder="Prénom" required
                                      className={`${errors && errors.prenom ? 'is-invalid border border-danger' : '' }`}
                                      value={prenom}
                                      onChange={handleInputChange}
                        />
                        {
                            errors.prenom ?
                                <div className="invalid-feedback">
                                    {errors && errors.prenom[0]}
                                </div>
                                : null
                        }
                    </div>

                    <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                        <Form.Control size="sm" type="email" name="email"
                                      placeholder="E-mail" required
                                      className={`${errors && errors.email ? 'is-invalid border border-danger' : '' }`}
                                      value={email}
                                      onChange={handleInputChange}
                        />
                        {
                            errors.email ?
                                <div className="invalid-feedback">
                                    {errors && errors.email[0]}
                                </div>
                                : null
                        }
                    </div>

                    <div className="d-flex flex-column align-items-center pt-1 pb-1 w-75">
                        <Form.Control size="sm" type="text" name="phone"
                                      placeholder="Téléphone Mobile" required
                                      className={`${errors && errors.phone ? 'is-invalid border border-danger' : '' }`}
                                      value={phone}
                                      onKeyPress={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                          }
                                      }}
                                      onChange={handleInputChange}
                        />
                        {
                            errors.phone ?
                                <div className="invalid-feedback">
                                    {errors && errors.phone[0]}
                                </div>
                                : null
                        }
                    </div>

                    <div className="d-flex flex-column pt-1 pb-1 w-75">
                        <h6>Directeur</h6>
                    </div>

                    <div className="d-flex flex-column pt-1 pb-1 w-75">
                        <Select
                            options={options}
                            value={directeur}
                            onChange={handleSelectChange}
                        />
                        {
                            errors && errors.directeur_id ?
                                <div className="text-danger">
                                    Choisi un Directeur.
                                </div>
                                : null
                        }
                    </div>

                    <div className="d-flex flex-column pt-1 pb-1 w-75">
                        <h6>Secteurs</h6>
                    </div>

                    <div className="d-flex flex-column pt-1 pb-1 w-75">
                        <div className="d-flex flex-row">
                            <div className="col-2 m-1 w-100 p-0">
                                <Form.Control
                                    size="sm" name="secteur1" maxLength="5"
                                    onChange={onSecteurChanged}
                                    type="text" placeholder="77001" required/>
                            </div>
                            <div className="col-2 m-1 w-100 p-0">
                                <Form.Control
                                    size="sm" name="secteur2" maxLength="5"
                                    onChange={onSecteurChanged}
                                    type="text" placeholder="77001" />
                            </div>
                            <div className="col-2 m-1 w-100 p-0">
                                <Form.Control
                                    size="sm" name="secteur3" maxLength="5"
                                    onChange={onSecteurChanged}
                                    type="text" placeholder="77001" />
                            </div>
                            <div className="col-2 m-1 w-100 p-0">
                                <Form.Control
                                    size="sm" name="secteur4" maxLength="5"
                                    onChange={onSecteurChanged}
                                    type="text" placeholder="77001" />
                            </div>
                            <div className="col-2 m-1 w-100 p-0">
                                <Form.Control
                                    size="sm" name="secteur5" maxLength="5"
                                    onChange={onSecteurChanged}
                                    type="text" placeholder="77001" />
                            </div>
                        </div>
                    </div>

                        <div className="d-flex flex-column pt-1 pb-1 w-75">
                            <div className="d-flex flex-row">
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur6" maxLength="5"
                                        onChange={onSecteurChanged}
                                        type="text" placeholder="77001" />
                                </div>
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur7" maxLength="5"
                                        onChange={onSecteurChanged}
                                        type="text" placeholder="77001" />
                                </div>
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur8" maxLength="5"
                                        onChange={onSecteurChanged}
                                        type="text" placeholder="77001" />
                                </div>
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur9" maxLength="5"
                                        onChange={onSecteurChanged}
                                        type="text" placeholder="77001" />
                                </div>
                                <div className="col-2 m-1 w-100 p-0">
                                    <Form.Control
                                        size="sm" name="secteur10" maxLength="5"
                                        onChange={onSecteurChanged}
                                        type="text" placeholder="77001" />
                                </div>
                            </div>
                        </div>

                    <div className="d-flex flex-column align-items-center pt-4 w-25">
                        <Button bsPrefix="w-100 button-r-form-valider" type="submit">
                            {isLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                : 'Valider'
                            }
                        </Button>
                    </div>

                    </Form>

                </div>
            </Modal.Body>
        </Modal>
    );
}

export default RdvModal;