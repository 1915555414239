import React, {useEffect, useState} from 'react';
import OpticienChart from "../../common/opticienChart/opticienChart";
import {useHistory} from "react-router-dom";
import {SESSION_FORMATEUR} from "../../../constants/sessionName";
import axios from "axios";
import {SERVER_URL} from "../../../constants/constants";
import {getPath} from "../../../routes/path";

function FormateurChart(props) {
    const history = useHistory();
    const[errors, setErrors] = useState({});
    const[formations, setFormations] = useState({});

    useEffect(() => {
        if (localStorage.hasOwnProperty(SESSION_FORMATEUR)) {
            const session = JSON.parse(localStorage.getItem(SESSION_FORMATEUR));
            const headers= { 'Authorization': `Bearer ${session.token}` }
            const params= { formateur_id: session.user.userable_id }

            axios.get(`${SERVER_URL}/api/formateur/count-formations-by-formateur` , { params, headers } )
                .then(response => {
                    setFormations(response.data);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push(getPath('login'))
                    } else {
                        setErrors(error.response);
                    }
                });
        }
        else {
            history.push(getPath('login'))
        }
    },[]);

    return (
        <div>
            {
                formations.OK !== undefined ?
                    <OpticienChart
                        former={formations.OK ?  formations.OK : null }
                        encours={formations.COURS ?  formations.COURS : null }
                        afixer={formations.FIXER ?  formations.FIXER : null }
                    />
                : null
            }
        </div>

    );
}

export default FormateurChart;