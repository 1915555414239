import React, {useEffect, useState} from 'react';
import OpticienChart from "../../common/opticienChart/opticienChart";
import FormateurTable from "../../common/formateurTable/formateurTable";
import SecteurTable from "../../common/secteurTable/secteurTable";
import SpinnerKalendar from "../../common/spinner/spinnerKalendar";
import {SESSION_ADMIN} from "../../../constants/sessionName";
import axios from "axios";
import {SERVER_URL} from "../../../constants/constants";
import {useHistory} from "react-router-dom";
import {getPath} from "../../../routes/path";

import './dashboard.scss';
import {Button, Spinner} from "react-bootstrap";

function Dashboard(props) {
    const history = useHistory();
    const[loading, setLoading] = useState(false);
    const[loadingExport, setLoadingExport] = useState(false);
    const[errors, setErrors] = useState({});
    const[formations, setFormations] = useState({});
    useEffect(() => {
        setLoading(true);
        if (localStorage.hasOwnProperty(SESSION_ADMIN)) {
            let session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
            const headers= { 'Authorization': `Bearer ${session.token}` };
            axios.get(`${SERVER_URL}/api/admin/get-formations-by-admin` , { headers } )
                .then(response => {
                    setFormations(response.data);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push(getPath('login'));
                    } else {
                        setErrors(error.response);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        else {
            setLoading(false);
            history.push(getPath('login'));
        }
    },[]);

    const exportFormationList = () => {
        setLoadingExport(true);
        if (localStorage.hasOwnProperty(SESSION_ADMIN)) {
            let session = JSON.parse(localStorage.getItem(SESSION_ADMIN));
            const headers = { 'Authorization': `Bearer ${session.token}` }
            axios({
                url: `${SERVER_URL}/api/admin/export-formations-by-admin`,
                method: 'get',
                responseType:"blob",
                headers: headers
            }).then(function (response) {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');     // Create a label
                const href = window.URL.createObjectURL(blob);       // Create downloaded link
                aEle.href = href;
                aEle.download = `formations.xlsx`;  // File name after download
                document.body.appendChild(aEle);
                aEle.click();     // Click to download
                document.body.removeChild(aEle); // Download complete remove element
                window.URL.revokeObjectURL(href) // Release blob object
            }).catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoadingExport(false);
            })

        } else {
            setLoadingExport(false);
        }
    }

    return loading ? (
        <SpinnerKalendar />
    ) : errors.status ? (
        <h2>Opps {errors.data.message}</h2>
    ) : (
        <div className="row">
            <div className="col-1"></div>
                        <div className="col-4">
                            {
                                formations.chart &&
                                <OpticienChart
                                    former={formations.chart ?  formations.chart.OK : null }
                                    encours={formations.chart ?  formations.chart.COURS : null }
                                    afixer={formations.chart ?  formations.chart.FIXER : null }
                                />
                            }
                            <Button
                                className="mt-3 w-100" style={{ backgroundColor: '#E17B71', border: 'none'}}
                                onClick={exportFormationList}
                            >
                                {loadingExport ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    :
                                    <React.Fragment>
                                        <i className="fas fa-download mr-4" />
                                        Exporter la liste formations
                                    </React.Fragment>
                                }
                            </Button>
                        </div>
                        <div className="col-7">
                            {
                                formations.formateur &&
                                <FormateurTable formations={formations.formateur ? formations.formateur : []}/>
                            }
                            {
                                formations.secteurs &&
                                <SecteurTable formations={formations.secteurs ? formations.secteurs : []}/>
                            }
                        </div>
        </div>
    )

}

export default Dashboard;