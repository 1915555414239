import axios from "axios";
import{ VERIFY_CODE_SMS } from "../../constants/types";
import{ SERVER_URL } from "../../constants/constants";

export const verifyCodeSMS = (params) => {
    return (dispatch) => {
        dispatch(verifyCodeSMSRequest())
        axios
            .post(`${SERVER_URL}/api/verify-code-sms` , params )
            .then(response => {
                const resp = response;
                dispatch(verifyCodeSMSSuccess(resp))
            })
            .catch(error => {
                dispatch(verifyCodeSMSFailure(error.message))
            })
    }
}

const verifyCodeSMSRequest = () => {
    return {
        type: VERIFY_CODE_SMS.VERIFY_CODE_SMS_REQUEST
    }
}

const verifyCodeSMSSuccess = response => {
    return {
        type: VERIFY_CODE_SMS.VERIFY_CODE_SMS_SUCCESS,
        payload: response
    }
}

const verifyCodeSMSFailure = error => {
    return {
        type: VERIFY_CODE_SMS.VERIFY_CODE_SMS_FAILED,
        payload: error
    }
}