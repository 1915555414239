import axios from "axios";
import{ LOGOUT } from "../../constants/types";
import{ SERVER_URL } from "../../constants/constants";

export const logoutAction = (params) => {
    const headers = {
        'Authorization': `Bearer ${params.token}`
    }
    return (dispatch) => {
        dispatch(logoutRequest())
        axios
            .post(`${SERVER_URL}/api/logout` , params, { headers } )
            .then(response => {
                const resp = response;
                dispatch(logoutSuccess(resp))
            })
            .catch(error => {
                dispatch(logoutFailure(error.message))
            })
    }
}

const logoutRequest = () => {
    return {
        type: LOGOUT.LOGOUT_REQUEST
    }
}

const logoutSuccess = response => {
    return {
        type: LOGOUT.LOGOUT_SUCCESS,
        payload: response
    }
}

const logoutFailure = error => {
    return {
        type: LOGOUT.LOGOUT_FAILED,
        payload: error
    }
}