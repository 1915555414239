import React, {useEffect, useState} from 'react';
import {Accordion, Card, Button} from "react-bootstrap";
import SpinnerKalendar from "../../common/spinner/spinnerKalendar";
import {SESSION_FORMATEUR} from "../../../constants/sessionName";
import axios from "axios";
import {SERVER_URL} from "../../../constants/constants";
import {getPath} from "../../../routes/path";

import './opticienFormateur.scss';

function OpticienFormateur(props) {
    const [loading, setLoading] = useState(false);
    const [opticians, setOpticians] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setLoading(true);
        if (localStorage.hasOwnProperty(SESSION_FORMATEUR)) {
            let session = JSON.parse(localStorage.getItem(SESSION_FORMATEUR));
            const headers= { 'Authorization': `Bearer ${session.token}` };
            const params = { formateur_id: session.user.userable_id };

            axios.get(`${SERVER_URL}/api/formateur/get-list-optician-by-formateur` , { params, headers } )
                .then(response => {
                    setOpticians(response.data);
                })
                .catch(error => {
                    if (error.response.status === 401){
                        history.push(getPath('login'));
                    } else {
                        setErrors(error.response);
                    }
                })
                .finally(() => {
                    setLoading(false);
                })
        }
        else {
            setErrors(false);
            history.push(getPath('login'));
        }
    },[]);

    return loading ? (
        <SpinnerKalendar />
    ) : errors.status ? (
        <h2>Opps {errors.data.message}</h2>
    ) : (
        <div className="row">
            <div className="col-md-1" />
            <div className="col-md-11 col-sm-12">
                {
                    opticians.data &&
                        <Accordion>
                            {
                                opticians.data.map(item =>
                                    <Card bsPrefix="mt-1 border" key={item.id}>
                                        <Card.Header bsPrefix="p-1">
                                            <div className="row p-1 align-items-center">
                                                <span className="col-md-3 col-5">{item.corporate_name}</span>
                                                <span className="col-md-1 col-3">{item.jnj_number}</span>
                                                <span className="col-md-3 col-4">{item.city}</span>
                                                <span className="col-md-2 col-5">{item.phone_fixe}</span>
                                                <span className="col-md-2 col-5">{item.phone_mobile}</span>
                                                <Accordion.Toggle className="col-md-1 col-2" as={Button} variant="link" eventKey={`${item.id}`}>
                                                    <i className="fas fa-arrow-down text-dark col-12" />
                                                </Accordion.Toggle>
                                            </div>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={`${item.id}`}>
                                            <Card.Body bsPrefix="p-1">
                                                <div className="row align-items-center">
                                                    <div className="col-md-5 col-12 d-flex align-items-center">
                                                        <span className="span-title">Adresse</span>
                                                        <div className="v-line ml-3 mr-2"/>
                                                        <span className="span-text">{item.address}</span>
                                                    </div>
                                                    <div className="col-md-3 col-6 d-flex align-items-center">
                                                        <span className="span-title">CP</span>
                                                        <div className="v-line ml-3 mr-2"/>
                                                        <span className="span-text">{item.postal_code}</span>
                                                    </div>
                                                    <div className="col-md-3 col-6 d-flex align-items-center">
                                                        <span className="span-title">Ville</span>
                                                        <div className="v-line ml-3 mr-2"/>
                                                        <span className="span-text">{item.city}</span>
                                                    </div>
                                                </div>
                                                <div className="mt-3 row align-items-center">
                                                    <div className="col-md-5 col-12 d-flex align-items-center">
                                                        <span className="span-title">Administrateur</span>
                                                        <div className="v-line ml-3 mr-2"/>
                                                        <span className="span-admin-text">{item.trade_name}</span>
                                                    </div>
                                                    <div className="col-md-2 col-5 d-flex align-items-center">
                                                        <div className="v-line ml-3 mr-2"/>
                                                        <span className="span-admin-text">{item.phone_mobile}</span>
                                                    </div>
                                                    <div className="col-md-5 col-5 d-flex align-items-center">
                                                        <div className="v-line ml-3 mr-2"/>
                                                        <span className="span-admin-text">{item.email}</span>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            }
                        </Accordion>
                }
            </div>
        </div>
    )

}

export default OpticienFormateur;